import {Component, Inject, OnInit} from '@angular/core';
import {FormService} from "../../form.service";
import {AppService} from "../../../app.service";
import {NotifierService} from "angular-notifier";
import {UserService} from "../../../users/user.service";
import {ItemService} from "../../../items/item.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {Parameters} from "../../../parameters";
import {map, startWith} from "rxjs/internal/operators";
import {FormControl} from "@angular/forms";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import * as $ from 'jquery';
@Component({
  selector: 'app-settings-questions',
  templateUrl: './settings-questions.component.html',
  styleUrls: ['./settings-questions.component.scss']
})
export class SettingsQuestionsComponent implements OnInit {
  incomingQuestion: any;
  incomingForm: any;
  incomingQuestions: any;
  questionControl = new FormControl();
  filterByControl = new FormControl();
  itemControl = new FormControl();
  optionalControl = new FormControl();
  autoPercentageFieldControl = new FormControl();
  percentageFieldControl1 = new FormControl();
  percentageFieldControl2 = new FormControl();
  filteredQuestionsOptions: any;
  autoAgregatedFieldControl = new FormControl();
  filteredAutoAggregatedFieldOptions: any;
  filteredAutoPercentageFieldOptions: any;
  filteredPercentageField1Options: any;
  filteredPercentageField2Options: any;
  filteredOptionalOptions: any;
  filteredItemOptions: any;
  filteredQuestions: any;
  incomingOption: any;
  incomingSelectedOptional: any;
  outgoingRequest: any;
  url: any;
  form: any;
  hidden: any;
  incomingConditions: any;
  incomingQuestionSettings: any;
  result: any;
  incomingImg: any;
  incomingOptionSelected: any;
  incomingOptionSettings: any;
  flag: any = 0;
  strings: any;
  constructor(
      public formService: FormService,
      public  service: AppService,
      public  itemService: ItemService,
      private notifier: NotifierService,
      private userService: UserService,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private dialog: MatDialog) {
    this.incomingOption = new Parameters();
    this.incomingQuestion = new Parameters();
    this.incomingQuestions = new Parameters();
    this.strings = new Parameters();
    this.incomingSelectedOptional  = new Parameters();
    this.incomingImg  = new Parameters();
    this.outgoingRequest = new Parameters();
    this.incomingOptionSettings = new Parameters();
    this.incomingForm = new Parameters();
    this.incomingConditions = new Parameters();
    this.incomingOptionSelected = new Parameters();
    this.incomingQuestionSettings = new Parameters();
    this.incomingQuestion.setAttributes(this.data.question);
    this.incomingQuestionSettings.setAttributes(this.incomingQuestion.attributes.hasOwnProperty('settings') ? this.incomingQuestion.getSettings() : {});
    this.incomingQuestionSettings.setItem(this.incomingQuestionSettings.attributes.hasOwnProperty('item') ? this.incomingQuestionSettings.getItem() : '');
    this.incomingQuestionSettings.setSrc(this.incomingQuestionSettings.attributes.hasOwnProperty('src') ?
        this.incomingQuestionSettings.getSrc() : '');
    this.incomingSelectedOptional.setAttributes({questions: []});
    this.incomingOptionSelected.setAttributes({});
    this.incomingQuestion.setConditions(this.incomingQuestion.attributes.hasOwnProperty('conditions') ? this.incomingQuestion.getConditions() : {});
    if(!this.incomingQuestion.attributes.hasOwnProperty('control_id')){
     this.incomingQuestion.setControlId(this.incomingQuestion.getTypeId());
     this.incomingQuestion.setControl(this.service.app.data.controls.filter((control: any) => parseFloat(control.id) === parseFloat(this.incomingQuestion.getTypeId())));
    }this.incomingQuestion.setConditions(this.incomingQuestion.getConditions() instanceof Array ? {} : this.incomingQuestion.getConditions());
  }


  ngOnInit(): void {
    setTimeout(() => {
      this.service.setStrings((rs: any) => {
        this.strings.setAttributes(this.service.app.strings.params);
      });
      this.filteredQuestionsOptions = this.questionControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.label),
          map(label => label ? this._filterQuestion(label) : this.formService.questions.slice())
      );

      this.filteredQuestionsOptions = this.filterByControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.label),
          map(label => label ? this._filterQuestion(label) : this.formService.questions.slice())
      );

      this.filteredItemOptions = this.itemControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.name),
                map(name => name ? this._filterItem(name) : this.service.app.data.items.data.slice())
      );

      this.filteredOptionalOptions = this.optionalControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.label),
          map(label => label ? this._filterOptional(label) : this.incomingQuestion.getOptions().slice())
      );


      this.filteredAutoPercentageFieldOptions = this.autoPercentageFieldControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.label),
          map(label => label ? this._filterOptional(label) : this.incomingQuestion.getOptions().slice())
      );

      this.filteredPercentageField1Options = this.percentageFieldControl1.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.label),
          map(label => label ? this._filterOptional(label) : this.incomingQuestion.getOptions().slice())
      );

      this.filteredPercentageField2Options = this.percentageFieldControl2.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.label),
          map(label => label ? this._filterOptional(label) : this.incomingQuestion.getOptions().slice())
      );

      setTimeout(() => {
        this.filtered();
        let incomingOptions: any = new Parameters();
        incomingOptions.setAttributes(this.service.getJSObjectByArrayAndLabel(this.incomingQuestion.getOptions(), 'cntrl_num'));
        this.incomingQuestions.setAttributes(this.service.getJSObjectByArrayAndLabel(this.formService.questions, 'cntrl_num'));
        if(!this.service.isNull(this.incomingQuestion.getAutoPercentageField())){
          this.autoPercentageFieldControl.setValue(incomingOptions.getUnknown(this.incomingQuestion.getAutoPercentageField()));
        }if(!this.service.isNull(this.incomingQuestion.getPercentageField1())){
          this.percentageFieldControl1.setValue(incomingOptions.getUnknown(this.incomingQuestion.getPercentageField1()));
        }if(!this.service.isNull(this.incomingQuestion.getPercentageField2())){
          this.percentageFieldControl2.setValue(incomingOptions.getUnknown(this.incomingQuestion.getPercentageField2()));
        }if(!this.service.isNull(this.incomingQuestion.getReference())){
          this.filterByControl.setValue(this.incomingQuestions.getUnknown(this.incomingQuestion.getReference()));
        }if(!this.service.isNull(this.incomingQuestionSettings.getItem())){
            this.result = this.itemService.hasItemByCntrlNum(this.incomingQuestionSettings.getItem());
            if(this.result.status === 200) this.itemControl.setValue(this.result.item);
            else{
              let outgoingRequest: any = new Parameters();
              outgoingRequest.setAttributes({});
              outgoingRequest.setSearchText(this.incomingQuestionSettings.getItem());
              this.itemService.search(outgoingRequest, (items: any) => {
                if(items !== null && items.length!==0) this.itemControl.setValue(items[0]);
              });
            }
        }
      });

    });
  }

  update(option: any){
    if(this.incomingQuestion.hasAttribute(this.strings.getId())) {
      let outgoingRequest: any = new Parameters();
      outgoingRequest.setAttributes(Object.assign({}, option));
      if (outgoingRequest.hasAttribute(this.strings.getSettings()) &&
          !this.service.isNull(outgoingRequest.getSettings()))
        outgoingRequest.setSettings(JSON.stringify(outgoingRequest.getSettings()));
      this.service.httpService('post', this.service.app.routes.questions.options.settings.update,
          outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: 'false'}, (response: any) => {
          }, (error: any) => {
          });
    }
  }

  onInputTextChange(searchText: any){
    let outgoingRequest: any = new Parameters();
    outgoingRequest.setAttributes({});
    outgoingRequest.setSearchText(searchText);
    this.itemService.search(outgoingRequest, (items: any)=>{
      if(items !== null && items.length !==0) {
        this.itemControl.setValue(items[0]);
        this.incomingQuestionSettings.setItem(items[0][this.strings.getCntrlNum()]);
      }
    });
  }

  assignInputCondition(event: any, condition: any){
    this.incomingQuestion.setAttributes(this.incomingQuestion.getAttributes());
    this.incomingConditions.setAttributes(this.incomingQuestion.getConditions());
    if(!this.service.isNull(event.target.value)){
      this.incomingConditions.setUnknown(condition, event.target.value);
    }else {
      if(this.incomingConditions.hasAttribute(condition)){
        delete this.incomingConditions.getAttributes()[condition];
      }
    }
  }

  openImgDialog(event: any){
    const files: FileList = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.incomingImg.setAttributes({});
      this.incomingImg.setSrc(reader.result);
      this.incomingImg.setFlag(0);
      this.incomingImg.setCaption('');
      this.incomingImg.setOrderBy('');
      this.incomingImg.setStatus(1);
      this.incomingImg.setUrl('');
      this.incomingImg.setPath('');
      this.incomingImg.setAvatar(this.service.dataURItoBlob(reader.result));
      setTimeout((params: any) => {
        this.service.httpService('post', this.service.app.routes.images.add,
            this.incomingImg.getAttributes(), {},(response: any) => {
              this.incomingImg.attributes = Object.assign(this.incomingImg.attributes, response);
              if(this.flag === 0) this.incomingQuestionSettings.setSrc(this.incomingImg.getPath());
              else {
                this.incomingOptionSettings.setAttributes(this.incomingOptionSelected.getSettings());
                this.incomingOptionSettings.setSrc(this.incomingImg.getPath());
                this.flag = 0;
              }
            }, (error: any) => {
            });
      });
    };
  }

  filtered(){
    this.filteredAutoAggregatedFieldOptions = this.autoAgregatedFieldControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.label),
        map(label => label ? this._filterOptionalQuestion(label) : this.incomingSelectedOptional.getQuestions().slice())
    );

  }

  onSelected(event: MatAutocompleteSelectedEvent, autoAggregatedFieldElement){
    setTimeout(() => {
      this.incomingSelectedOptional.setAttributes(event.option.value);
      autoAggregatedFieldElement.value = '';
      const questions: any = this.service.getJSObjectByArrayAndLabel(this.incomingSelectedOptional.getQuestions(), 'cntrl_num');
      this.filtered();
      if(!this.service.isNull(this.incomingSelectedOptional.getAutoAggregatedField())){
        this.autoAgregatedFieldControl.setValue(questions[this.incomingSelectedOptional.getAutoAggregatedField()]);
      }
    });
  }

  setInputSettings(event: any, column: any){
      this.incomingQuestion.setAttributes(this.incomingQuestion.getAttributes());
      this.incomingQuestionSettings.setAttributes(this.incomingQuestion.getSettings());
      if(!this.service.isNull(event.target.value)){
        this.incomingQuestionSettings.setUnknown(column, event.target.value);
      }else {
        if(this.incomingQuestionSettings.hasAttribute(column))
          delete this.incomingQuestionSettings.getAttributes()[column];
      }
  }

  importOptions(event: any) {
    let files: FileList = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.outgoingRequest.setAttributes({});
      this.outgoingRequest.setQuestionId(this.incomingQuestion.getId());
      this.outgoingRequest.setFile(this.service.dataURItoBlob(reader.result));
      this.url = '/options/importrequest';
      this.service.httpService('post', this.url, this.outgoingRequest.attributes, {}, (response: any) => {
        event.target.files = null;
        this.incomingQuestion.setOptions(response);}, (error: any) => {
        event.target.files = null;
      });

    };
  }

  private _filterItem(name: string) {
      const args = name.toLowerCase();
      return this.service.app.data.items.data.filter((item: any) => JSON.stringify(item).toLowerCase().includes(args));
  }

  oncheckboxTextChanged(element: any){
    return parseFloat(element) !== 0;
  }

  private _filterQuestion(label: string) {
    const filterValue = label.toLowerCase();
    return this.formService.questions.filter((question: any) => question.label.toString().toLowerCase().includes(filterValue));
  }

  private _filterOptional(label: string) {
    const filterValue = label.toLowerCase();
    return this.incomingQuestion.getOptions().filter((optional: any) => optional.label.toString().toLowerCase().includes(filterValue));
  }

  private _filterOptionalQuestion(label: string) {
    const filterValue = label.toLowerCase();
    return this.incomingSelectedOptional.getQuestions().filter((questional: any) => questional.label.toString().toLowerCase().includes(filterValue));
  }

  displayFnItem(value: any){
    return value.name;
  }

  onOptionalSelectionChanged(event: MatAutocompleteSelectedEvent, field){
    this.incomingQuestion.setUnknown(field, event.option.value.cntrl_num.toString());
  }

  onOptionalQuestionSelectionChanged(event: MatAutocompleteSelectedEvent, field){
    this.incomingSelectedOptional.setUnknown(field, event.option.value.cntrl_num.toString());
  }

  onQuestionSelectionChanged(event: MatAutocompleteSelectedEvent, field){
    this.incomingQuestion.setUnknown(field, event.option.value.cntrl_num.toString());
  }

  displayFn(value: any){
    return value.label;
  }

  exportOptions() {
    setTimeout(() => {
      this.outgoingRequest.setAttributes({});
      this.outgoingRequest.setQuestionId(this.incomingQuestion.getId());
      this.form = document.createElement('form');
      this.form.action  = this.service.app.settings.routes.server + '/questions/undefined/options/exportrequest';
      this.form.method  = 'get';
      this.form.enctype = 'multipart/form-data';
      this.hidden       = document.createElement('input');
      this.hidden.name  = 'token';
      this.hidden.type  = 'hidden';
      this.hidden.value = this.service.getToken();
      this.form.appendChild(this.hidden);
      for (const key in this.outgoingRequest.getAttributes()) {
      this.hidden       = document.createElement('input');
      this.hidden.name  = key;
      this.hidden.type  = 'hidden';
      this.hidden.value = this.outgoingRequest.attributes[key];
      this.form.appendChild(this.hidden);
      }
      $('#form').append(this.form);
      this.form.submit();
    });
  }


}
