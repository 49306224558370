import {Component, Inject, Input, ViewChild, ElementRef, OnInit} from '@angular/core';
import {CampaignService} from '../../campaigns/campaign.service';
import {TeamService} from '../team.service';
import {BroadcastChannelService} from "../../broadcast-channel.service";
import {NotifierService} from "angular-notifier";
import {Parameters} from "../../parameters";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BrunchesService} from "../brunches/brunches.service";
import {FormService} from "../../forms/form.service";
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/internal/operators';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {AppService} from "../../app.service";

@Component({
  selector: 'app-list-teams',
  templateUrl: './list-teams.component.html',
  styleUrls: ['./list-teams.component.scss']
})
export class ListTeamsComponent implements OnInit {
  @Input() tabAllign;
  @ViewChild('suggestions', {static: false}) elementSuggestions: any;
  @ViewChild('trigger', {static: false}) elementTrigger: any;
  subscription: any;
  campaignId: any = null;
  teams: any = [];
  incomingTeam: any;
  incomingCampaign: any;
  campaignSelectedId: any;
  incomingBranch: any;
  selectedIndex: any = 1;
  filteredSuggestions: any;
  suggestionControl = new FormControl();
  incomingForm: any;
  outgoingRequest: any;
  incomingItemSelected: any;
  incomingDescription: any;
  incomingSettings: any;
  items: any = [];
  question: any;
  incomingQuestion: any;
  strings: any;
  constructor(private broadcastChannel: BroadcastChannelService,
              private notifier: NotifierService,
              public formService: FormService,
              private teamService: TeamService,
              public service: AppService,
              public brunchService: BrunchesService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private campaignService: CampaignService,
              public dialogRef: MatDialogRef<ListTeamsComponent>) {
    this.incomingTeam = new Parameters();
    this.incomingCampaign = new Parameters();
    this.strings = new Parameters();
    this.incomingForm = new Parameters();
    this.incomingBranch = new Parameters();
    this.outgoingRequest = new Parameters();
    this.incomingItemSelected = new Parameters();
    this.incomingDescription = new Parameters();
    this.incomingSettings = new Parameters();
    this.incomingQuestion = new Parameters();
    this.strings.setAttributes(this.service.app.strings.params);
    this.incomingTeam.setAttributes({});
    this.incomingItemSelected.setAttributes({});
    this.elementSuggestions = ElementRef;
    this.elementTrigger = MatAutocompleteTrigger;
    this.incomingCampaign.setAttributes(this.campaignService.selected);
  }

    ngOnInit(): void {
        if(this.incomingCampaign.getTeams()[this.strings.getStatus()] === 0) {
            this.campaignService.getTeamsByCampaignId(this.incomingCampaign.getId(), (teams) => {
                if(teams !== null) {
                    this.teams = this.teamService.getTeamsByCampaignId(this.incomingCampaign.getId());
                    this.incomingCampaign.getTeams()[this.strings.getStatus()] = 1;
                }
            });
        }else this.teams = this.teamService.getTeamsByCampaignId(this.incomingCampaign.getId());
        this.filteredSuggestions = this.suggestionControl.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.description),
            map(name => name ? this._filterSuggestion(name) : this.items.slice())
        );
        this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
            .subscribe((item: any) => {
                if(item instanceof Object){
                    if(item.hasOwnProperty('campaignId')){
                        this.campaignId = item.campaignId;
                        this.onCampaignSelected();
                    }else if(item.hasOwnProperty('team')){
                        if(this.campaignId !== null)
                            this.onCampaignSelected();
                        this.selectedIndex = 0;
                        setTimeout(()=>{
                            this.selectedIndex = 1;
                        }, 200);
                    }
                }
            });
    }

  getItemIdByCntrlNum(cntrlNum: any, callback){
      this.outgoingRequest.setAttributes({});
      this.outgoingRequest.setCntrlNum(this.incomingItemSelected.getCntrlNum());
     this.service.httpService( 'post', this.service.app.settings.routes.items.get, this.outgoingRequest.getAttributes(), {},(response: any) => {
      callback(response);
      },  (error: any) => {
      });
  }

    onSelectTeam(team: any){
        this.incomingItemSelected.setAttributes(team);
        this.teamService.teamSelectedId = this.incomingItemSelected.getId();
        this.teamService.setSelected(this.incomingItemSelected.getAttributes());
    }

  closeDialog(){
    this.dialogRef.close(null);
  }

  onSuggestionSelectionChanged(event: MatAutocompleteSelectedEvent){
        this.suggestionControl.setValue(event.option.value);
        this.incomingItemSelected.setAttributes(event.option.value);
  }

  getItemId(callback: any){
   if(this.incomingItemSelected.attributes.hasOwnProperty('item_id')){
    callback(this.incomingItemSelected.getItemId());
    }else{this.outgoingRequest.setAttributes({});
    this.outgoingRequest.setCntrlNum(this.incomingItemSelected.getCntrlNum());
    this.outgoingRequest.setItemTypeId(1);
    this.outgoingRequest.setStatus(0);
    this.outgoingRequest.setName(this.incomingItemSelected.getLabel());
    this.service.httpService( 'post', this.service.app.settings.routes.items.store, this.outgoingRequest.getAttributes(), {notify: false},(response: any) => {
        this.incomingTeam.setAttributes(response);
        this.incomingItemSelected.setItemId(this.incomingTeam.getId());
        callback(this.incomingTeam.getId());
    },  (error: any) => {
     this.getItemIdByCntrlNum(this.outgoingRequest.getCntrlNum(), (item: any) => {
      this.incomingTeam.setAttributes(item);
      this.incomingItemSelected.setItemId(this.incomingTeam.getId());
      callback(this.incomingTeam.getId());
     });
    });
   }
  }

  addStock(team: any, qty: any){
   if(!this.service.isNull(qty)){
      if(Object.keys(this.incomingItemSelected.getAttributes()).length > 0){
       this.getItemId((itemId: any) => {
           this.incomingTeam.setAttributes(team);
           this.outgoingRequest.setAttributes({});
           this.outgoingRequest.setReference1(this.incomingTeam.getId());
           this.outgoingRequest.setReference2('recItms');
           this.outgoingRequest.setReference3('team');
           this.outgoingRequest.setReference4(this.incomingItemSelected.getCntrlNum());
           this.outgoingRequest.setItemId(itemId);
           this.outgoingRequest.setInOutQty(qty);
           this.outgoingRequest.setDescription('');
           this.service.httpService( 'post', this.service.app.settings.routes.inventories.store, this.outgoingRequest.getAttributes(), {},(response: any) => {

               },  (error: any) => {
               });
       });
      }
   }
  }

  displayFn(value: any){
        return value.label;
  }



  private _filterSuggestion(name: string) {
    const filterValue: any = name.toString().toLowerCase();
    return this.items.filter((item: any) => item.toString().toLowerCase().includes(filterValue));
  }

  getBranchByBranch(branch: any){
    return branch = Object.assign(branch, this.brunchService.getBrunchByBrunch(branch));
  }

  addBrunch(team: any){
    setTimeout(() => {
      this.incomingTeam.setAttributes(team);
      this.teamService.teamSelectedId = this.incomingTeam.getId();
      this.teamService.setSelected(this.incomingTeam.getAttributes());
      this.brunchService.openDialog({teamId: this.incomingTeam.getId()});
    });
  }

  addTeam(){
    this.campaignService.setSelected(this.campaignId === null ? {} : this.incomingCampaign.getAttributes());
    this.teamService.setSelected({});
    this.teamService.openDialog({});
  }

  onCampaignSelected() {
    this.teams = [];
    const campaign = this.campaignService.getCampaignById(this.campaignId);
    this.incomingCampaign.setAttributes(campaign);
    this.incomingCampaign.getTeams().data.map((team: any) => {
      this.incomingTeam.setAttributes(team);
      this.teams.push(this.teamService.getTeamById(this.incomingTeam.getId()));
    });
  }

  onSelectedTeamChange(team: any){
    this.incomingTeam.setAttributes(team);
    this.teamService.teamSelectedId = this.incomingTeam.attributes.id;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
