import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Parameters} from '../../parameters';
import {FormService} from '../form.service';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {FlatTreeControl, NestedTreeControl} from "@angular/cdk/tree";
import {MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource} from "@angular/material/tree";
import {AppService} from "../../app.service";

interface QuestionNode {
  name: string;
  children?: QuestionNode[];
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-treeview-form',
  templateUrl: './treeview-form.component.html',
  styleUrls: ['./treeview-form.component.scss']
})
export class TreeviewFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() questions: any;
  treedata: any = [];
  subscription: any;
  recursive: any = false;
  strings: any;
  output;
  controls: any = [];
  flattenQuestions: any;
  constructor(public formService: FormService,
              public service: AppService,
              private broadcastChannel: BroadcastChannelService) {
    this.strings = new Parameters();
    this.output = new Parameters();
    this.flattenQuestions = new Parameters();
    this.strings.setAttributes(this.service.app.strings.params);
    this.output.setAttributes({});
  }

  private _transformer = (node: QuestionNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name      : node.name,
      node      : node,
      level     : level,
    };
  }

  setCheckbox(node: any, checkbox: any, event: any){
    let incomingNode: any = new Parameters();
    incomingNode.setAttributes(node.node);
// checkbox.checked = !checkbox.checked;
// (<HTMLInputElement> document.getElementById(incomingNode.getCntrlNum())).checked = true;
    if(!incomingNode.getAttributes().hasOwnProperty(this.strings.getOptions())){
      if(incomingNode.getAttributes().hasOwnProperty(this.strings.getFormControlId())){
        if(!this.output.hasAttribute(incomingNode.getCntrlNum())){
          let incomingQuestion: any = new Parameters();
          incomingQuestion.setAttributes(this.flattenQuestions.getUnknown(incomingNode.getFormControlId()));
          let incomingOutput: any = new Parameters();
          incomingOutput.setAttributes({});
          incomingOutput.setOptionId(incomingNode.getId());
          incomingOutput.setData(incomingNode.getLabel());
          incomingOutput.setCntrlNum(incomingNode.getCntrlNum());
          incomingOutput.setControlId(incomingQuestion.getControlId());
          incomingOutput.setFormControlId(incomingQuestion.getId());
          this.output.setUnknown(incomingNode.getCntrlNum(), incomingOutput.getAttributes());
        }else delete this.output.attributes[incomingNode.getCntrlNum()];
      }
    }
    setTimeout(() => {
      this.broadcastChannel.emitNavChangeEvent({action: this.strings.getTreeNode(), item: this.output.getAttributes()});
    });
  }

  onClick(node: any){
    let incomingNode: any = new Parameters();
    incomingNode.setAttributes(node.node);
    if(incomingNode.hasAttribute(this.strings.getOptions())){}
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
      node => node.level, node => node.expandable);

  treeFlattener: any = new MatTreeFlattener(
      this._transformer, node =>
          node.level, node =>
          node.expandable, node =>
          node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);




  ngOnInit(): void {
    this.flattenQuestions.setAttributes(this.service.getJSObjectByArrayAndLabel(this.questions, this.strings.getId()));
    this.dataSource.data = this.treedata;
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          if(item instanceof Object) {
            if (item.hasOwnProperty(this.strings.getAction())) {
              let incomingItem: any = new Parameters();
              incomingItem.setAttributes(item);
              if (incomingItem.getAction() === this.strings.getTreenodes())
                this.nodes();
            }
          }
        });
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;


  ngAfterViewInit(): void {}

  nodes(){
    this.treedata = [];
    this.formService.questions.map((question: any) => {
      if(question.hasOwnProperty(this.strings.getOptions())){
        question.options.map((opt: any) => {
          if(opt.label.toString().includes('null')){
            this.formService.showQuestions(opt);
          }else this.formService.hideOptionalQuestions(opt);
        });
      }
    });
    this.formService.loadHiddenQuestions();
    this.formService.questions.map((question: any) => { this.appendChildrenNode(question, 0); });
    this.dataSource.data = this.treedata;
    return this.dataSource;
  }


  appendChildrenNode(question: any, flag: any){
    const incomingQuestion = new Parameters();
    incomingQuestion.setAttributes(question);
    incomingQuestion.setName(incomingQuestion.getLabel());
    incomingQuestion.setChildren([]);
    if(parseFloat(incomingQuestion.getVisible().toString()) === 1)
      this.treedata.push(incomingQuestion.getAttributes());
    if(incomingQuestion.attributes.hasOwnProperty(this.strings.getOptions())){
      incomingQuestion.getOptions().map((optional: any) => {
        let incomingOption: any = new Parameters();
        incomingOption.setAttributes(optional);
        incomingOption.setName(incomingOption.getLabel());
        incomingOption.setChildren([]);
        incomingOption.setNumber(incomingQuestion.getCntrlNum());
      //if(incomingQuestion.getControl().id === 5){
          incomingQuestion.getChildren().push(incomingOption.getAttributes());
          if(incomingOption.getAttributes().hasOwnProperty(this.strings.getQuestions())){
            incomingOption.getQuestions().map((q: any) => {
              let incomingOptionQuestion: any = new Parameters();
              incomingOptionQuestion.setAttributes(q);
              incomingOptionQuestion.setChildren([]);
              incomingOptionQuestion.setName(incomingOptionQuestion.getLabel());
              incomingOption.getChildren().push(incomingOptionQuestion.getAttributes());
              let finalQ = this.formService.questions.filter((questional: any) => {
                return questional[this.strings.getCntrlNum()].includes(incomingOptionQuestion.getCntrlNum()); })[0];
              incomingOptionQuestion.attributes = Object.assign(incomingOptionQuestion.attributes, finalQ);
              this.appendChildrenNode(incomingOptionQuestion.attributes, 1);
            });
          }
      //}
      });
    }
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
  }
}
