<mat-form-field  class="example-full-width">
    <textarea matInput type="text"
              [(ngModel)]="question.label"
              placeholder="{{question.label.length === 0 ? 'Untitled Question' : ''}}">
    </textarea>
    <span *ngFor="let control of controls; let controlOf = index"
          ngClass="pull-right">
        <i class="material-icons pull-left"
           [ngStyle]="{'color':'#888','cursor':'pointer'}"
           *ngIf="control.id === question.control.id">
            {{control.icon}}
        </i>
    </span>
</mat-form-field>
