import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppService} from "../app.service";
import {FormService} from "../forms/form.service";
import {AmbassadorsService} from "../ambassadors/ambassadors.service";
import {Parameters} from "../parameters";
import {Geolocation} from "@capacitor/geolocation";
import {WaypointService} from "../waypoints/waypoint.service";
import {EntityService} from "../entities/entity.service";
import {Router} from "@angular/router";
import {ResponseService} from "../forms/responses/data/response.service";
import InfoWindow = google.maps.InfoWindow;
import {MatMenuTrigger} from "@angular/material/menu";
import {OrderService} from "./order.service";

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit, OnDestroy {
  @ViewChild('addressId', {static: false})  addressId: any;
  @ViewChild(MatMenuTrigger) contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  incomingForm: any;
  infoWindows: any = [];
  incomingUser: any;
  strings: any;
  orders: any = [];
  googleCurrentIcon: any = null;
  agmCircle: any;
  agmCirclelat: any = 0;
  agmCirclelng: any = 0;
  zoom: any = 15;
  icon: any;
  currentlat: any = 0;
  currentlng: any = 0;
  agmIcon: any;
  marker3: any;
  marker2: any;
  interval: any;
  minute: any = 60 * 1000;
  positionOptions: any = {
    // timeout: 80000,
    // maximumAge: 60000,
    enableHighAccuracy: true,
  };
  pin: any;
  constructor(public service: AppService,
              private waypointService: WaypointService,
              private responseServce: ResponseService,
              private orderService: OrderService,
              private entityService: EntityService,
              private router: Router,
              private formService: FormService,
              private ambassadorService: AmbassadorsService) {
    this.incomingForm = new Parameters();
    this.incomingUser = new Parameters();
    this.strings = new Parameters();
    this.agmCircle = new Parameters();
    this.contextMenu = MatMenuTrigger;
    this.incomingForm.setAttributes(this.formService.getSelected());
    this.agmCircle.setAttributes({});
    this.incomingUser.setAttributes({});
    this.strings.setAttributes(this.service.app.strings.params);
    this.icon = {url: 'https://static.vecteezy.com/system/resources/thumbnails/005/337/737/small/icon-delivery-silhouette-illustration-free-vector.jpg', scaledSize: {height: 80, width: 80}};
    this.agmIcon = {url: this.service.app.routes.app.agm.icon, scaledSize: {width: 70, height: 70}};
    this.marker2 = {url: this.service.app.routes.app.agm.marker2, scaledSize: {width: 70, height: 70}};
    this.marker3 = {url: this.service.app.routes.app.agm.marker3, scaledSize: {width: 70, height: 40}};
    this.marker3 = {url: this.service.app.routes.app.agm.marker3, scaledSize: {width: 70, height: 40}};
    this.googleCurrentIcon = {url: this.service.app.routes.app.agm.googleCurrentIcon, scaledSize: {width: 70, height: 73}};
    this.pin = {url: this.service.app.routes.app.agm.pin, scaledSize: {width: 70, height: 70}};
    this.addressId = ElementRef;
    // this.interval = setInterval((e: any) => {
    //   this.addCurrentUserlocation();
    // }, this.minute);
  }

  ngOnDestroy(): void {
        clearInterval(this.interval);
  }

  onGoogleMapAutocompleteSelected(result: any) {
    const geomentry: any = new Parameters();
    geomentry.setAttributes({});
    geomentry.setAddress(this.addressId.nativeElement.value);
    geomentry.setLat(result.geometry.location.lat());
    geomentry.setLng(result.geometry.location.lng());
 // this.broadcastChannel.emitNavChangeEvent({action: this.strings.getLocation(), item: geomentry.getAttributes()});
    this.addressId.nativeElement.value = '';
    this.zoom = 5;
    setTimeout(() => {
      this.agmCircle.setAddress(geomentry.getAddress());
      this.agmCircle.setLat(geomentry.getLat());
      this.agmCircle.setLng(geomentry.getLng());
      this.zoom = 13;
    }, this.service.timeout());
  }

  onLocationSelected(location: any) {}

  onContextMenu(event: MouseEvent, item: any) {
    event.preventDefault();
    this.contextMenuPosition.x = (event.clientX - 10) + 'px';
    this.contextMenuPosition.y = (event.clientY) + 'px';
    this.contextMenu.menuData = { item: item };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  addDelivery(order: any){
    let incomingOrder = new Parameters();
    let outgoingRequest = new Parameters();
    incomingOrder.setAttributes(order);
    outgoingRequest.setAttributes({});
    outgoingRequest.setResponseId(incomingOrder.getId());
    this.orderService.addDelivery(outgoingRequest, () => {});
  }

  onmapClick(){
    setTimeout(() => {
      this.infoWindows.map((infoWindow: InfoWindow) => {
        if(infoWindow)
          infoWindow.close();
      });this.infoWindows = [];
    });
  }

  onMarkerClick(infoWindow: any){
    this.infoWindows.push(infoWindow);
  }

  addCurrentUserlocation(){
    let outgoingRequest: any = new Parameters();
    let lat: any = this.currentlat;
    let lng: any = this.currentlng;
    outgoingRequest.setAttributes({});
    this.service.geocoder(lat,lng, (address) => {
      if(address !== null){
        let incomingAddress: any = new Parameters();
        incomingAddress.setAttributes(address);
        if(incomingAddress.hasAttribute(this.strings.getAddress4())){
          let incomingAddress4: any = new Parameters();
          incomingAddress4.setAttributes(incomingAddress.getAddress4());
          outgoingRequest.setLat(lat);
          outgoingRequest.setLng(lng);
          outgoingRequest.setPlaceId(incomingAddress4.getPlaceId());
          outgoingRequest.setRouteId(0);
          outgoingRequest.setName(incomingAddress.getAddress());
          outgoingRequest.setCntrlNum(this.service.random());
          this.waypointService.add(outgoingRequest.getAttributes(), (waypoint: any) => {
            if(waypoint !== null){
              let incomingWaypoint: any = new Parameters();
              incomingWaypoint.setAttributes(waypoint);
              outgoingRequest.setAttributes({});
              outgoingRequest.setEntity1(this.incomingUser.getId());
              outgoingRequest.setEntity2(incomingWaypoint.getId());
              outgoingRequest.setSample(this.strings.getUser() + this.strings.getDot() + this.strings.getWaypoints());
              this.entityService.add(outgoingRequest.getAttributes(), (userWaypoint: any) => {
              });
            }
          });
        }
      }
    });
  }

  ngOnInit(): void {
    document.getElementsByTagName( 'body')[0].style.overflow = 'hidden';
    Geolocation.watchPosition(this.positionOptions, (pos: any, err: any) => {
      if(this.currentlng === 0)this.zoom = 13;
      this.currentlat = parseFloat(pos.coords.latitude.toString());
      this.currentlng = parseFloat(pos.coords.longitude.toString());
    });
    this.incomingUser.setAttributes(this.service.app.data.status === 1 ? this.service.app.data.user : {});
    this.service.matSpinner = false;
    this.getOrders(10, (orders: any) => {
      if(this.orders.length === 0){
        this.responseServce.setUserId(this.incomingUser.getId());
        this.router.navigateByUrl((
                this.strings.getSlash() +
                this.strings.getApp() +
                this.strings.getSlash() +
                this.strings.getAmbassadors() +
                this.strings.getSlash()) +
            this.service.encrypt(this.incomingUser.getId()) +
            this.strings.getSlash() +
            this.strings.getResponses());
      }
    });
  }

  getOrders(limit: any, call: any){
    this.service.matSpinner = true;
    let outgoingRequest: any = new Parameters();
    outgoingRequest.setAttributes({});
    outgoingRequest.setFormId(this.incomingForm.getId());
    if(this.orders.length !== 0)
      outgoingRequest.setAscendinglastAt(this.orders[this.orders.length - 1][this.strings.getId()]);
    this.service.httpService('POST', this.service.app.routes.orders.search,
        outgoingRequest.getAttributes(),
        {ignoreLoadingBar: 'true', notify: false},
        (orders: any) => {
          orders.map((order: any) => {
            let incomingOrder: any = new Parameters();
            incomingOrder.setAttributes(order);
            if(this.service.findIntegerIndex(this.orders, this.strings.getId(), incomingOrder.getId()) === -1)
              this.orders.push(incomingOrder.getAttributes());
          }); if(orders.length !== 0){
            limit -= parseFloat('1');
            this.getOrders(limit, call);
          }else call(null);
        },(error: any) => {
          setTimeout(() => {this.getOrders(limit, call);});
        });

    // this.service.matSpinner = true;
    // let outgoingRequest: any = new Parameters();
    // outgoingRequest.setAttributes({});
    // outgoingRequest.setReference2(this.incomingForm.getId());
    // outgoingRequest.setSample(this.strings.getDelivery() + this.strings.getDot() + this.strings.getUsers());
    // if(this.incomingUser.hasAttribute(this.strings.getId()) && parseFloat(this.incomingUser.getRoleId()) === 8)
    //   outgoingRequest.setEntity2(this.incomingUser.getId());
    // if(this.orders.length !== 0)
    //   outgoingRequest.setAscendinglastAt(this.orders[this.orders.length - 1][this.strings.getId()]);
    // this.service.httpService('POST', this.service.app.routes.orders.search,
    //     outgoingRequest.getAttributes(),
    //     {ignoreLoadingBar: 'true', notify: false},
    //     (orders: any) => {
    //       orders.map((order: any) => {
    //         let incomingOrder: any = new Parameters();
    //         incomingOrder.setAttributes(order);
    //         if(this.service.findIntegerIndex(this.orders, this.strings.getId(), incomingOrder.getId()) === -1)
    //           this.orders.push(incomingOrder.getAttributes());
    //       }); if(orders.length !== 0){
    //         limit -= parseFloat('1');
    //         this.requestOrders(limit, call);
    //       }else call(null);
    //     },(error: any) => {
    //       setTimeout(() => {this.requestOrders(limit, call);});
    //     });
  }

}
