import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../app.service';
import {NotifierService} from 'angular-notifier';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Parameters} from '../parameters';
import {map, startWith} from 'rxjs/internal/operators';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import {ClientService} from './client.service';
import {DialogTeamsComponent} from '../teams/dialog/dialog-teams.component';
import {BroadcastChannelService} from '../broadcast-channel.service';

@Component({
  selector: 'app-dialog-clients',
  templateUrl: './dialog-clients.component.html',
  styleUrls: ['./dialog-clients.component.scss']
})
export class DialogClientsComponent implements OnInit, OnDestroy {
  app: any;
  configAngularEditor: any;
  formValues: FormGroup = new FormGroup({});
  teamControl = new FormControl();
  filteredTeamsOptions: any;
  htmlContent: any;
  parameters: any;
  params: any;
  incomingClientSelected: any;
  keys: any;
  selectedClient: any;
  addressValue: any;
  logo: any;
  position: any;
  constructor(public service: AppService,
              private notifier: NotifierService,
              public formBuilder: FormBuilder,
              private dialog: MatDialog,
              public broadcastChannel: BroadcastChannelService,
              public metthods: ClientService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DialogClientsComponent>) {
    this.app = this.service.app;
    this.configAngularEditor = this.service.setAngularEditorConfig();
    this.htmlContent = null;
    this.parameters = new Parameters();
    this.parameters.setAttributes({});
    this.incomingClientSelected = new Parameters();
    this.incomingClientSelected.setAttributes(metthods.getSelected());
    this.keys = Object.keys(this.incomingClientSelected.getAttributes()).length;
    this.selectedClient = metthods.getSelected();
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.dialogRef.afterClosed().subscribe(result => {
      this.metthods.setSelected({});
    });
    this.addressValue = '';
    this.formValues = this.formBuilder.group({
      name:        ['', Validators.compose([])],
      description: ['', Validators.compose([])],
      phone:       ['', Validators.compose([])],
      email:       ['', Validators.compose([])],
      logo:        ['', Validators.compose([])],
      location:    ['', Validators.compose([])],
    });

    if(this.keys === 0) this.selectedClient = {}; else{
      this.logo = this.incomingClientSelected.getLogo();
      this.formValues.controls['name'].setValue(this.incomingClientSelected.getName());
      this.formValues.controls['description'].setValue(this.incomingClientSelected.getDescription());
      this.formValues.controls['phone'].setValue(this.incomingClientSelected.getPhone());
      this.formValues.controls['email'].setValue(this.incomingClientSelected.getEmail());
      this.formValues.controls['location'].setValue(this.incomingClientSelected.getLocation());
      this.formValues.addControl('id', new FormControl(this.incomingClientSelected.getId(), Validators.compose([])));
      this.addressValue = this.incomingClientSelected.getLocation();
      for(var key in this.incomingClientSelected.getAttributes()) {
        if(this.incomingClientSelected.attributes[key] instanceof Object || this.incomingClientSelected.attributes[key] instanceof Array){} else{
          if(this.formValues.contains(key)) {
            this.formValues.controls[key].setValue(this.incomingClientSelected.attributes[key]);
          }else {
            if(this.incomingClientSelected.attributes[key] instanceof Object || this.incomingClientSelected.attributes[key] instanceof Array){}
            else this.formValues.addControl(key, new FormControl(this.incomingClientSelected.attributes[key], Validators.compose([])));

          }
        }
      }
    }

    setTimeout(() => {
      this.filteredTeamsOptions = this.teamControl.valueChanges.pipe(
        startWith(),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterTeam(name) : this.app.data.teams.data.slice())
      );
    });
  }

  public handleAddressChange(address: Address) {
    console.log(address);
  }

  addTeam(){
    this.dialog.open(DialogTeamsComponent, {data  : {}, width : '55%', height: '100%', });
  }


  onGoogleMapAutocompleteSelected(result: PlaceResult) {
    this.formValues.controls['location'].setValue(result.formatted_address);
  }

  onLocationSelected(location: any) {
    console.log('onLocationSelected: ', location);
  }

//  onLocationSelected(location: Location) {
//     console.log('onLocationSelected: ', location);
//  }

  handlEditorFocus(){}

  handleFileInput(event: any){
    const files: FileList = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.formValues.controls['logo'].setValue(this.service.dataURItoBlob(reader.result));
      this.logo = reader.result;
    };
  }



  private _filterTeam(name: string) {
    const filterValue = name.toLowerCase();
    return this.app.data.teams.data.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  closeDialog(){
    this.dialogRef.close(null);
  }

  onChangeAddressText(event: any){
    this.formValues.controls['location'].setValue(event.target.value);
    this.formValues.controls['address'].setValue(event.target.value);
  }

//  handleAddressChange(result: PlaceResult){
//  }


  onTeamSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.formValues.controls['team_id'].setValue(event.option.value.id);
  }

  displayFn(value: any){
    return value.name;
  }

  onSubmit(ambassadorForm: any) {
    this.service.httpService(
      'post', this.keys === 0 ? '/clients' : '/clients/' + this.incomingClientSelected.getId() + '/update',
      ambassadorForm, {},
      (response: any) => {
        if(this.keys === 0) {
          this.service.app.data.clients.data.unshift(response);
        } else this.selectedClient = Object.assign(this.selectedClient, response);
        this.closeDialog();
        this.notifier.notify('success', 'successfull ' + (this.keys === 0 ? 'added' : 'updated' ));
        this.selectedClient = {};
        this.broadcastChannel.emitNavChangeEvent({client: response});
      }, (error: any) => {
      });

  }

}
