import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormService} from "../../../../forms/form.service";
import {Parameters} from "../../../../parameters";
import {AppService} from "../../../../app.service";
import {ClientService} from "../../../../clients/client.service";
import {StorageMap} from "@ngx-pwa/local-storage";

@Component({
  selector: 'app-details-account',
  templateUrl: './details-account.component.html',
  styleUrls: ['./details-account.component.scss']
})
export class DetailsAccountComponent implements OnInit, OnDestroy {

  incomingForm: any;
  incomingFormSettings: any;
  incomingClient: any;
  tickets: any = 1;
  name: any = null;
  invoice: any = null;
  details: any = null;
  total: any = null;
  packages: any = [];
  strings: any;
  constructor(private formService: FormService,
              private clientService: ClientService,
              private storage: StorageMap,
              public service: AppService) {
    this.strings = new Parameters();
    this.incomingForm = new Parameters();
    this.incomingFormSettings = new Parameters();
    this.incomingClient = new Parameters();
    this.incomingClient.setAttributes(this.clientService.getSelected());
    this.incomingForm.setAttributes(this.formService.getSelected());
    this.strings.setAttributes(this.service.app.strings.params);
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#fff';
  }

  ngOnDestroy(): void {
    if(this.incomingForm.getAttributes().hasOwnProperty(this.strings.getId())) {
      setTimeout(() => {
        if(window.location.href.includes(this.strings.getWait()))
          window.history.back();
      });
    }
  }

  ngOnInit(): void {
    if(this.incomingClient.getAttributes().hasOwnProperty(this.strings.getId()))
      this.service.logo = this.incomingClient.getLogo();
    if(this.incomingForm.getAttributes().hasOwnProperty(this.strings.getId())){
      this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
      this.incomingForm.getControls().map((question: any) => {
        let incomingQuestion: any = new Parameters();
        incomingQuestion.setAttributes(question);
        if(incomingQuestion.getCntrlNum() === this.incomingFormSettings.getPaymentMethod()){
          this.details = incomingQuestion.getLabel();
        }else if(incomingQuestion.getCntrlNum() === this.incomingFormSettings.getTickets()){
          if(!this.service.isNull(incomingQuestion.getData())) {
            this.tickets = parseFloat(incomingQuestion.getData());
            this.total = this.service.isNull(this.total) ?
                parseFloat(incomingQuestion.getData()) :
                (this.total * parseFloat(incomingQuestion.getData()));
          }

        }else if(incomingQuestion.getCntrlNum() === this.incomingFormSettings.getInvoice()){
          this.invoice = incomingQuestion.getData();
        }else if(incomingQuestion.getCntrlNum() === this.incomingFormSettings.getPackages()){
          incomingQuestion.getOptions().map((option: any) => {
            let incomingOption: any = new Parameters();
            incomingOption.setAttributes(option);
            let incomingOptionSettings: any = new Parameters();
            incomingOptionSettings.setAttributes(incomingOption.getSettings());
            if(JSON.stringify(incomingQuestion.getResult()).includes(incomingOption.getCntrlNum())){
              this.packages.push(incomingOption.getLabel());
              if(incomingOptionSettings.hasAttribute(this.strings.getSellingPrice()) &&
                  !this.service.isNull(incomingOptionSettings.getSellingPrice())){
                this.total = this.service.isNull(this.invoice) ? parseFloat(incomingOptionSettings.getSellingPrice()) : (this.total * parseFloat(incomingOptionSettings.getSellingPrice()));
              }
            }
          });
        }else if(incomingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeeName()){
          this.name = incomingQuestion.getData();
        }
      });
      // if(this.service.isNull(this.packages.length === 0)) window.location.replace(this.incomingFormSettings.getRedirectUrl());
    }
    else{
      this.storage.has(this.strings.getForm()).subscribe((status: any) => {
        if(status){
          this.storage.get(this.strings.getForm()).subscribe((form: any) => {
            if(form.hasOwnProperty(this.strings.getId())){
              this.incomingForm.setAttributes(form);
              this.ngOnInit();
            }
          });
        }
      });
    }
  }

}
