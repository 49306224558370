import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTabsModule} from '@angular/material/tabs';
import { MatIconModule} from '@angular/material/icon';
import { MatGridListModule} from '@angular/material/grid-list';
import { MatBadgeModule} from '@angular/material/badge';
import { WebcamModule} from 'ngx-webcam';
import { MatStepperModule} from '@angular/material/stepper';
import { NgxWheelModule} from "ngx-wheel";
import { TreeviewModule} from 'ngx-treeview';
import { MatTreeModule} from '@angular/material/tree';
import { AgmOverlays } from "agm-overlays"
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatInputModule} from '@angular/material/input';
import { AngularEditorModule} from '@kolkov/angular-editor';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from 'angularx-social-login';
import { GooglePlaceModule} from 'ngx-google-places-autocomplete';
import { MatGoogleMapsAutocompleteModule} from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule} from '@agm/core';
import { MatSidenavModule} from '@angular/material/sidenav';
import { GoogleChartsModule} from 'angular-google-charts';
import { MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { MatButtonModule} from '@angular/material/button';
import { MatDialogModule} from '@angular/material/dialog';
import { MatMenuModule} from '@angular/material/menu';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { FilterPipe } from './filter.pipe';
import { JsonFilterPipe } from './json-filter.pipe';
import { JsonPropertyFilterPipe } from './json-property-filter.pipe';
import { NotifierModule, NotifierOptions} from 'angular-notifier';
import { MatSnackBarModule} from '@angular/material/snack-bar';
import { MatListModule} from '@angular/material/list';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatRadioModule} from '@angular/material/radio';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { HighchartsChartModule} from 'highcharts-angular';
import { MorrisJsModule} from 'angular-morris-js';
import { NgxCaptchaModule} from 'ngx-captcha';
import { MatProgressBarModule} from '@angular/material/progress-bar';
import { CarouselModule} from 'ngx-owl-carousel-o';
import { ZXingScannerModule} from '@zxing/ngx-scanner';
import { CountUpModule} from 'ngx-countup';
import { MatCardModule} from '@angular/material/card';
import { LimitToPipe } from './limit-to.pipe';
import { MatTableModule} from '@angular/material/table';
import { MatChipsModule} from '@angular/material/chips';
import { MatFormFieldModule} from '@angular/material/form-field';
import { ThermalPrintModule} from 'ng-thermal-print';
import { AgmDirectionModule} from 'agm-direction';
import { SortPipe } from './sort.pipe';
import { MatExpansionModule} from '@angular/material/expansion';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatSelectModule} from '@angular/material/select';
import { SafeUrlPipe } from './safe-url.pipe';
import { IvyCarouselModule} from 'angular-responsive-carousel';
import { ExtendedModule} from '@angular/flex-layout';
import { SlicePipe } from './slice.pipe';
import { MatNativeDateModule} from '@angular/material/core';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { MainComponent } from './main/main.component';
import { AboutComponent } from './about/about.component';
import { LoginComponent } from './login/login.component';
import { SidebarHeaderComponent} from './header/sidebar-header.component';
import { PageComponent } from './main/page.component';
import { ToolboxComponent } from './main/toolbox.component';
import { SearchComponent } from './main/search.component';
import { DeleteDialogComponent } from './main/delete-dialog.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OverallReportComponent } from './dashboard/chart/overall-report.component';
import { SitemapComponent } from './dashboard/sitemap/sitemap.component';
import { FilterDashboardComponent } from './dashboard/filter-dashboard.component';
import { RegionalsComponent } from './regionals/regionals.component';
import { SparklineComponent } from './dashboard/chart/sparkline/sparkline.component';
import { DistrictsComponent } from './districts/districts.component';
import { TableRegionalComponent } from './regionals/table/table-regional.component';
import { TabsRegionalComponent } from './regionals/sections/tabs-regional.component';
import { DialogRegionalComponent } from './regionals/dialog/dialog-regional.component';
import { DialogDistrictalComponent } from './districts/dialog-districtal.component';
import { TableDistrictalComponent } from './districts/table-districtal.component';
import { TabsDistrictalComponent } from './districts/tabs-districtal.component';
import { DateDialogComponent } from './main/date-dialog.component';
import { DashbaordRegionalComponent } from './regionals/dashbaord-regional.component';
import { OverallComponent } from './dashboard/overall.component';
import { MorrisBarchartComponent } from './dashboard/chart/morris-barchart/morris-barchart.component';
import { PiechartComponent } from './dashboard/chart/piechart/echart/circle/piechart.component';
import { DialogOverallComponent } from './dashboard/dialog-overall.component';
import { TabsClientsComponent } from './clients/tabs-clients.component';
import { DialogClientsComponent } from './clients/dialog-clients.component';
import { TableClientsComponent } from './clients/table-clients.component';
import { ClientsComponent } from './clients/clients.component';
import { FormsComponent } from './forms/forms.component';
import { DialogFormsComponent } from './forms/dialog-forms.component';
import { TabsFormsComponent } from './forms/tables/tabs-forms.component';
import { TableFormsComponent } from './forms/table/table-forms.component';
import { ChoiceControlsComponent } from './forms/controls/choice/control/choice-controls.component';
import { TextControlsComponent} from './forms/controls/text/draft/text-controls.component';
import { NumberControlsComponent } from './forms/controls/number-controls/number-controls.component';
import { ImageControlsComponent } from './forms/controls/image-controls/image-controls.component';
import { VideoControlsComponent } from './forms/controls/video-controls/video-controls.component';
import { DateControlsComponent } from './forms/controls/date/date-controls.component';
import { SideControlsComponent } from './forms/controls/side/side-controls.component';
import { BottomButtonControlsComponent } from './forms/controls/bottom-button-controls/bottom-button-controls.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { TableCampaignsComponent } from './campaigns/table/table-campaigns.component';
import { TabsCampaignsComponent } from './campaigns/tabs-campaigns/tabs-campaigns.component';
import { DialogCampaignsComponent } from './campaigns/dialog/dialog-campaigns.component';
import { AmbassadorsComponent } from './ambassadors/ambassadors.component';
import { TableCampaniesComponent } from './campanies/table-campanies.component';
import { TabsCampaniesComponent } from './campanies/tabs-campanies.component';
import { CampaniesComponent} from './campanies/campanies.component';
import { DialogCampaniesComponent} from './campanies/dialog-campanies.component';
import { DialogAmbassadorsComponent } from './ambassadors/dialog-ambassadors/dialog-ambassadors.component';
import { TableAmbassadorsComponent } from './ambassadors/table-ambassadors/table-ambassadors.component';
import { TabsAmbassadorsComponent } from './ambassadors/tabs-ambassadors/tabs-ambassadors.component';
import { NavTeamsComponent } from './teams/nav/nav-teams.component';
import { DialogTeamsComponent } from './teams/dialog/dialog-teams.component';
import { TableTeamsComponent } from './teams/table/table-teams.component';
import { TeamsComponent } from './teams/teams.component';
import { DialogEmployeesComponent } from './employees/dialog-employees/dialog-employees.component';
import { TableEmployeesComponent } from './employees/table-employees/table-employees.component';
import { TabsEmployeesComponent } from './employees/tabs-employees/tabs-employees.component';
import { EmployeesComponent } from './employees/employees.component';
import { DialogProfileComponent } from './users/dialog-profile/dialog-profile.component';
import { PublicFormsComponent } from './forms/responses/feedback/feedback-forms.component';
import { DescriptionControlsComponent } from './forms/controls/description-controls/description-controls.component';
import { DescriptionPreviewControlsComponent } from './forms/controls/description/description-preview-controls.component';
import { TextPreviewControlsComponent } from './forms/controls/text/preview/text-preview-controls.component';
import { NumberPreviewControlsComponent } from './forms/controls/number/number-preview-controls/number-preview-controls.component';
import { ChoicePreviewControlsComponent } from './forms/controls/choice/preview/choice-preview-controls.component';
import { CheckPreviewControlsComponent } from './forms/controls/check/check-preview-controls/check-preview-controls.component';
import { ResponsesFormComponent } from './forms/responses/data/list/responses-form.component';
import { DialogResponsesFormComponent } from './forms/responses/data/dialog/dialog-responses-form.component';
import { TableResponsesFormComponent } from './forms/responses/data/table/table-responses-form.component';
import { TabsResponsesFormComponent } from './forms/responses/data/tabs/tabs-responses-form.component';
import { NavigationsFormComponent } from './forms/navigations-form/navigations-form.component';
import { DialogPasswordUserComponent } from './users/dialog-password-user/dialog-password-user.component';
import { SuccessfullSuggestionsFormsComponent } from './forms/suggestions/successfull-suggestions-forms/successfull-suggestions-forms.component';
import { HomeInternalSuggestionsComponent } from './forms/suggestions/internal/home-internal-suggestions/home-internal-suggestions.component';
import { SuggestionsComponent } from './suggestions/suggestions.component';
import { DialogLockQuestionnairesComponent } from './questions/dialog-lock-questionnaires/dialog-lock-questionnaires.component';
import { EmailInvitationsComponent } from './invites/email-invitations/email-invitations.component';
import { DialogEmailInvitationsComponent } from './invites/dialog-email-invitations/dialog-email-invitations.component';
import { DialogReplyComponent } from './replies/dialog-reply/dialog-reply.component';
import { RateComponent } from './forms/controls/rate/rate.component';
import { DepartmentsComponent } from './departments/departments.component';
import { DialogDepartmetsComponent } from './departments/dialog-departmets.component';
import { TabsDepartmetsComponent } from './departments/tabs-departmets.component';
import { TableDepartmetsComponent } from './departments/table-departmets/table-departmets.component';
import { DialogInviteFormsComponent } from './forms/invitees/dialog-invite-forms.component';
import { OutletControlsComponent } from './forms/controls/outlets/outlet-controls.component';
import { RatePreviewControlsComponent } from './forms/controls/rate/rate-preview-controls.component';
import { SheetControlsComponent } from './forms/controls/sheets/sheet-controls.component';
import { TextareaControlsComponent } from './forms/controls/textarea/textarea-controls.component';
import { TextareaPreviewControlsComponent } from './forms/controls/textarea/textarea-preview-controls.component';
import { OutletPreviewControlsComponent } from './forms/controls/outlets/preview/outlet-preview-controls.component';
import { SheetPreviewControlsComponent } from './forms/controls/sheets/sheet-preview-controls.component';
import { ImagePreviewControlsComponent } from './forms/controls/images/image-preview-controls.component';
import { VideoPreviewControlsComponent } from './forms/controls/videos/video-preview-controls/video-preview-controls.component';
import { SelectPreviewControlsComponent } from './forms/controls/select/select-preview-controls.component';
import { DatePreviewControlsComponent } from './forms/controls/date/date-preview-controls.component';
import { AddressPreviewControlsComponent } from './forms/controls/address/address-preview-controls.component';
import { SearchFormsComponent } from './forms/search/search-forms.component';
import { ReportQuestionComponent } from './questions/report-question.component';
import { FormsAmbassadorComponent } from './ambassadors/forms-ambassador.component';
import { PreviewFormComponent } from './forms/preview-form.component';
import { TreeviewFormComponent } from './forms/treeview/treeview-form.component';
import { TeamsFormComponent } from './forms/teams/teams-form.component';
import { SettingsFormComponent } from './settings/settings-form.component';
import { ManageTeamMembersComponent } from './teams/members/manage/manage-team-members.component';
import { EchartComponent } from './dashboard/chart/echart/echart.component';
import { UsersComponent } from './users/users.component';
import { ProfileComponent } from './users/profile/profile.component';
import { SearchCampaignsComponent } from './campaigns/campaigns/search-campaigns.component';
import { ListTeamsComponent } from './teams/list/list-teams.component';
import { BarChartjsComponent } from './dashboard/chart/chatjs/bar-chartjs/bar-chartjs.component';
import { LineChartjsComponent } from './dashboard/chart/line-chartjs/line-chartjs.component';
import { MorrisLinechartComponent } from './dashboard/chart/morris-linechart/morris-linechart.component';
import { EchartMinipieComponent } from './dashboard/chart/echart-minipie/echart-minipie.component';
import { EchartPyramidComponent } from './dashboard/chart/echart-pyramid/echart-pyramid.component';
import { EchartSonarComponent } from './dashboard/chart/echart-sonar/echart-sonar.component';
import { LabelComponent } from './forms/controls/label/draft/label.component';
import { GroupsPreviewControlsComponent } from './forms/controls/groups/preview/groups-preview-controls.component';
import { DonutPiechatChatjsComponent} from './dashboard/chart/piechart/chartjs/donut/donut-piechat-chatjs.component';
import { DonutPiechartComponent} from './dashboard/chart/echart/donut-piechart/donut-piechart.component';
import { PiechartChartjsComponent } from './dashboard/chart/piechart/chartjs/circle/piechart-chartjs.component';
import { OpenCampaignsComponent } from './campaigns/open/open-campaigns.component';
import { MenusComponent } from './menus/menus.component';
import { ImageOptionalComponent } from './forms/controls/options/image/image-optional.component';
import { RespondFormComponent } from './forms/responses/respond/respond-form.component';
import { VideoChoiceCreateComponent } from './forms/controls/choice/video/create/video-choice-create.component';
import { BrowseImagesComponent } from './main/images/browse-images/browse-images.component';
import { DraftEmailsComponent } from './forms/controls/emails/draft/draft-emails.component';
import { RespondEmailControlsComponent } from './forms/controls/emails/respond/respond-email-controls.component';
import { CreateFormComponent } from './forms/create/create-form.component';
import { DraftAddressControlsComponent } from './forms/controls/address/draft/draft-address-controls.component';
import { ViewFormComponent } from './forms/view/view-form.component';
import { DashboardFormComponent} from './forms/dashboard/dashboard-form.component';
import { DraftTimeControlsComponent } from './forms/controls/time/draft/draft-time-controls.component';
import { RespondTimeControlsComponent } from './forms/controls/time/respond/respond-time-controls.component';
import { CoordinateDraftControlsComponent } from './forms/controls/coordinates/draft/coordinate-draft-controls.component';
import { CoordinateRespondControlsComponent } from './forms/controls/coordinates/respond/coordinate-respond-controls.component';
import { CampaignViewComponent } from './campaigns/view/campaign-view.component';
import { FooterComponent } from './main/footer/footer.component';
import { CreateCampaignComponent } from './campaigns/create/create-campaign.component';
import { SelectionCampaignComponent } from './campaigns/selection/selection-campaign.component';
import { ListCampaignComponent } from './campaigns/list/list-campaign.component';
import { DraftGroupControlsComponent } from './forms/controls/groups/draft/draft-group-controls.component';
import { AddControlsComponent } from './forms/controls/add/add-controls.component';
import { DraftTableControlsComponent } from './forms/controls/table/draft/draft-table-controls.component';
import { RespondTableControlsComponent } from './forms/controls/table/respond/respond-table-controls.component';
import { DraftInputlistsControlsComponent } from './forms/controls/inputlists/draft/draft-inputlists-controls.component';
import { RespondInputlistsControlsComponent } from './forms/controls/inputlists/respond/respond-inputlists-controls.component';
import { SubmitControlsComponent } from './forms/controls/submit/submit-controls.component';
import { ImageChoiceDraftControlsComponent } from './forms/controls/choice/images/draft/image-choice-draft-controls.component';
import { ImageChoiceRespondControlComponent } from './forms/controls/choice/images/respond/image-choice-respond-control.component';
import { BuildFormComponent } from './forms/build/build-form.component';
import { UntitledFormComponent } from './forms/untitled/untitled-form.component';
import { DescriptionFormComponent } from './forms/description/description-form.component';
import { AddQuestionComponent } from './forms/questions/add/add-question.component';
import { TextCreateControlComponent } from './forms/controls/text/create/text-create-control.component';
import { LabelCreateControlComponent } from './forms/controls/label/feedback/label-create-control.component';
import { CreateChoiceControlComponent} from './forms/controls/choice/create/create-choice-control.component';
import { PdfComponent } from './forms/pdf/pdf.component';
import { ImagesFormComponent } from './forms/images/images-form.component';
import { MapComponent } from './map/map.component';
import { VideosFormComponent } from './forms/videos/videos-form.component';
import { FilesFormComponent } from './forms/files/files-form.component';
import { AmbassadorResponsesComponent } from './ambassadors/responses/ambassador-responses.component';
import { MessageDisplayComponent } from './main/message/message-display.component';
import { DialogUserComponent } from './users/dialog-user/dialog-user.component';
import { ProgressDialogComponent } from './main/progress-dialog/progress-dialog.component';
import { DraftCurrencyControlComponent } from './forms/controls/currency/draft/draft-currency-control.component';
import { RespondCurrencyControlComponent } from './forms/controls/currency/respond/respond-currency-control.component';
import { Dashboard2FormComponent } from './forms/dashboard/dashboard2/dashboard2-form.component';
import { Dashboard3FormComponent } from './forms/dashboard/dashboard3-form/dashboard3-form.component';
import { DashboardClientsComponent } from './clients/dashboard-clients/dashboard-clients.component';
import { Dashboard4FormComponent } from './forms/dashboard/4/dashboard4-form.component';
import { SettingsQuestionsComponent } from './forms/questions/settings/settings-questions.component';
import { OtherOptionQuestionsComponent } from './forms/questions/options/others/other-option-questions.component';
import { UserActivityChartjsComponent } from './dashboard/chart/chatjs/user-activity/user-activity-chartjs.component';
import { HorizontalBarChartjsComponent } from './dashboard/chart/chatjs/bar/horizontal/horizontal-bar-chartjs.component';
import { BrunchTeamsComponent } from './teams/brunches/brunch-teams.component';
import { DialogBrunchTeamsComponent } from './teams/brunches/dialog/dialog-brunch-teams.component';
import { NavBrunchTeamsComponent } from './teams/brunches/nav/nav-brunch-teams.component';
import { TableBrunchTeamsComponent } from './teams/brunches/table/table-brunch-teams.component';
import { ManageTeamBrunchMembersComponent } from './teams/brunches/members/manage/manage-team-brunch-members.component';
import { HighchartPiechartComponent } from './dashboard/chart/piechart/highchart/highchart-piechart.component';
import { Highchart3DBarchartComponent } from './dashboard/barchart/hightcharts/highchart3/highchart3-d-barchart.component';
import { RadarChartjsComponent } from './dashboard/radar/chartjs/radar/radar-chartjs.component';
import { MixedchartHighchartComponent } from './dashboard/mixedcharts/Highcharts/mixedchart/mixedchart-highchart.component';
import { RespondAutocompleteControlsComponent } from './forms/controls/autocomplete/respond/respond-autocomplete-controls.component';
import { ChipDraftControlsComponent } from './forms/controls/chip/draft/chip-draft-controls.component';
import { ChipFeedbackControlsComponent } from './forms/controls/chip/feedback/chip-feedback-controls.component';
import { HeaderJobinnv120Component } from './header/jobinnv1.2.0/header-jobinnv120.component';
import { RoutesInviteeComponent } from './invites/routes/routes-invitee.component';
import { ListInvitesComponent } from './invites/list/list-invites.component';
import { TableInvitesComponent } from './invites/table/table-invites.component';
import { TabInvitesComponent } from './invites/tabs/tab-invites.component';
import { DashboardInvitesComponent } from './invites/dashboard/dashboard-invites.component';
import { MitipleUserActivityComponent } from './dashboard/chart/chatjs/mitiple-user-activity/mitiple-user-activity.component';
import { GoogleChartsComponent } from './plugins/charts/google/google-charts/google-charts.component';
import { OptionSettingsComponent } from './forms/questions/options/settings/option-settings.component';
import { WaypointsDraftComponent } from './forms/controls/waypoints/draft/waypoints-draft.component';
import { WaypointsResponsesComponent } from './forms/controls/waypoints/responses/waypoints-responses.component';
import { AdvertsDraftComponent } from './forms/controls/adverts/draft/adverts-draft.component';
import { AdvertResponsesComponent } from './forms/controls/adverts/responses/advert-responses.component';
import { NextButtonComponent } from './forms/controls/next-button/next-button.component';
import { PromptComponent } from './prompt/prompt.component';
import { RightHeaderComponent } from './header/right-header/right-header.component';
import { LoginUiComponent } from './login/ui/login-ui.component';
import { EngagementOutletReportsComponent } from './reports/outlets/engagement-outlet-reports/engagement-outlet-reports.component';
import { AmbassadorsListComponent } from './ambassadors/list/ambassadors-list.component';
import { DateRangeNumberReportComponent } from './reports/range/date-range-number-report.component';
import { AmbassadorsFinalReportComponent } from './reports/final/ambassadors-final-report.component';
import {NgxEchartsModule} from "ngx-echarts";
import { Chart3DhighchartsComponent } from './charts/highcharts/chart3-dhighcharts/chart3-dhighcharts.component';
import { InventoriesComponent } from './inventories/inventories/inventories.component';
import { DialogInventoriesComponent } from './inventories/dialog-inventories/dialog-inventories.component';
import { TableInventoriesComponent } from './inventories/table-inventories/table-inventories.component';
import { SectionInventoriesComponent } from './inventories/section-inventories/section-inventories.component';
import { OutletsComponent } from './outlets/outlets.component';
import { SuppliesComponent } from './supplies/supplies.component';
import { ReceivingsComponent } from './receivings/receivings.component';
import { RoutesComponent } from './routes/routes.component';
import { OrdersComponent } from './orders/orders.component';
import { DraftRanksComponent } from './forms/controls/ranks/draft-ranks/draft-ranks.component';
import { RespondRanksComponent } from './forms/controls/ranks/respond-ranks/respond-ranks.component';
import { SettingsCriteriasDialogComponent } from './criterias/settings/settings-criterias-dialog.component';
import { QrcodeScanningInvitatationComponent } from './invitations/qrcode/scanning/qrcode-scanning-invitatation.component';
import { InviteeWelcomeScreenComponent } from './invitations/invitee-welcome-screen/invitee-welcome-screen.component';
import { ConfirmationInvititationsComponent } from './invitations/confirmation-invititations/confirmation-invititations.component';
import { InvitationCardComponent } from './invitations/invitation-card/invitation-card.component';
import { DialogInvitationCardComponent } from './invitations/card/dialog-invitation-card/dialog-invitation-card.component';
import {NgxQrcodeStylingModule} from "ngx-qrcode-styling";
import { CreateQrcodeControlsComponent } from './forms/controls/qrcode/create-qrcode-controls/create-qrcode-controls.component';
import { ViewQrcodeControlsComponent } from './forms/controls/qrcode/view-qrcode-controls/view-qrcode-controls.component';
import { ViewInvitationCardControlComponent } from './forms/controls/invitationcards/view-invitation-card-control/view-invitation-card-control.component';
import { ViewInvitationCardComponent } from './invitations/card/view-invitation-card/view-invitation-card.component';
import { TabsOutletsComponent } from './outlets/tabs-outlets/tabs-outlets.component';
import { TableOutletsComponent } from './outlets/table-outlets/table-outlets.component';
import { DialgProgressComponent } from './main/dialg-progress/dialg-progress.component';
import { DialogFrameOutletsComponent } from './outlets/dialog-frame-outlets/dialog-frame-outlets.component';
import { CreateOrderControlComponent } from './forms/controls/orders/create-order-control/create-order-control.component';
import { ViewOrderControlComponent } from './forms/controls/orders/view-order-control/view-order-control.component';
import { DialogItemComponent } from './items/dialog-item/dialog-item.component';
import { DialogMapSuppliesComponent } from './supplies/map/dialog-map-supplies/dialog-map-supplies.component';
import { BottomsheetOrderViewControlComponent } from './supplies/map/bottomsheet-order-view-control/bottomsheet-order-view-control.component';
import { DialogDisableCloseDisplayTextComponent } from './main/dialg/dialog-disable-close-display-text/dialog-disable-close-display-text.component';
import { ViewWinnerControlComponent } from './forms/controls/winners/view-winner-control/view-winner-control.component';
import { CreateWinnerControlComponent } from './forms/controls/winners/create-winner-control/create-winner-control.component';
import { DialogViewOutletsComponent } from './outlets/dialog/dialog-view-outlets/dialog-view-outlets.component';
import { ListItemsComponent } from './items/list-items/list-items.component';
import { SectionsItemsComponent } from './items/sections-items/sections-items.component';
import { TableItemsComponent } from './items/table-items/table-items.component';
import { OutletOrdersComponent } from './orders/outlet-orders/outlet-orders.component';
import { LivePhotoWallComponent } from './images/live-photo-wall/live-photo-wall.component';
import { DialogViewImageComponent } from './images/dialog/dialog-view-image/dialog-view-image.component';
import { DialogGenerateQrcodeComponent } from './invitations/dialog/dialog-generate-qrcode/dialog-generate-qrcode.component';
import { GetCntrlNumComponent } from './main/get-cntrl-num/get-cntrl-num.component';
import { DownloadQrcodeComponent } from './qrcode/download-qrcode/download-qrcode.component';
import { CreateSubmitControlComponent } from './forms/controls/submit/create-submit-control/create-submit-control.component';
import { ViewSubmitControlComponent } from './forms/controls/submit/view-submit-control/view-submit-control.component';
import { RegistraHeaderComponent } from './header/registra/registra-header.component';
import { HomeRegistraComponent } from './home/registra/home-registra.component';
import { NgxTreeviewComponent } from './ngx-treeview/ngx-treeview.component';
import { RegisterComponent } from './register/register.component';
import { ChannelPaymentsComponent } from './payments/channel-payments/channel-payments.component';
import { DialogRoutesComponent } from './routes/dialog/dialog-routes.component';
import { ChatbotResponsesComponent } from './responses/chatbot/chatbot-responses.component';
import { ChatbottResponsesComponent } from './responses/chatbott-responses/chatbott-responses.component';
import { CreateFileControlsComponent } from './forms/controls/file/create-file-controls/create-file-controls.component';
import { AnnoucementEventsComponent } from './events/annoucement-events/annoucement-events.component';
import { ExhibitorRegistrationEventsComponent } from './events/registrations/exhibitor-registration-events/exhibitor-registration-events.component';
import { RegisterEventsComponent } from './events/registrations/attendee-registration-events/register-events.component';
import { HomeServicesComponent } from './services/home-services/home-services.component';
import {CountdownModule} from "ngx-countdown";
import {CdTimerModule} from "angular-cd-timer";
import { FormListsComponent } from './forms/lists/form-lists/form-lists.component';
import { DialogMemberComponent } from './teams/members/dialog-member/dialog-member.component';
import { DialogResponseComponent } from './responses/dialog/dialog-response.component';
import {ListCriterialsComponent} from "./criterias/partials/list/list-criterials.component";
import {ListDialogCriteriasComponent} from "./criterias/dialog/list-dialog-criterias/list-dialog-criterias.component";
import {MapAmbassadorOrdersComponent} from "./ambassadors/partials/map/map-ambassador-orders.component";
import { AmbassadorComponent } from './ambassadors/ambassador.component';
import { UserComponent } from './users/user.component';
import {ShipmentOrderComponent} from "./orders/shipment/shipment-order.component";
import { OutletComponent } from './outlets/partials/outlet/outlet.component';
import { FormsListPartialComponent } from './forms/partials/list/forms-list-partial.component';
import { ResponsesListPartialComponent } from './responses/partials/list/responses-list-partial.component';
import { DashboardDistributorsComponent } from './distributors/dashboard/dashboard-distributors.component';
import { ListOrdersComponent } from './orders/list/list-orders.component';
import { ListUsersComponent } from './users/list/list-users.component';
import { RegisterAttendeeComponent } from './invites/register/register-attendee.component';
import {RegisterInviteeComponent} from "./invitations/register-invitee/register-invitee.component";
import { WheelRafflesComponent } from './raffles/wheel-raffles/wheel-raffles.component';
import { ListDistributorsComponent } from './distributors/list/list-distributors.component';
import { DistributorsComponent } from './distributors/distributors.component';
import { DetailsAccountComponent } from './events/registrations/accounts/details-account/details-account.component';
@NgModule({
    declarations: [
        FilterPipe,
        JsonFilterPipe,
        JsonPropertyFilterPipe,
        SortPipe,
        LimitToPipe,
        SafeUrlPipe,
        SlicePipe,
        AppComponent,
        HomeComponent,
        HeaderComponent,
        MainComponent,
        AboutComponent,
        LoginComponent,
        PageComponent,
        ToolboxComponent,
        SearchComponent,
        SidebarHeaderComponent,
        DeleteDialogComponent,
        DashboardComponent,
        OverallReportComponent,
        SitemapComponent,
        FilterDashboardComponent,
        RegionalsComponent,
        DistrictsComponent,
        TableRegionalComponent,
        TabsRegionalComponent,
        DialogRegionalComponent,
        DialogDistrictalComponent,
        DashboardFormComponent,
        TableDistrictalComponent,
        TabsDistrictalComponent,
        DateDialogComponent,
        DashbaordRegionalComponent,
        OverallComponent,
        MorrisBarchartComponent,
        PiechartComponent,
        DialogOverallComponent,
        SparklineComponent,
        TabsClientsComponent,
        DialogClientsComponent,
        TableClientsComponent,
        ClientsComponent,
        FormsComponent,
        DialogFormsComponent,
        TabsFormsComponent,
        TableFormsComponent,
        TextControlsComponent,
        ChoiceControlsComponent,
        NumberControlsComponent,
        ImageControlsComponent,
        VideoControlsComponent,
        DateControlsComponent,
        SideControlsComponent,
        BottomButtonControlsComponent,
        CampaignsComponent,
        CampaniesComponent,
        TableCampaignsComponent,
        TabsCampaignsComponent,
        DialogCampaniesComponent,
        DialogCampaignsComponent,
        AmbassadorsComponent,
        TableCampaniesComponent,
        TabsCampaniesComponent,
        DialogAmbassadorsComponent,
        TableAmbassadorsComponent,
        TabsAmbassadorsComponent,
        NavTeamsComponent,
        DialogTeamsComponent,
        TableTeamsComponent,
        TeamsComponent,
        DialogEmployeesComponent,
        TableEmployeesComponent,
        TabsEmployeesComponent,
        EmployeesComponent,
        DialogProfileComponent,
        RegisterInviteeComponent,
        PublicFormsComponent,
        DescriptionControlsComponent,
        DescriptionPreviewControlsComponent,
        TextPreviewControlsComponent,
        NumberPreviewControlsComponent,
        ChoicePreviewControlsComponent,
        CheckPreviewControlsComponent,
        ResponsesFormComponent,
        DialogResponsesFormComponent,
        TableResponsesFormComponent,
        TabsResponsesFormComponent,
        NavigationsFormComponent,
        DialogPasswordUserComponent,
        SuccessfullSuggestionsFormsComponent,
        HomeInternalSuggestionsComponent,
        SuggestionsComponent,
        DialogLockQuestionnairesComponent,
        EmailInvitationsComponent,
        DialogEmailInvitationsComponent,
        DialogReplyComponent,
        RateComponent,
        DepartmentsComponent,
        DialogDepartmetsComponent,
        TabsDepartmetsComponent,
        TableDepartmetsComponent,
        DialogInviteFormsComponent,
        OutletControlsComponent,
        RatePreviewControlsComponent,
        SheetControlsComponent,
        TextareaControlsComponent,
        TextareaPreviewControlsComponent,
        OutletPreviewControlsComponent,
        SheetPreviewControlsComponent,
        ImagePreviewControlsComponent,
        VideoPreviewControlsComponent,
        SelectPreviewControlsComponent,
        DatePreviewControlsComponent,
        AddressPreviewControlsComponent,
        SearchFormsComponent,
        ReportQuestionComponent,
        FormsAmbassadorComponent,
        PreviewFormComponent,
        TreeviewFormComponent,
        TeamsFormComponent,
        SettingsFormComponent,
        ManageTeamMembersComponent,
        EchartComponent,
        UsersComponent,
        ProfileComponent,
        SearchCampaignsComponent,
        ListTeamsComponent,
        BarChartjsComponent,
        LineChartjsComponent,
        MorrisLinechartComponent,
        EchartMinipieComponent,
        EchartPyramidComponent,
        EchartSonarComponent,
        LabelComponent,
        GroupsPreviewControlsComponent,
        DonutPiechatChatjsComponent,
        DonutPiechartComponent,
        PiechartChartjsComponent,
        OpenCampaignsComponent,
        MenusComponent,
        ImageOptionalComponent,
        RespondFormComponent,
        VideoChoiceCreateComponent,
        BrowseImagesComponent,
        DraftEmailsComponent,
        RespondEmailControlsComponent,
        CreateFormComponent,
        DraftAddressControlsComponent,
        ViewFormComponent,
        DraftTimeControlsComponent,
        RespondTimeControlsComponent,
        CoordinateDraftControlsComponent,
        CoordinateRespondControlsComponent,
        CampaignViewComponent,
        FooterComponent,
        CreateCampaignComponent,
        SelectionCampaignComponent,
        ListCampaignComponent,
        DraftGroupControlsComponent,
        AddControlsComponent,
        DraftTableControlsComponent,
        RespondTableControlsComponent,
        DraftInputlistsControlsComponent,
        RespondInputlistsControlsComponent,
        SubmitControlsComponent,
        ImageChoiceDraftControlsComponent,
        ImageChoiceRespondControlComponent,
        BuildFormComponent,
        UntitledFormComponent,
        DescriptionFormComponent,
        AddQuestionComponent,
        TextCreateControlComponent,
        LabelCreateControlComponent,
        CreateChoiceControlComponent,
        PdfComponent,
        ImagesFormComponent,
        MapComponent,
        VideosFormComponent,
        FilesFormComponent,
        AmbassadorResponsesComponent,
        MessageDisplayComponent,
        DialogUserComponent,
        ProgressDialogComponent,
        DraftCurrencyControlComponent,
        RespondCurrencyControlComponent,
        Dashboard2FormComponent,
        Dashboard3FormComponent,
        DashboardClientsComponent,
        Dashboard4FormComponent,
        SettingsQuestionsComponent,
        OtherOptionQuestionsComponent,
        UserActivityChartjsComponent,
        HorizontalBarChartjsComponent,
        BrunchTeamsComponent,
        DialogBrunchTeamsComponent,
        NavBrunchTeamsComponent,
        TableBrunchTeamsComponent,
        ManageTeamBrunchMembersComponent,
        HighchartPiechartComponent,
        Highchart3DBarchartComponent,
        RadarChartjsComponent,
        MixedchartHighchartComponent,
        RespondAutocompleteControlsComponent,
        ChipDraftControlsComponent,
        ChipFeedbackControlsComponent,
        HeaderJobinnv120Component,
        RoutesInviteeComponent,
        ListInvitesComponent,
        TableInvitesComponent,
        TabInvitesComponent,
        DashboardInvitesComponent,
        MitipleUserActivityComponent,
        GoogleChartsComponent,
        OptionSettingsComponent,
        WaypointsDraftComponent,
        WaypointsResponsesComponent,
        AdvertsDraftComponent,
        AdvertResponsesComponent,
        NextButtonComponent,
        PromptComponent,
        RightHeaderComponent,
        LoginUiComponent,
        EngagementOutletReportsComponent,
        AmbassadorsListComponent,
        DateRangeNumberReportComponent,
        AmbassadorsFinalReportComponent,
        Chart3DhighchartsComponent,
        InventoriesComponent,
        DialogInventoriesComponent,
        TableInventoriesComponent,
        SectionInventoriesComponent,
        OutletsComponent,
        SuppliesComponent,
        ReceivingsComponent,
        RoutesComponent,
        OrdersComponent,
        DraftRanksComponent,
        RespondRanksComponent,
        SettingsCriteriasDialogComponent,
        QrcodeScanningInvitatationComponent,
        InviteeWelcomeScreenComponent,
        ConfirmationInvititationsComponent,
        InvitationCardComponent,
        DialogInvitationCardComponent,
        CreateQrcodeControlsComponent,
        ViewQrcodeControlsComponent,
        ViewInvitationCardControlComponent,
        ViewInvitationCardComponent,
        TabsOutletsComponent,
        TableOutletsComponent,
        DialgProgressComponent,
        DialogFrameOutletsComponent,
        CreateOrderControlComponent,
        ViewOrderControlComponent,
        DialogItemComponent,
        DialogMapSuppliesComponent,
        BottomsheetOrderViewControlComponent,
        DialogDisableCloseDisplayTextComponent,
        ViewWinnerControlComponent,
        CreateWinnerControlComponent,
        DialogViewOutletsComponent,
        ListItemsComponent,
        SectionsItemsComponent,
        TableItemsComponent,
        OutletOrdersComponent,
        LivePhotoWallComponent,
        DialogViewImageComponent,
        RegisterAttendeeComponent,
        DialogGenerateQrcodeComponent,
        GetCntrlNumComponent,
        DownloadQrcodeComponent,
        CreateSubmitControlComponent,
        ViewSubmitControlComponent,
        RegistraHeaderComponent,
        HomeRegistraComponent,
        NgxTreeviewComponent,
        RegisterComponent,
        ChannelPaymentsComponent,
        DialogRoutesComponent,
        ChatbotResponsesComponent,
        ChatbottResponsesComponent,
        CreateFileControlsComponent,
        AnnoucementEventsComponent,
        ExhibitorRegistrationEventsComponent,
        RegisterEventsComponent,
        HomeServicesComponent,
        FormListsComponent,
        DialogMemberComponent,
        DialogResponseComponent,
        ListCriterialsComponent,
        ListDialogCriteriasComponent,
        MapAmbassadorOrdersComponent,
        AmbassadorComponent,
        UserComponent,
        ShipmentOrderComponent,
        OutletComponent,
        FormsListPartialComponent,
        ResponsesListPartialComponent,
        DashboardDistributorsComponent,
        ListOrdersComponent,
        ListUsersComponent,
        RegisterAttendeeComponent,
        WheelRafflesComponent,
        ListDistributorsComponent,
        DistributorsComponent,
        DetailsAccountComponent
    ],
    imports: [
        BrowserModule,
        AgmOverlays,
        AppRoutingModule,
        HttpClientModule,
        LoadingBarHttpClientModule,
        FormsModule,
        BrowserAnimationsModule,
        MatStepperModule,
        MatTabsModule,
        CountdownModule,
        CdTimerModule,
        ZXingScannerModule,
        NgxWheelModule,
        NgxQrcodeStylingModule,
        MatIconModule,
        ThermalPrintModule,
        WebcamModule,
        MatBadgeModule,
        MatGridListModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatExpansionModule,
        CountUpModule,
        ReactiveFormsModule,
        AngularEditorModule,
        SocialLoginModule,
        TreeviewModule.forRoot(),
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        }),
        GooglePlaceModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBGfZCD14Q31iB9wryGIPuyWK1Ai_kX09w',
            apiVersion: 'weekly',
            channel: 'weekly',
            libraries: ['places', 'drawing', 'geometry']
        }),
        AgmDirectionModule,
        MatGoogleMapsAutocompleteModule,
        MatSidenavModule,
        NgxCaptchaModule,
        MatBottomSheetModule,
        MatSelectModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        GoogleChartsModule,
        IvyCarouselModule,
        MorrisJsModule,
        MatSlideToggleModule,
        HighchartsChartModule,
        MatDialogModule,
        MatMenuModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSnackBarModule,
        MatProgressBarModule,
        MatListModule,
        MatSliderModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatTableModule,
        MatChipsModule,
        MatRadioModule,
        NotifierModule.withConfig({
            position: {
                horizontal: {position: 'right', distance: 12},
                vertical: {position: 'top', distance: 12, gap: 10}
            },
            theme: 'material',
            behaviour: {
                autoHide: 40000,
                onClick: 'hide',
                onMouseover: 'pauseAutoHide',
                showDismissButton: true,
                stacking: 4,
            },
            animations: {
                enabled: true,
                show: {preset: 'slide', speed: 300, easing: 'ease'},
                hide: {preset: 'fade', speed: 300, easing: 'ease', offset: 50},
                shift: {speed: 300, easing: 'ease'},
                overlap: 150
            },
        }),
        ExtendedModule,
        CarouselModule,
        MatProgressSpinnerModule,
        MatTreeModule,
        MatCardModule,
        AgmDirectionModule,

    ],
    providers: [
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    // {
                    //     id: GoogleLoginProvider.PROVIDER_ID,
                    //     provider: new GoogleLoginProvider('715116863217-h27ef0f9n1ciuafq33vt4trqt1g7li8s.apps.googleusercontent.com')
                    // },
                    // {
                    //     id: FacebookLoginProvider.PROVIDER_ID,
                    //     provider: new FacebookLoginProvider('2641027689463563')
                    // }
                ]
            } as SocialAuthServiceConfig
        }
    ],
    schemas: [ NO_ERRORS_SCHEMA ],
    bootstrap: [AppComponent]
})
export class AppModule { }
