<mat-drawer-container
        class="example-container"
        [hasBackdrop]="false">
    <mat-drawer #drawer
                [position]="'start'"
                [opened]="true"
                [mode]="'side'">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <mat-icon (click)="drawer.toggle()"
                      class="pull-right">close</mat-icon>
            <div [ngStyle]="{'margin-top':'100px'}">
                <app-treeview-form [dataSource]="dataSource"
                                   [treeControl]="treeControl"
                                   [questions]="incomingForm.getControls()"></app-treeview-form>
            </div>
        </div>
    </mat-drawer>
    <mat-drawer-content>
        <table [ngStyle]="{'position':'absolute','top':'5xp','left':'5px','background-color':'transparent','width':'100%','z-index': '999999999'}">
            <tr>
                <td valign="top"
                    [ngStyle]="{'width':'100%'}">
                    <a class="pull-left cursor" *ngIf="!drawer.opened"><img src="../../assets/images/option-icon.png" (click)="drawer.toggle()"></a>
                </td>
                <td valign="top" >
                    <div [ngStyle]="{'margin-right':'10px','border-radius': '25px','background':'#fff'}" class="mdl-shadow--2dp">
                        <table [ngStyle]="{'min-width':'350px','margin':'5px'}">
                            <td [ngStyle]="{'width':'100%'}">
                                <input  [ngStyle]="{'scaleX':'1.37','color':'#000','text-align':'center','border-width':'0px','width':'100%'}"
                                        autocomplete="off"
                                        ngClass="sawasdee18 searchbar"
                                        #addressId
                                        placeholder=""
                                        (onAutocompleteSelected)="onGoogleMapAutocompleteSelected($event)"
                                        (onLocationSelected)="onLocationSelected($event)"
                                        matGoogleMapsAutocomplete>
                            </td>
                            <td>
                                <mat-icon matSuffix >search</mat-icon>
                            </td>
                        </table>
                    </div>
                </td>
            </tr>
        </table>
        <div>
            <div *ngFor="let time of durations">
                <div *ngIf="navTime === time.id && service.markers.overpassapi.railway.data.features.length !=0">
                    <agm-map
                        #agm
                        [latitude]="lat"
                        [longitude]="lng"
                        [fitBounds]="false"
                        [zoom]="zoom"
                        (mapClick)="onMapClicked()"
                        [disableDefaultUI]="true"
                        [styles]="app.settings.map.styles.style1"
                        [ngStyle]="{'height': this.service.getHeight() + 'px', 'width':this.service.getWidth() + 'px'}">
                        <agm-map-type-control position="LEFT_BOTTOM"></agm-map-type-control>
                        <agm-zoom-control position="RIGHT_BOTTOM"></agm-zoom-control>
                        <!--                        <agm-direction-->
                        <!--                                    *ngFor="let waypoint of service.markers.overpassapi.railway.data.waypoints; let waypointIncremental = index"-->
                        <!--                                    [visible]="true"-->
                        <!--                                    [renderOptions] = "{suppressMarkers: true, polylineOptions: {strokeColor : '#ff8000'}}"-->
                        <!--                                    [provideRouteAlternatives]="false"-->
                        <!--                                    [origin]="waypoint.location"-->
                        <!--                                    [destination]="waypointIncremental === 0 ? waypoint.location : service.markers.overpassapi.railway.data.waypoints[waypointIncremental - 1].location"-->
                        <!--                            >-->
                        <!--                        </agm-direction>-->
                        <agm-circle [latitude]="this.service.parseFloat(agmCircle.getLat())"
                                    [longitude]="this.service.parseFloat(agmCircle.getLng())"
                                    [radius]="5000"
                                    [fillColor]="'red'"
                                    [circleDraggable]="true"
                                    [editable]="true"
                                    (dragEnd)="agmCircleChangeRadius($event)"
                                    (dragStart)="agmCircleChangeRadius($event)"
                                    (radiusChange)="agmCircleChangeRadius($event)"
                                    (mouseOver)="agmCircleChangeRadius($event)"
                                    *ngIf="agmCircle.getAttributes().hasOwnProperty(strings.getLat())">
                        </agm-circle>
                        <agm-marker
                                [latitude]="latitude"
                                [longitude]="longitude"
                                [agmFitBounds]="true"
                                [openInfoWindow]="true"
                                [iconUrl]="agmIcon">
                        </agm-marker>
                        <agm-marker
                                *ngFor="let marker of (incomingReport.attributes.data.markers[time.tm].markers.length === 0 ? [] :  incomingReport.attributes.data.markers[time.tm].markers)"
                                [latitude]="marker.lat"
                                [longitude]="marker.lng"
                                [agmFitBounds]="true"
                                [openInfoWindow]="true"
                                (markerClick)="onClickMarker($event, marker)"
                                [iconUrl]="marker.items.length === 0 ? icon : ({url: marker.items[0].avatar, scaledSize: {height: 100, width: 100}})">
                            <agm-info-window
                                    (infoWindowClose)="onInfoWindowClose(agm,infoWindow)"
                                    #infoWindow>
                                <div>
                                    {{marker.postedBy.length === 0 ? marker.date : marker.postedBy.name}}
                                    <mat-divider></mat-divider>
                                    <div>
                                        <!-- <carousel [cellsToShow]=1 [height]="200" [width]="200" [cellWidth]="100">-->
                                        <!--   <div class="carousel-cell">-->
                                        <!--      <img style='width: 200px;max-height: 300px' src="https://image.scoopwhoop.com/w360/s3.scoopwhoop.com/anj/dead_poets_society_quotes/285731274.jpg.webp">-->
                                        <!--   </div>-->
                                        <!--   <div class="carousel-cell">-->
                                        <!--       <img src="https://image.scoopwhoop.com/w360/s3.scoopwhoop.com/anj/dead_poets_society_quotes/285731274.jpg.webp">-->
                                        <!--   </div>-->
                                        <!-- </carousel>-->
                                    </div>
                                    <div> <mat-spinner class="pull-left" *ngIf="spinner" [diameter]="20"></mat-spinner></div>
                                    <div *ngIf="marker.hasOwnProperty('response')">
                                        <div><br><br>
                                            <ng-container *ngIf="marker.items.length === 0;else items">
                                                <table>
                                                    <tbody>
                                                    <tr *ngFor="let response of  marker.response[0].datas">
                                                        <td style="border-bottom-style: dotted;border-bottom: 1px solid #5f6368;padding-right: 20px;" [innerHTML]="response.formControl.label">
                                                            {{tableService.setQuestion(response.formControl)}}
                                                        </td>
                                                        <td style="border-bottom-style: dotted;border-left-style: dotted;border-bottom: 1px solid #5f6368;padding-left: 20px;border-left: 1px solid #5f6368;">
                                                            <div *ngIf="response.formControl.control.id !== 24 &&
                                                                        response.formControl.control.id !== 2 &&
                                                                        response.formControl.control.id !== 7">
                                                                <span [innerHTML]="response.data"></span>
                                                            </div>
                                                            <div *ngIf="response.formControl.control.id === 24">
                                                                <table  class="table table-striped table-bordered">
                                                                    <thead *ngIf="tableService.isheaderShouldDislayed()" style="width: 100%">
                                                                    <th *ngFor="let optional of response.formControl.options" style="font-size: 17px; margin: 2px; min-width: 200px">
                                                                        <span class='cursor headline2' [innerHTML]="optional.label"></span>
                                                                    </th>
                                                                    </thead>
                                                                    <tbody>
                                                                    <tr *ngFor="let row of response.rows" style="height: 50px !important; width: 100%; min-width: 200px">
                                                                        <td *ngFor="let option of response.formControl.options; let optionIndex = index" style="height: 100%; width: 100%">
                                                                            <div #tdElement style="height: 100%; width: 100%;min-width: 200px"
                                                                                 *ngIf="option.type_id !== 3"
                                                                                 [innerHTML]="row.hasOwnProperty(option.cntrl_num) ?
                                                                                         row[option.cntrl_num].data : ''">
                                                                            </div>
                                                                            <div style="height: 100%; width: 100% ; min-width: 200px"
                                                                                 *ngIf="option.type_id === 3">
                                                                                <center>
                                                                                    <mat-checkbox
                                                                                        #checkboxElement
                                                                                        [checked]="tableService.isChecked(checkboxElement, row, option)"
                                                                                        class="cursor"
                                                                                    >
                                                                                    </mat-checkbox>
                                                                                </center>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="{{marker.response[0].datas.length * 2}}">
                                                            <center>
                                                                <div>
                                                                    <ul class='mdl-list'>
                                                                        <li class="mdl-list__item" *ngFor="let user of marker[strings.getDeliveryUsers()].data">
                                                                            <span class="mdl-list__item-primary-content">{{user.name}}</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </center>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </ng-container>
                                            <ng-template #items>
                                                <div [innerHTML]="marker.response[0].reference3 | safeUrl"></div>
                                                <div>
                                                    <center>
                                                        <center>
                                                            <section class="example-section">
                                                                <mat-checkbox
                                                                        #checkboxId
                                                                        ngClass="example-margin"
                                                                        [checked]="isDeliveryUser(marker)"
                                                                        (change)="addDeliveryUser(marker, checkboxId)"
                                                                        [disabled]="this.service.disabled"
                                                                        [indeterminate]="false"
                                                                        [labelPosition]="'before'">
                                                                    <span [ngStyle]="{'font-size':'14px','font-family':'Sawasdee','-webkit-transform': 'scale(1.15,0.77)','color':'rgb(90, 115, 142)','padding-right':'20px'}">hand it over to John Doe to deliver this product</span>
                                                                </mat-checkbox>
                                                            </section>
                                                        </center>
                                                    </center>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </agm-info-window>
                        </agm-marker>
                        <agm-marker
                                *ngFor="let marker of service.markers.overpassapi.railway.data.features"
                                [latitude]="service.parseFloat(marker.geometry.coordinates[1])"
                                [longitude]="service.parseFloat(marker.geometry.coordinates[0])"
                                [agmFitBounds]="true"
                                [openInfoWindow]="true"
                                (markerClick)="onClickMarker($event, marker)"
                                [iconUrl]="railwayIcon">
                        </agm-marker>
                    </agm-map>
                </div>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
<!--<div class="theme-style-1">-->
<!--    <div id="wrapper">-->
<!--        <header id="header" class="header-2">-->
<!--            &lt;!&ndash;BURGER NAVIGATION SECTION START&ndash;&gt;-->
<!--            <div class="cp-burger-nav">-->
<!--                &lt;!&ndash;BURGER NAV BUTTON&ndash;&gt;-->
<!--                <div id="cp_side-menu-btn" class="cp_side-menu cursor" (click)="onMenuClicked()">-->
<!--                    <a hrefx="" class=""><img src="assets/images/option-icon.png" alt="img">-->
<!--                        <span style="margin-left: 10px" class="headline">-->
<!--&lt;!&ndash;                        {{service.header}}&ndash;&gt;-->
<!--                        </span>-->
<!--                    </a>-->
<!--                    &lt;!&ndash;<a hrefx="" class=""> <img src="../../assets/images/logo-3.png" alt="img" [ngStyle]="{'margin-left':'20px','width':'40px','height':'40px'}"></a>&ndash;&gt;-->
<!--                </div>-->
<!--                &lt;!&ndash;BURGER NAV BUTTON&ndash;&gt;-->
<!--                &lt;!&ndash;SIDEBAR MENU START&ndash;&gt;-->
<!--                <div id="cp_side-menu"> <a hrefx="#" id="cp-close-btn" class="crose"><i class="fa fa-close"></i></a>-->
<!--                    &lt;!&ndash;<div class="cp-top-bar">&ndash;&gt;-->
<!--                    &lt;!&ndash;<h4>For any Queries: +800 123 4567</h4>&ndash;&gt;-->
<!--                    &lt;!&ndash;<div class="login-section"> <a hrefx="login.html" class="btn-login">Log in</a> <a hrefx="signup.html" class="btn-login">Signup</a> </div>&ndash;&gt;-->
<!--                    &lt;!&ndash;</div>&ndash;&gt;-->
<!--                    &lt;!&ndash;<strong class="logo-2"><a hrefx="index.html"><img src="../../assets/images/sidebar-logo.png" alt="img"></a></strong>&ndash;&gt;-->
<!--                    <div class="content mCustomScrollbar">-->
<!--                        <div id="content-1" class="content">-->
<!--                            <div class="cp_side-navigation">-->
<!--                                <nav>-->
<!--                                    &lt;!&ndash;<ul class="navbar-nav">&ndash;&gt;-->
<!--                                    &lt;!&ndash;<li class="active"><a hrefx="index.html" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">Home<span class="caret"></span></a>&ndash;&gt;-->
<!--                                    &lt;!&ndash;<ul class="dropdown-menu" role="menu">&ndash;&gt;-->
<!--                                    &lt;!&ndash;<li><a hrefx="index-1.html">Home 1</a></li>&ndash;&gt;-->
<!--                                    &lt;!&ndash;<li><a hrefx="index-2.html">Home 2</a></li>&ndash;&gt;-->
<!--                                    &lt;!&ndash;<li><a hrefx="index-3.html">Home 3</a></li>&ndash;&gt;-->
<!--                                    &lt;!&ndash;<li><a hrefx="index-4.html">Home 4</a></li>&ndash;&gt;-->
<!--                                    &lt;!&ndash;</ul>&ndash;&gt;-->
<!--                                    &lt;!&ndash;</li>&ndash;&gt;-->
<!--                                    &lt;!&ndash;</ul>&ndash;&gt;-->
<!--                                </nav>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="cp-sidebar-social">-->
<!--                        <ul>-->
<!--                            &lt;!&ndash;<li><a hrefx="#"><i class="fa fa-google-plus"></i></a></li>&ndash;&gt;-->
<!--                            &lt;!&ndash;<li><a hrefx="#"><i class="fa fa-twitter"></i></a></li>&ndash;&gt;-->
<!--                            &lt;!&ndash;<li><a hrefx="#"><i class="fa fa-linkedin"></i></a></li>&ndash;&gt;-->
<!--                            &lt;!&ndash;<li><a hrefx="#"><i class="fa fa-facebook-f"></i></a></li>&ndash;&gt;-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                    <strong class="copy">mashindano &copy; 2020, All Rights Reserved</strong> </div>-->
<!--                &lt;!&ndash;SIDEBAR MENU END&ndash;&gt;-->

<!--            </div>-->
<!--            &lt;!&ndash;BURGER NAVIGATION SECTION END&ndash;&gt;-->
<!--            <div class="container">-->
<!--                &lt;!&ndash;NAVIGATION START&ndash;&gt;-->
<!--                <div class="navigation-col">-->
<!--                    <nav class="navbar navbar-inverse">-->
<!--                        <div class="navbar-header">-->
<!--                            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar"> <span class="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span> </button>-->
<!--                        </div>-->
<!--                        &lt;!&ndash;                <div id="navbar" class="collapse navbar-collapse">&ndash;&gt;-->
<!--                        &lt;!&ndash;                    <ul class="nav navbar-nav" id="nav">&ndash;&gt;-->
<!--                        &lt;!&ndash;                        <li><a href="">;cxbkfddfkgpdfg</a>&ndash;&gt;-->
<!--                        &lt;!&ndash;                            <ul>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                <li><a hrefx="index.html">Home 1</a></li>&ndash;&gt;-->
<!--                        &lt;!&ndash;                            </ul>&ndash;&gt;-->
<!--                        &lt;!&ndash;                        </li>&ndash;&gt;-->
<!--                        &lt;!&ndash;                        &lt;!&ndash;    <li *ngFor="let module of app.settings.modules"><a href="{{module.path}}">{{module.title}}</a>&ndash;&gt;&ndash;&gt;-->
<!--                        &lt;!&ndash;                        &lt;!&ndash;                            &lt;!&ndash;<ul>&ndash;&gt;&ndash;&gt;&ndash;&gt;-->
<!--                        &lt;!&ndash;                        &lt;!&ndash;                            &lt;!&ndash;<li><a hrefx="index.html">Home 1</a></li>&ndash;&gt;&ndash;&gt;&ndash;&gt;-->
<!--                        &lt;!&ndash;                        &lt;!&ndash;                            &lt;!&ndash;<li><a hrefx="index-2.html">Home 2</a></li>&ndash;&gt;&ndash;&gt;&ndash;&gt;-->
<!--                        &lt;!&ndash;                        &lt;!&ndash;                            &lt;!&ndash;<li><a hrefx="index-3.html">Home 3</a></li>&ndash;&gt;&ndash;&gt;&ndash;&gt;-->
<!--                        &lt;!&ndash;                        &lt;!&ndash;                            &lt;!&ndash;<li><a hrefx="index-4.html">Home 4</a></li>&ndash;&gt;&ndash;&gt;&ndash;&gt;-->
<!--                        &lt;!&ndash;                        &lt;!&ndash;                            &lt;!&ndash;</ul>&ndash;&gt;&ndash;&gt;&ndash;&gt;-->
<!--                        &lt;!&ndash;                        &lt;!&ndash;                     </li>&ndash;&gt;&ndash;&gt;-->

<!--                        &lt;!&ndash;                    </ul>&ndash;&gt;-->
<!--                        &lt;!&ndash;                </div>&ndash;&gt;-->
<!--                    </nav>-->
<!--                </div>-->
<!--                &lt;!&ndash;NAVIGATION END&ndash;&gt;-->
<!--            </div>-->

<!--            &lt;!&ndash;USER OPTION COLUMN START&ndash;&gt;-->
<!--            <div class="user-option-col">-->
<!--                <div class="thumb">-->
<!--                    <div class="dropdown">-->
<!--                        <button  class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">-->
<!--                            <img class='img-circle' src="assets/images/avatar.jpg" alt="img">-->
<!--                        </button>-->
<!--                        <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">-->
<!--                            <li><a hrefx="#">Manage Account</a></li>-->
<!--                            <li><a hrefx="#">Change Password</a></li>-->
<!--                            <li><a hrefx="#">Edit Profile</a></li>-->
<!--                            <li><a hrefx="#">Log off</a></li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="dropdown-box">-->
<!--                    <div class="dropdown">-->
<!--                        <button-->
<!--                                class="btn btn-default dropdown-toggle"-->
<!--                                type="button" id="dropdownMenu2"-->
<!--                                data-toggle="dropdown"-->
<!--                                aria-haspopup="true"-->
<!--                                aria-expanded="true"-->
<!--                                (click)="openDateMenu()">-->
<!--                            <img src="../../assets/images/option-icon-2.png" alt="img">-->
<!--                        </button>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;USER OPTION COLUMN END&ndash;&gt;-->

<!--        </header>-->
<!--        <div>-->
<!--            <div id="main">-->
<!--                <div class="banner-outer banner-2">-->
<!--                    <div class="banner-2">-->
<!--                        <div [ngStyle] = "{'height':'100%'}">-->
<!--                            <div *ngFor="let time of durations">-->
<!--                                <div *ngIf="navTime === time.id && service.markers.overpassapi.railway.data.features.length !=0">-->
<!--                                    <agm-map-->
<!--                                        #gm-->
<!--                                        [latitude]="lat"-->
<!--                                        [longitude]="lng"-->
<!--                                        [fitBounds]="false"-->
<!--                                        [zoom]="zoom"-->
<!--                                        (mapClick)="onMapClicked()"-->
<!--                                        [disableDefaultUI]="true"-->
<!--                                        [styles]="app.settings.map.styles.style1"-->
<!--                                        style="height : {{getHeight().height + 'px'}}">-->
<!--                                        <agm-map-type-control position="LEFT_BOTTOM"></agm-map-type-control>-->
<!--                                        <agm-zoom-control position="RIGHT_BOTTOM"></agm-zoom-control>-->
<!--                                        &lt;!&ndash;                        <agm-direction&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                    *ngFor="let waypoint of service.markers.overpassapi.railway.data.waypoints; let waypointIncremental = index"&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                    [visible]="true"&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                    [renderOptions] = "{suppressMarkers: true, polylineOptions: {strokeColor : '#ff8000'}}"&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                    [provideRouteAlternatives]="false"&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                    [origin]="waypoint.location"&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                    [destination]="waypointIncremental === 0 ? waypoint.location : service.markers.overpassapi.railway.data.waypoints[waypointIncremental - 1].location"&ndash;&gt;-->
<!--                                        &lt;!&ndash;                            >&ndash;&gt;-->
<!--                                        &lt;!&ndash;                        </agm-direction>&ndash;&gt;-->
<!--                                        <agm-circle [latitude]="this.service.parseFloat(agmCircle.getLat())"-->
<!--                                                    [longitude]="this.service.parseFloat(agmCircle.getLng())"-->
<!--                                                    [radius]="5000"-->
<!--                                                    [fillColor]="'red'"-->
<!--                                                    *ngIf="agmCircle.getAttributes().hasOwnProperty(strings.getLat())">-->
<!--                                        </agm-circle>-->
<!--                                        <agm-marker-->
<!--                                                [latitude]="latitude"-->
<!--                                                [longitude]="longitude"-->
<!--                                                [agmFitBounds]="true"-->
<!--                                                [openInfoWindow]="true"-->
<!--                                                [iconUrl]="agmIcon">-->
<!--                                        </agm-marker>-->
<!--                                        <agm-marker-->
<!--                                                *ngFor="let marker of (incomingReport.attributes.data.markers[time.tm].markers.length === 0 ? [] :  incomingReport.attributes.data.markers[time.tm].markers)"-->
<!--                                                [latitude]="marker.lat"-->
<!--                                                [longitude]="marker.lng"-->
<!--                                                [agmFitBounds]="true"-->
<!--                                                [openInfoWindow]="true"-->
<!--                                                (markerClick)="onClickMarker($event, marker)"-->
<!--                                                [iconUrl]="marker.items.length === 0 ? icon : ({url: marker.items[0].avatar, scaledSize: {height: 100, width: 100}})">-->
<!--                                            <agm-info-window-->
<!--                                                    (infoWindowClose)="onInfoWindowClose(gm,infoWindow)"-->
<!--                                                    #infoWindow>-->
<!--                                                <div>-->
<!--                                                    {{marker.postedBy.length === 0 ? marker.date : marker.postedBy.name}}-->
<!--                                                    <mat-divider></mat-divider>-->
<!--                                                    <div>-->
<!--                                                        &lt;!&ndash; <carousel [cellsToShow]=1 [height]="200" [width]="200" [cellWidth]="100">&ndash;&gt;-->
<!--                                                        &lt;!&ndash;   <div class="carousel-cell">&ndash;&gt;-->
<!--                                                        &lt;!&ndash;      <img style='width: 200px;max-height: 300px' src="https://image.scoopwhoop.com/w360/s3.scoopwhoop.com/anj/dead_poets_society_quotes/285731274.jpg.webp">&ndash;&gt;-->
<!--                                                        &lt;!&ndash;   </div>&ndash;&gt;-->
<!--                                                        &lt;!&ndash;   <div class="carousel-cell">&ndash;&gt;-->
<!--                                                        &lt;!&ndash;       <img src="https://image.scoopwhoop.com/w360/s3.scoopwhoop.com/anj/dead_poets_society_quotes/285731274.jpg.webp">&ndash;&gt;-->
<!--                                                        &lt;!&ndash;   </div>&ndash;&gt;-->
<!--                                                        &lt;!&ndash; </carousel>&ndash;&gt;-->
<!--                                                    </div>-->
<!--                                                    <div> <mat-spinner class="pull-left" *ngIf="spinner" [diameter]="20"></mat-spinner></div>-->
<!--                                                    <div *ngIf="marker.hasOwnProperty('response')">-->
<!--                                                        <div><br><br>-->
<!--                                                            <table>-->
<!--                                                                <tbody>-->
<!--                                                                <tr *ngFor="let response of  marker.response[0].datas">-->
<!--                                                                    <td style="border-bottom-style: dotted;border-bottom: 1px solid #5f6368;padding-right: 20px;" [innerHTML]="response.formControl.label">-->
<!--                                                                        {{tableService.setQuestion(response.formControl)}}-->
<!--                                                                    </td>-->
<!--                                                                    <td style="border-bottom-style: dotted;border-left-style: dotted;border-bottom: 1px solid #5f6368;padding-left: 20px;border-left: 1px solid #5f6368;">-->
<!--                                                                        <div *ngIf="response.formControl.control.id !== 24 &&-->
<!--                                                                            response.formControl.control.id !== 2 &&-->
<!--                                                                            response.formControl.control.id !== 7">-->
<!--                                                                            <span [innerHTML]="response.data"></span>-->
<!--                                                                        </div>-->
<!--                                                                        <div *ngIf="response.formControl.control.id === 24">-->
<!--                                                                            <table  class="table table-striped table-bordered">-->
<!--                                                                                <thead *ngIf="tableService.isheaderShouldDislayed()" style="width: 100%">-->
<!--                                                                                <th *ngFor="let optional of response.formControl.options" style="font-size: 17px; margin: 2px; min-width: 200px">-->
<!--                                                                                    <span class='cursor headline2' [innerHTML]="optional.label"></span>-->
<!--                                                                                </th>-->
<!--                                                                                </thead>-->
<!--                                                                                <tbody>-->
<!--                                                                                <tr *ngFor="let row of response.rows" style="height: 50px !important; width: 100%; min-width: 200px">-->
<!--                                                                                    <td *ngFor="let option of response.formControl.options; let optionIndex = index" style="height: 100%; width: 100%">-->
<!--                                                                                        <div #tdElement style="height: 100%; width: 100%;min-width: 200px"-->
<!--                                                                                             *ngIf="option.type_id !== 3"-->
<!--                                                                                             [innerHTML]="row.hasOwnProperty(option.cntrl_num) ?-->
<!--                                                                                         row[option.cntrl_num].data : ''">-->
<!--                                                                                        </div>-->
<!--                                                                                        <div style="height: 100%; width: 100% ; min-width: 200px"-->
<!--                                                                                             *ngIf="option.type_id === 3">-->
<!--                                                                                            <center>-->
<!--                                                                                                <mat-checkbox-->
<!--                                                                                                    #checkboxElement-->
<!--                                                                                                    [checked]="tableService.isChecked(checkboxElement, row, option)"-->
<!--                                                                                                    class="cursor"-->
<!--                                                                                                >-->
<!--                                                                                                </mat-checkbox>-->
<!--                                                                                            </center>-->
<!--                                                                                        </div>-->
<!--                                                                                    </td>-->
<!--                                                                                </tr>-->
<!--                                                                                </tbody>-->
<!--                                                                            </table>-->
<!--                                                                        </div>-->
<!--                                                                    </td>-->
<!--                                                                </tr>-->
<!--                                                                <tr>-->
<!--                                                                    <td colspan="{{marker.response[0].datas.length * 2}}">-->
<!--                                                                        <center>-->
<!--                                                                            <div class="btn btn-lg default-color">I can delivery it to you</div>-->
<!--                                                                        </center>-->
<!--                                                                    </td>-->
<!--                                                                </tr>-->
<!--                                                                </tbody>-->
<!--                                                            </table>-->
<!--                                                        </div>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </agm-info-window>-->
<!--                                        </agm-marker>-->
<!--                                        <agm-marker-->
<!--                                                *ngFor="let marker of service.markers.overpassapi.railway.data.features"-->
<!--                                                [latitude]="service.parseFloat(marker.geometry.coordinates[1])"-->
<!--                                                [longitude]="service.parseFloat(marker.geometry.coordinates[0])"-->
<!--                                                [agmFitBounds]="true"-->
<!--                                                [openInfoWindow]="true"-->
<!--                                                (markerClick)="onClickMarker($event, marker)"-->
<!--                                                [iconUrl]="railwayIcon">-->
<!--                                        </agm-marker>-->
<!--                                    </agm-map>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <mat-drawer-container class="example-container"-->
<!--                                  [matDialogClose]="true"-->
<!--                                  [hasBackdrop]="true" autosize>-->
<!--                <mat-drawer #drawer class="example-sidenav"-->
<!--                            mode="side"-->
<!--                            [(opened)]="opened"-->
<!--                            [disableClose]="false">-->
<!--                    <a (click)="drawer.toggle()" #drawerElement>&nbsp;</a>-->
<!--                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 map" style="margin-left: 10px; margin-right: 10px">-->
<!--                        <br><br><br>-->
<!--                        &lt;!&ndash;            <ngx-treeview&ndash;&gt;-->
<!--                        &lt;!&ndash;                    [config]="config"&ndash;&gt;-->
<!--                        &lt;!&ndash;                    [items]="items"&ndash;&gt;-->
<!--                        &lt;!&ndash;                    (selectedChange)="onSelectedChange($event)">&ndash;&gt;-->
<!--                        &lt;!&ndash;            </ngx-treeview>&ndash;&gt;-->
<!--                        &lt;!&ndash;            <app-ngx-treeview&ndash;&gt;-->
<!--                        &lt;!&ndash;                    [config]="config"&ndash;&gt;-->
<!--                        &lt;!&ndash;                    [items]="items">&ndash;&gt;-->
<!--                        &lt;!&ndash;            </app-ngx-treeview>&ndash;&gt;-->
<!--                        <app-treeview-form [dataSource]="dataSource"-->
<!--                                           [treeControl]="treeControl"-->
<!--                                           [questions]="incomingForm.getControls()" *ngIf="formService.questions.length !== 0"></app-treeview-form>-->
<!--                    </div>-->
<!--                </mat-drawer>-->

<!--            </mat-drawer-container>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<!--<div [ngStyle]="{'position':'fixed','top':'10px','right':'200px','z-index':'999999999999'}">-->
<!--    <div [ngStyle]="{'border-radius': '25px','background':'#fff'}" class="mdl-shadow&#45;&#45;2dp">-->
<!--        <div>-->
<!--            <table [ngStyle]="{'min-width':'350px','margin':'5px'}">-->
<!--                <td [ngStyle]="{'width':'100%'}">-->
<!--                    <input  [ngStyle]="{'scaleX':'1.37','color':'#000','text-align':'center','border-width':'0px','width':'100%'}"-->
<!--                            autocomplete="off"-->
<!--                            ngClass="sawasdee18 searchbar"-->
<!--                            #addressId-->
<!--                            placeholder=""-->
<!--                            (onAutocompleteSelected)="onGoogleMapAutocompleteSelected($event)"-->
<!--                            (onLocationSelected)="onLocationSelected($event)"-->
<!--                            matGoogleMapsAutocomplete>-->
<!--                </td>-->
<!--                <td>-->
<!--                    <mat-icon matSuffix >search</mat-icon>-->
<!--                </td>-->
<!--            </table>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->




