import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-distributors',
  templateUrl: './distributors.component.html',
  styleUrls: ['./distributors.component.scss']
})
export class DistributorsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
