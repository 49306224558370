import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {BroadcastChannelService} from "../../../broadcast-channel.service";
import {FormService} from "../../form.service";
import {ClientService} from "../../../clients/client.service";
import {CampaignService} from "../../../campaigns/campaign.service";
import {AppService} from "../../../app.service";
import {Parameters} from "../../../parameters";
import {TeamService} from "../../../teams/team.service";
import {AmbassadorsService} from "../../../ambassadors/ambassadors.service";

@Component({
  selector: 'app-form-lists',
  templateUrl: './form-lists.component.html',
  styleUrls: ['./form-lists.component.scss']
})
export class FormListsComponent implements OnInit, AfterViewInit {
  @ViewChild('searchId', {static: false})  searchId: any;
  appmodulesUrl = '../assets/json/modules.json';
  campaign: any;
  selectedTabIndex: any = 0;
  incomingCampaign: any;
  subscription: any;
  form: any;
  searchIcon: any = false;
  searchInput: any = true;
  incomingCampaignSelected: any;
  position: any;
  incomingUser: any;
  strings: any;
  incomingForm: any;
  stopFormRequesting: any = false;
  matSpinner: any = false;
  searchCntrl: any = '';
  modules: any = [];
  constructor(private router: Router,
              private broadcastChannelService: BroadcastChannelService,
              private formService: FormService,
              private ambassadorService: AmbassadorsService,
              private teamService: TeamService,
              private clientService: ClientService,
              private campaignService: CampaignService,
              public service: AppService) {
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#F7F7F7';
    this.incomingCampaignSelected = new Parameters();
    this.strings = new Parameters();
    this.incomingUser = new Parameters();
    this.incomingCampaign = new Parameters();
    this.incomingForm = new Parameters();
    this.incomingCampaignSelected.setAttributes({});
    this.searchId = ElementRef;
    this.strings.setAttributes(this.service.app.strings.params);
    this.incomingForm.setAttributes({});
    this.incomingCampaign.setAttributes({});
  }

  ngOnInit(): void {
    this.incomingUser.setAttributes(this.service.app.data.user);
    if(parseFloat(this.incomingUser.getAttributes().role.id) === 2){
      if(parseFloat(this.service.app.data.forms.data.length) > 0){
        this.onSelectedForm(this.service.app.data.forms.data[this.service.app.data.forms.data.length - 1]);
      }
    }
    this.service.httpService( 'get', this.appmodulesUrl, {},
        {ignoreLoadingBar : 'true', notify: 'false'},
        (modules: any) => {
          this.modules = modules;
        },  (error: any) => {});
    this.subscription =  this.broadcastChannelService.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          if(item instanceof Object){
            if (item.hasOwnProperty('campaign') === true) {
              this.campaignService.campaignSelectedId = item.campaign.id;
            }else if(item.hasOwnProperty('form') === true){
              this.form = item.form;
              this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.form.id) + '/dashboard');
            }else if(item.hasOwnProperty(this.strings.getAction())){
              if(item.action.includes('onSelectForm'))
                this.onSelectedForm(item.item);
            }
          }
        });
  }

  getSearchText(searchText: any){return searchText;}

  search(text){
    let outgoingRequest: any = new Parameters();
    outgoingRequest.setAttributes({});
    outgoingRequest.setSearchText(text);
    this.formService.search(outgoingRequest, (forms: any) => {
   // this.searchId.nativeElement.value = '';
      if(forms !== null){
        forms.map((form: any) => {
          let incomingForm: any = new Parameters();
          incomingForm.setAttributes(form);
          if(this.service.findIntegerIndex(this.service.app.data.forms.data, this.strings.getId(), incomingForm.getId()) === -1) {
            this.service.app.data.forms.data.push(incomingForm.getAttributes());
            setTimeout(() => {
              let elementId: any = document.getElementById(incomingForm.getId());
              elementId.scrollIntoView({behavior: 'smooth'});
            });
          }
        });
      }
    });
  }

  requestFormAndlimit(status: any){
    this.service.matSpinner = true;
    let outgoingRequest: any = new Parameters();
    outgoingRequest.setAttributes({});
    if(this.service.app.data.forms.data.length !== 0)
      outgoingRequest.setAscendinglastAt(this.service.app.data.forms.data[this.service.app.data.forms.data.length - 1].id);
    this.service.httpService( 'POST',
        this.service.app.routes.forms.search,
        outgoingRequest.getAttributes(),
        {ignoreLoadingBar : 'true', notify: 'false'},
        (forms: any) => {
          forms.map((form: any) => {
            let incomingForm: any = new Parameters();
            incomingForm.setAttributes(form);
            incomingForm.setAttributes(this.formService.getForm(incomingForm.getAttributes()));
            incomingForm.setStatus(0);
            status -= 1;
            this.service.app.data.forms.data = this.service.orderBy(
                this.service.app.data.forms.data,
                this.strings.getDesc(),
                this.strings.getId());
          });setTimeout(() => {
            if(forms.length !== 0 && status > 0)
              this.requestFormAndlimit(status);
            else if(this.service.app.data.forms.data.length === 1) {
              this.onSelectedForm(this.service.app.data.forms.data[0]);
            }else {this.service.matSpinner = false;
              window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
            }
          });
        },  (error: any) => {
        });
  }

  ngAfterViewInit(): void {
  }

  setFormCampaign(campaign: any){
    let incomingCampaign: any = new Parameters();
    incomingCampaign.setAttributes(campaign);
    this.requestClientById(incomingCampaign.getClientId(), (client: any) => {
      let incomingClient: any = new Parameters();
      incomingClient.setAttributes();
      this.setCampaignClient(incomingClient.getAttributes());
    }); this.service.app.data.forms.data.map((form: any) => {
      let incomingForm: any = new Parameters();
      incomingForm.setAttributes(form);
      if(parseFloat(incomingCampaign.getId()) === parseFloat(incomingForm.getCampaignId())){
        incomingForm.setCampaign(incomingCampaign.getAttributes());
      }
    });
  }

  setCampaignClient(client){
    let incomingClient: any = new Parameters();
    incomingClient.setAttributes(client);
    this.service.app.data.campaigns.data.map((campaign: any) => {
      let incomingCampaign: any = new Parameters();
      incomingCampaign.setAttributes(campaign);
      if(parseFloat(incomingClient.getId()) === parseFloat(incomingCampaign.getClientId())){
        incomingCampaign.setClient(incomingClient.getAttributes());
      }
    });
  }

  requestCampaignById(id: any, call: any){
    if(this.campaignService.hasCampaignById(id)) call(this.campaignService.getCampaignById(id)); else {
      this.campaignService.requestCampaignById(id, (campaign: any) => {
        if(campaign === null) this.requestCampaignById(id, call); else{
          let incomingCampaign: any = new Parameters();
          incomingCampaign.setAttributes(campaign);
          call(incomingCampaign.getAttributes());
        }
      });
    }
  }

  requestClientById(id: any, call: any){
    if(this.clientService.hasClientById(id)) call(this.clientService.getClientById(id)); else {
      this.clientService.requestClientById(id, (client: any) => {
        if(client === null) this.requestClientById(id, call); else{
          let incomingClient: any = new Parameters();
          incomingClient.setAttributes(client);
          call(incomingClient.getAttributes());
        }
      });
    }
  }

  getFormByForm(form: any){
    let campaign: any = this.campaignService.getCampaignById(form.campaign_id);
    let client: any = this.clientService.getClientById(campaign.client_id);
    campaign = Object.assign(campaign, {client: client});
    form = Object.assign(form, {campaign: campaign});
  }

  onSearchIconClicked(){
    setTimeout(() => {
      if(this.searchId.nativeElement){
        this.searchId.nativeElement.focus();
      }
    });
  }

  onSelectedForm(form: any){
    let incomingForm: any = new Parameters();
    incomingForm.setAttributes(form);
    incomingForm.setStatus(1);
    this.formService.formSelectedId = incomingForm.getId();
    this.formService.setSelected(incomingForm.getAttributes());
    this.requestCampaignById(incomingForm.getCampaignId(), (campaign: any) => {
      let incomingCampaign: any = new Parameters();
      incomingCampaign.setAttributes(campaign);
      this.campaignService.campaignSelectedId = incomingCampaign.getId();
      this.campaignService.setSelected(incomingCampaign.getAttributes());
  // incomingCampaign.getTeams()[this.strings.getData()].map((team: any) => {
      //   let incomingTeam: any = new Parameters();
      //   incomingTeam.setAttributes(team);
      //   this.ambassadorService.addAmbassadorsByTeam(this.teamService.getTeamByTeam(incomingTeam.getAttributes()));
   // });
      incomingForm.setCampaign(incomingCampaign.getAttributes());
      this.requestClientById(incomingCampaign.getClientId(), (client: any) => {
        let incomingClient: any = new Parameters();
        incomingClient.setAttributes(client);
        this.clientService.setSelected(incomingClient.getAttributes());
        incomingCampaign.setClient(incomingClient.getAttributes());
        incomingForm.setStatus(0);
        if(parseFloat(this.incomingUser.getAttributes().role.id) === 2 ||
            parseFloat(this.incomingUser.getAttributes().role.id) === 15){
          this.router.navigateByUrl('/us/app/forms/' + this.service.encrypt(form.id) + '/dashboard3', {state:{id: this.service.encrypt(form.id)}});
        }else if(parseFloat(this.incomingUser.getAttributes().role.id) === 8){
          this.router.navigateByUrl('/app/orders');
       // this.router.navigateByUrl('/app/ambassadors/' +  this.service.encrypt(this.service.app.data.user.id) + this.strings.getSlash() + this.strings.getResponses());
        }
        // else this.router.navigateByUrl(
        //     this.strings.getSlash() +
        //       this.strings.getApp() +
        //       this.strings.getSlash() +
        //       this.strings.getEvents() +
        //       this.strings.getSlash() +
        //       this.service.encrypt(form.id) +
        //       this.strings.getSlash() +
        //       this.strings.getRaffles(), {state:{id: this.service.encrypt(form.id)}});
        // else this.router.navigateByUrl(
        //     this.strings.getSlash() +
        //       this.strings.getApp() +
        //       this.strings.getSlash() +
        //       this.strings.getEvents() +
        //       this.strings.getSlash() +
        //       this.service.encrypt(form.id) +
        //       this.strings.getSlash() +
        //       this.strings.getInvitees() +
        //       this.strings.getSlash() +
        //       this.strings.getRegister(), {state:{id: this.service.encrypt(form.id)}});
        //     else this.router.navigateByUrl(
        //         this.strings.getSlash() +
        //           this.strings.getUs() +
        //           this.strings.getSlash() +
        //           this.strings.getApp() +
        //           this.strings.getSlash() +
        //           this.strings.getForms() +
        //           this.strings.getSlash() +
        //           this.service.encrypt(form.id) +
        //           this.strings.getSlash() +
        //           this.strings.getDistributors() +
        //           this.strings.getSlash() +
        //       this.strings.getDashboard(), {state:{id: this.service.encrypt(form.id)}});
        else this.router.navigateByUrl('/app/forms/' + this.service.encrypt(form.id) + '/dashboard', {state:{id: this.service.encrypt(form.id)}});
      });
    });
  }


  navigateDashboardCampaignSingleForm(){
    if(this.service.app.data.campaigns.data.length === 1){
      this.campaignService.campaignSelectedId = this.service.app.data.campaigns.data[0].id;
      if(this.service.app.data.forms.data.length === 1){
        this.formService.formSelectedId = this.service.app.data.forms.data[0].id;
        if(this.service.app.data.user.role.id === 2) {
          this.router.navigateByUrl('/us/app/forms/' + this.service.encrypt(this.service.app.data.forms.data[0].id) + '/dashboard3');
        }else this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.service.app.data.forms.data[0].id) + '/dashboard');
      }
    }
  }

  onSelectedCampaign(campaign: any){
    this.incomingCampaignSelected.setAttributes(campaign);
    this.campaignService.campaignSelectedId = this.campaign.id;
  }

  add(){
    if(this.selectedTabIndex === 0) {
      this.campaignService.campaignSelectedId = undefined;
      this.formService.openDialog({});
    } else this.campaignService.openDialog({});
  }

}