import {Component, Input, OnDestroy, AfterViewInit, NgZone, OnInit, ViewChild, ElementRef} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {AppService} from '../../../../app.service';
import {MatDialog} from '@angular/material/dialog';
import {BroadcastChannelService} from '../../../../broadcast-channel.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormService} from '../../../form.service';
import {Parameters} from '../../../../parameters';
import {NotifierService} from 'angular-notifier';
import {DeleteDialogComponent} from '../../../../main/delete-dialog.component';
import {DialogGenerateQrcodeComponent} from '../../../../invitations/dialog/dialog-generate-qrcode/dialog-generate-qrcode.component';
import {ResponseService} from '../response.service';
import {FilterDashboardComponent} from '../../../../dashboard/filter-dashboard.component';
import {MessageDisplayComponent} from '../../../../main/message/message-display.component';
import {take} from 'rxjs/operators';
import {DashboardService} from '../../../../dashboard/dashboard.service';
import {PdfFormService} from '../../../pdf/pdf-form.service';
import * as moment from 'moment';
import {CampaignService} from '../../../../campaigns/campaign.service';
import html2canvas from 'html2canvas';
import {ClientService} from '../../../../clients/client.service';
import {TableService} from '../../../controls/table/table.service';
import {StorageMap } from '@ngx-pwa/local-storage';
import {Options, NgxQrcodeStylingComponent, NgxQrcodeStylingService} from 'ngx-qrcode-styling';
import * as htmlToImage from 'html-to-image';
import * as $ from 'jquery';
import {
    DialogInvitationCardComponent
} from "../../../../invitations/card/dialog-invitation-card/dialog-invitation-card.component";
import {ImageService} from "../../../../images/image.service";
@Component({
    selector: 'app-table-responses-form',
    templateUrl: './table-responses-form.component.html',
    styleUrls: ['./table-responses-form.component.scss']
})
export class TableResponsesFormComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() flag: any;
    @ViewChild(MatMenuTrigger) contextMenu: any;
    @ViewChild('qrcode', { static: false }) public qrcode!: NgxQrcodeStylingComponent;
    @ViewChild('file')  file: any;
    contextMenuPosition = { x: '0px', y: '0px' };
    app: any;
    form: any;
    formId: any;
    responses: any = {data: [], trashed: {data: []}};
    incomingForm: any;
    subscription: any;
    searchCntrl: any;
    outgoingForm: any;
    teams: any;
    contentId: any = 0;
    isDialogOpen: any = false;
    responseSelected: any;
    questionalValues: any;
    campaign: any;
    dialogRef: any;
    descendinglastAt: any = null;
    incomingClient: any;
    incomingCampaign: any;
    incomingQuestion: any;
    incomingResponseResult: any;
    contextmenuFlag: any = 0;
    incomingOption: any;
    incomingQuestions: any;
    incomingPost: any;
    incomingFormSettings: any;
    incomingResponse: any;
    incomingSelectedQuestion: any;
    results: any;
    result: any;
    incomingResult: any;
    incomingResponseSelected: any;
    strings: any;
    posts: any = [];
    outgoingRequest: any;
    incomingInvitationQuestion: any;
    incomingInvitationCard: any;
    incomingAttendeePhoto: any;
    incomingAttendeeName: any;
    incomingJobTitle: any;
    incomingQrcode: any;
    outgoingResponseSelected: any;
    incomingResponseResultSelected: any;
    // config: Options = {
    //     height: 550,
    //     width: 550,
    //     shape: 'square',
    //     rotate: 0,
    //     data: 'xxxxxxx',
    //     margin: 1,
    //     dotsOptions: {
    //         color: '#c2201d',
    //         type: 'extra-rounded'
    //     },
    //     cornersDotOptions: {
    //         color: '#c2201d',
    //         type: 'dot'
    //     },
    //     frameOptions: {
    //         style: 'FE_125',
    //         height: 250,
    //         width: 190,
    //         x: 140,
    //         y: 120,
    //         containers: [{
    //             fill: '#FFF',
    //             stroke: '#c2201d'
    //         },{
    //             fill: '#fff'
    //         }]
    //     },
    //     qrOptions: {
    //         errorCorrectionLevel: 'H',
    //     },
    //     backgroundOptions: {
    //         color: '#fff',
    //     },
    //     imageOptions: {
    //         crossOrigin: 'anonymous',
    //         margin: 0
    //     }
    // };
    public config: Options = {
        height: 230,
        width: 230,
        shape: 'square',
        rotate: 0,
        data: 'xxxxxxx',
        margin: 1,
        dotsOptions: {
            color: '#c2201d',
            type: 'extra-rounded'
        },
        cornersDotOptions: {
            color: '#c2201d',
            type: 'dot'
        },
        frameOptions: {
            style: 'F_073',
            height: 230,
            width: 230,
            x: 92,
            y: 95
        },
        qrOptions: {
            errorCorrectionLevel: 'H',
        },
        backgroundOptions: {
            color: '#fff',
        },
        imageOptions: {
            crossOrigin: 'anonymous',
            margin: 0
        }
    };

    constructor(public  service: AppService,
                public  dialog: MatDialog,
                private pdfFormService: PdfFormService,
                private activatedRoute: ActivatedRoute,
                public  broadcastChannel: BroadcastChannelService,
                private ngxQrcodeStylingService: NgxQrcodeStylingService,
                private notifier: NotifierService,
                private dashboardService: DashboardService,
                public  tableService: TableService,
                private campaignService: CampaignService,
                public zone: NgZone,
                private imgService: ImageService,
                private clientServce: ClientService,
                private storage: StorageMap,
                private router: Router,
                private formService: FormService,
                private responseService: ResponseService) {
        this.incomingCampaign = new Parameters();
        this.incomingQuestion = new Parameters();
        this.incomingOption   = new Parameters();
        this.incomingQrcode   = new Parameters();
        this.incomingInvitationCard = new Parameters();
        this.incomingAttendeeName = new Parameters();
        this.outgoingResponseSelected = new Parameters();
        this.outgoingForm = new Parameters();
        this.incomingResponseResult   = new Parameters();
        this.strings = new Parameters();
        this.incomingResponseResultSelected = new Parameters();
        this.incomingSelectedQuestion = new Parameters();
        this.incomingJobTitle = new Parameters();
        this.incomingQuestions = new Parameters();
        this.incomingAttendeePhoto = new Parameters();
        this.file = ElementRef;
        this.incomingPost = new Parameters();
        this.incomingResult = new Parameters();
        this.incomingInvitationQuestion = new Parameters();
        this.incomingResponseSelected = new Parameters();
        this.outgoingRequest = new Parameters();
        this.incomingFormSettings = new Parameters();
        this.incomingResponse = new Parameters();
        this.app = service.app;
        this.incomingInvitationQuestion.setAttributes({});
        this.incomingAttendeePhoto.setAttributes({});
        this.incomingAttendeeName.setAttributes({});
        this.outgoingResponseSelected.setAttributes({});
        this.contextMenu = MatMenuTrigger;
        this.formId = this.activatedRoute.snapshot.params.id;
        this.formId = this.service.decrypt(this.formId);
        this.form = this.formService.getFormById(this.formId);
        this.responses = this.form.responses;
        this.incomingForm = new Parameters();
        this.incomingForm.setAttributes(this.form);
        this.incomingInvitationCard.setAttributes({});
        this.incomingJobTitle.setAttributes({});
        this.incomingQrcode.setAttributes({});
        this.responseService.reportValues.attributes.form_id = this.incomingForm.attributes.id;
        this.campaign = this.campaignService.getCampaignById(this.campaignService.campaignSelectedId);
        this.incomingCampaign.setAttributes(this.campaign);
        this.campaignService.setSelected(this.incomingCampaign.getAttributes());
        this.strings.setAttributes(this.service.app.strings.params);
        this.incomingResponseResultSelected.setAttributes({});
        this.incomingClient = new Parameters();
        this.incomingClient.setAttributes(this.clientServce.getClientById(this.incomingCampaign.attributes.client_id));
        this.clientServce.setSelected(this.incomingClient.getAttributes());
        this.incomingCampaign.setClient(this.incomingClient.getAttributes());
        this.service.subHeader = this.service.upperStartingCharacter(this.incomingCampaign.getName(), true) + ', ' + this.incomingClient.getName().toString().toLowerCase();
        this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
        this.incomingFormSettings.setOutlet(this.incomingFormSettings.hasAttribute('outlet') ? this.incomingFormSettings.getOutlet() : '');
        this.incomingFormSettings.setInvitationContents(this.incomingFormSettings.hasAttribute('invitation_contents') ? this.incomingFormSettings.getInvitationContents() : '');
        this.incomingFormSettings.setResponsesCounter(this.incomingFormSettings.hasAttribute('responses_counter') ? this.incomingFormSettings.getResponsesCounter() : 1);
        this.incomingFormSettings.setInvitationCardEmailed(this.incomingFormSettings.hasAttribute(
            this.service.app.strings.params.invitationCardEmailed) ?
            this.incomingFormSettings.getInvitationCardEmailed() : '');
        this.incomingFormSettings.setBackgroundColor(this.incomingFormSettings.hasAttribute('background_color') ? this.incomingFormSettings.getBackgroundColor() : '');
        this.incomingFormSettings.setStartingAt(this.incomingFormSettings.hasAttribute('starting_at') ? this.incomingFormSettings.getStartingAt() : '');
        this.incomingFormSettings.setInvitationCard(this.incomingFormSettings.attributes.hasOwnProperty('invitation_card') ?
            this.incomingFormSettings.getInvitationCard() : '');
        this.incomingFormSettings.setFrameUrl(this.incomingFormSettings.attributes.hasOwnProperty(
            this.service.app.strings.params.frameUrl) ?
            this.incomingFormSettings.getFrameUrl() : '');
        this.incomingFormSettings.setFrameDataUrl(this.incomingFormSettings.attributes.hasOwnProperty(
            this.service.app.strings.params.frameDataUrl) ?
            this.incomingFormSettings.getFrameDataUrl() : '');
        this.incomingFormSettings.setAttendeePic(this.incomingFormSettings.attributes.hasOwnProperty('attendee_pic') ?
            this.incomingFormSettings.getAttendeePic() : '');
        this.incomingFormSettings.setAttendeeName(this.incomingFormSettings.attributes.hasOwnProperty(this.service.app.strings.params.attendeeName) ?
            this.incomingFormSettings.getAttendeeName() : '');
        this.incomingFormSettings.setJobPosition(this.incomingFormSettings.attributes.hasOwnProperty(this.service.app.strings.params.jobPosition) ?
            this.incomingFormSettings.getJobPosition() : '');
        this.incomingFormSettings.setInvitationCardWidth(this.incomingFormSettings.attributes.hasOwnProperty(this.service.app.strings.params.invitationCardWidth) ?
            this.incomingFormSettings.getInvitationCardWidth() : 0);
        this.incomingFormSettings.setInvitationCardHeight(this.incomingFormSettings.attributes.hasOwnProperty(this.service.app.strings.params.invitationCardHeight) ?
            this.incomingFormSettings.getInvitationCardHeight() : 0);
        this.setQuestions(() => {});
    }




    getQuestionById(id: any){return this.incomingForm.getControls().filter((question: any) => parseFloat(question.id) === parseFloat(id))[0]; }
    ngOnInit(): void {
        this.incomingSelectedQuestion.setAttributes(window.location.href.toString().includes('questions') ? this.getQuestionById(this.service.decrypt(this.activatedRoute.snapshot.params.questionId)) : {});
        document.title = this.campaign.name.toString().toUpperCase() + ', ' + this.incomingClient.getName();
        this.results = this.form.name.toString().toUpperCase();
        this.results = window.location.href.toString().includes('questions') ? this.incomingSelectedQuestion.getLabel().toString().toUpperCase() : this.results;
        this.service.header =  this.results + '  ' + this.service.setDisplayedDate(this.responseService.reportValues) +  ' ,responses' ;
        if(this.responses.data.length === 0)
            if(!this.service.isNull(this.incomingFormSettings.getStartingAt()))
                this.responseService.reportValues.setStartingAt(this.incomingFormSettings.getStartingAt());
        if (this.responseService.isDialogOpen === false) {
            if (this.responses.data.length === 0){
                this.responses.data = [];
                this.responses.trashed = {data: []};
                this.report();
            }
        }setTimeout(() => {
            this.emitter();
            this.searchCntrl = this.responseService.searchText;
        });
    }

    getQuestionlabel(response: any, responseResultt: any){
        // let incomingResponse: any = new Parameters();
        // let incomingResponseResult: any = new Parameters();
        // let incomingResult: any = new Parameters();
        // let incomingQuestion: any = new Parameters();
        // let name: any = '';
        // let jobTitle: any = '';
        // let company: any = '';
        // let jobPosition: any = '';
        // incomingResponse.setAttributes(response);
        // incomingResponse.getUnknown('datas').map((responseResult: any) => {
        //     incomingResponseResult.setAttributes(responseResult);
        //     incomingQuestion.setAttributes(incomingResponseResult.getUnknown('formControl'));
        //     incomingResult.setAttributes(
        //         incomingResponseResult.hasAttribute(this.strings.getResult()) &&
        //         !this.service.isNull(incomingResponseResult.getResult()) ?
        //             incomingResponseResult.getResult() : {});
        //     if (incomingQuestion.getCntrlNum().includes(this.incomingFormSettings.getAttendeeName())) {
        //         name = incomingResponseResult.getData();
        //     }if(incomingQuestion.getCntrlNum().includes(this.incomingFormSettings.getAttendeeJobTitle())){
        //         jobTitle = incomingResponseResult.getData();
        //     }if(incomingQuestion.getCntrlNum().includes(this.incomingFormSettings.getInvitationCard())){
        //         if(!incomingResult.hasAttribute(this.strings.getUrl())){}
        //     }if(incomingQuestion.getCntrlNum().includes(this.incomingFormSettings.getAttendeeCompany())){
        //         company = incomingResponseResult.getData();
        //     }if(incomingQuestion.getCntrlNum().includes(this.incomingFormSettings.getAttendeeJobPosition())){
        //         jobPosition = incomingResponseResult.getData();
        //     }
        // });
        // if(responseResultt.formControl.label.includes('qrcodeId')){
        //     if(!this.service.isNull(this.incomingFormSettings.getFrame())) {
        //         this.service.getDataUrl(this.incomingFormSettings.getFrame(), (url: any) => {
        //         });
        //     }
        //     setTimeout(() => {
        //         let element:any = document.getElementById(incomingResponse.getId() + responseResultt.formControl[this.strings.getCntrlNum()]);
        //         while (element.firstChild) {
        //             element.firstChild.remove();
        //         }
        //         this.config[this.strings.getData()] = this.strings.getHost() +
        //             this.strings.getSlash() +
        //             this.strings.getAppInvitationsSlash() +
        //             this.service.encrypt(incomingResponse.getPostId()) +
        //             this.service.app.strings.params.confirmationQ;
        //         this.config[this.strings.getData()] = this.config[this.strings.getData()].toString().includes(this.service.app.strings.params.https) ? this.config[this.strings.getData()] : this.service.app.strings.params.https + this.config[this.strings.getData()];
        //         if (!this.service.isNull(this.incomingFormSettings.getBackgroundColor())) this.config[this.strings.getData()] = this.config[this.strings.getData()] + this.service.app.strings.params.extension + this.incomingFormSettings.getBackgroundColor();
        //         if (!this.service.isNull(this.incomingClient.getUnknown('logo'))) this.config[this.strings.getData()] = this.config[this.strings.getData()] + this.service.app.strings.params.access + this.incomingClient.getUnknown('logo').split('clients/')[1];
        //         this.ngxQrcodeStylingService.create(this.config, element).subscribe((res) => {});
        //     }, this.service.timeout()*4);
        // }

        // if(this.incomingAttendeePhoto.getAttributes().hasOwnProperty('id')) {
        //     if(responseResultt.formControl.label.includes('*photo')){
        //         incomingResponse.getUnknown('datas').map((responseResult: any) => {
        //             incomingResponseResult.setAttributes(responseResult);
        //             incomingQuestion.setAttributes(incomingResponseResult.getUnknown('formControl'));
        //             if (incomingQuestion.getCntrlNum().includes(this.incomingAttendeePhoto.getCntrlNum())) {
        //                 if(!this.service.isNull(incomingResponseResult.getData())){
        //                     this.service.getDataUrl(incomingResponseResult.getData(), (result: any) => {
        //                         responseResultt.formControl.label =
        //                             responseResultt.formControl.label
        //                                 .replaceAll('*photo', result);
        //                     });
        //                 }
        //             }
        //         });
        //     }
        // }

        return responseResultt.formControl.label;
    }

    setQuestions(callback: any){
        this.incomingForm.getControls().map((question: any) =>{
            this.incomingQuestion.setAttributes(question);
            if(this.incomingQuestion.getCntrlNum().includes(this.incomingFormSettings.getInvitationCard()))
                this.incomingInvitationCard.setAttributes(this.incomingQuestion.getAttributes());
            else if(this.incomingQuestion.getCntrlNum().includes(this.incomingFormSettings.getAttendeePic()))
                this.incomingAttendeePhoto.setAttributes(this.incomingQuestion.getAttributes());
            else if(this.incomingQuestion.getCntrlNum().includes(this.incomingFormSettings.getAttendeeName()))
                this.incomingAttendeeName.setAttributes(this.incomingQuestion.getAttributes());
            else if(this.incomingQuestion.getCntrlNum().includes(this.incomingFormSettings.getJobPosition()))
                this.incomingJobTitle.setAttributes(this.incomingQuestion.getAttributes());
            else if(parseFloat(this.incomingQuestion.getControlId()) === 33) {
                this.incomingQrcode.setAttributes(this.incomingQuestion.getAttributes());
            }
        });
            // this.incomingForm.getResponses().data.map((response: any) => {
            //     this.incomingResponse.setAttributes(response);
            //     this.incomingResponse.getUnknown('datas').map((responseResult: any) =>{
            //         this.incomingResponseResult.setAttributes(responseResult);
            //         this.incomingQuestion.setAttributes(this.incomingResponseResult.getUnknown('formControl'));
            //         if(this.incomingQuestion.getCntrlNum().includes(this.incomingFormSettings.getInvitationCard())){
            //             this.incomingInvitationCard.setAttributes(this.incomingQuestion.getAttributes());
            //         }
            //     });
            //
            // });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            window.scrollTo(this.responseService.xScrollingPart, this.responseService.yScrollingPart);
        }, this.service.timeout());
    }


    sendInvitationThroughtEmail(response: any){
        const dialogRef: any =  this.dialog.open(MessageDisplayComponent, {data : {title: 'Invitation', action: 'send email to'}, width : 'auto', });
        dialogRef.afterClosed().subscribe((item: any) => {
            if (item instanceof Object){
                if (item.hasOwnProperty('response')){
                    if (item.response.toString().includes('successfull')){
                        this.incomingResponseSelected.setAttributes(this.responseSelected);
                        this.outgoingRequest.setAttributes({});
                        this.outgoingRequest.setFormId(this.incomingForm.getId());
                        this.outgoingRequest.setResponseId(this.incomingResponseSelected.getId());
                        this.outgoingRequest.setUnknown('backgroundColor', this.incomingFormSettings.getBackgroundColor());
                        this.service.httpService( 'post', this.service.app.routes.invitations.email, this.outgoingRequest.getAttributes(), {},
                            (result: any) => {
                                this.incomingResponseSelected.attributes =
                                    Object.assign(this.incomingResponseSelected.attributes, result);
                                this.notifier.notify('success', this.service.app.strings.invitation.email.successfull);
                                this.sendInvitationThroughtWhatsapp(response);
                            },  (error: any) => {this.notifier.notify('success', this.service.app.strings.invitation.email.error);
                            });
                    }
                }
            }
        });
    }

    download(){
        this.incomingResponseSelected.setAttributes(this.responseSelected);
        this.qrcode.download('png').subscribe((result: any) => {});
    }

    sendInvitationThroughtWhatsapp(response: any){
        const dialogRef: any =  this.dialog.open(MessageDisplayComponent, {data : {title: 'Invitation', action: 'whatsapp invitation to'}, width : 'auto', });
        dialogRef.afterClosed().subscribe((item: any) => {
            if (item instanceof Object){
                if (item.hasOwnProperty('response')){
                    if (item.response.toString().includes('successfull')){
                        this.incomingResponseSelected.setAttributes(this.responseSelected);
                        this.outgoingRequest.setAttributes({});
                        this.outgoingRequest.setFormId(this.incomingForm.getId());
                        this.outgoingRequest.setResponseId(this.incomingResponseSelected.getId());
                        this.outgoingRequest.setClient(this.incomingClient.getName());
                        this.outgoingRequest.setUnknown('backgroundColor', this.incomingFormSettings.getBackgroundColor());
                        if(this.incomingResponseSelected.getAttributes().hasOwnProperty('imgSrc'))
                            this.outgoingRequest.setUnknown('imgSrc', this.incomingResponseSelected.getUnknown('imgSrc'));
                        this.incomingResponseSelected.getUnknown('datas').map((responseResult: any) => {
                            this.incomingResponseResult.setAttributes(responseResult);
                            this.incomingQuestion.setAttributes(this.incomingResponseResult.getUnknown('formControl'));
                            this.incomingQuestion.setResponseResultId(this.incomingResponseResult.getId());
                            this.incomingQuestion.setData(this.incomingResponseResult.getData());
                            this.incomingQuestion.setResult(this.incomingResponseResult.getResult());
                            if (this.incomingQuestion.getCntrlNum() === this.incomingFormSettings.getInvitationContents()){
                                this.service.httpService( 'post', this.service.app.routes.invitations.whatsapp, this.outgoingRequest.getAttributes(), {},
                                    (result: any) => {
                                        this.incomingResponseSelected.attributes =
                                            Object.assign(this.incomingResponseSelected.attributes, result);
                                        this.notifier.notify('success', this.service.app.strings.invitation.whatsapp.successfull);
                                    },  (error: any) => {
                                    });
                            }
                        });
                    }
                }
            }
        });
//       const dialogRef: any =  this.dialog.open(MessageDisplayComponent, {data : {title: 'Invitation', action: 'send whatsapp message to'},width : 'auto',});
//       dialogRef.afterClosed().subscribe((item: any) => {
//         if (item instanceof Object){
//           if (item.hasOwnProperty('response')){
//             if (item.response.toString().includes('successfull')){
//
//             }
//           }
//         }
//       });
    }

    isInvitationCardEmailed(responseSelected: any, callback: any){
        const incomingResponseSelected: any = new Parameters();
        const incomingResponseResult: any = new Parameters();
        let result: any = false;
        incomingResponseSelected.setAttributes(responseSelected);
        result = false;
        incomingResponseSelected.getUnknown('datas').map((responseResult: any) => {
            incomingResponseResult.setAttributes(responseResult);
            if (incomingResponseResult.getUnknown('formControl').cntrl_num.toString() === this.incomingFormSettings.getInvitationCardEmailed().toString()){
                if (incomingResponseResult.getUnknown('data').toLowerCase().includes('yes')) {
                    result = true;
                }
            }
        });
        callback(result);
    }

    sendInvitationCardRequest(){
        const incomingResponseSelected: any = new Parameters();
        const outgoingRequest: any = new Parameters();
        incomingResponseSelected.setAttributes({});
        if (this.posts.length !== 0){
            this.posts.map((post: any, incremental: any) => {
                if (this.responseService.isDialogOpen === false){
                    this.responseService.isDialogOpen = true;
                    incomingResponseSelected.setAttributes(post);
                    // this.responseService.setSelected(incomingResponseSelected.getAttributes());
                    this.isInvitationCardEmailed(incomingResponseSelected.getAttributes(),(result: any) => {
                        if (!result){
                            outgoingRequest.setAttributes({});
                            outgoingRequest.setFormId(this.incomingForm.getId());
                            outgoingRequest.setResponseId(incomingResponseSelected.getId());
                            outgoingRequest.setUnknown('backgroundColor', this.incomingFormSettings.getBackgroundColor());
                            this.service.httpService( 'post', this.service.app.routes.invitations.email, outgoingRequest.getAttributes(), {},
                                (result: any) => {
                                    this.notifier.notify('success', this.service.app.strings.invitation.email.successfull);
                                    this.posts.splice(this.service.findIndex(this.posts, 'id',
                                        parseFloat(incomingResponseSelected.getId())), 1);
                                    this.responseService.isDialogOpen = false;
                                    this.sendInvitationCardRequest();
                                },  (error: any) => {
                                    this.notifier.notify('success', this.service.app.strings.invitation.email.error);
                                    this.posts.splice(this.service.findIndex(this.posts, 'id',
                                        parseFloat(incomingResponseSelected.getId())), 1);
                                    this.responseService.isDialogOpen = false;
                                    this.sendInvitationCardRequest();
                                });
                        }else {
                            this.posts.splice(this.service.findIndex(this.posts, 'id',
                                parseFloat(incomingResponseSelected.getId())), 1);
                            this.responseService.isDialogOpen = false;
                            this.sendInvitationCardRequest();
                        }
                    });
                }
            });
        }else { this.fetchAllByDescendingAndSendInvitation(); }
    }

    downloadByContentId(){
        const elementHtml: any = document.getElementById(this.contentId);
        this.incomingResponseResultSelected.setUnknown('matSpinner','null');
        setTimeout(() => {
            html2canvas(elementHtml).then(canvas => {
                let name: any = '';
                this.incomingResponseSelected.getUnknown('datas').map((responseResult: any) => {
                    this.incomingResponseResult.setAttributes(responseResult);
                    if(this.incomingResponseResult.getUnknown('formControl').cntrl_num.includes(this.incomingFormSettings.getAttendeeName()))
                        name = this.incomingResponseResult.getUnknown('data');
                });
                this.imgService.download(name, canvas.toDataURL('image/png'),(result: any) => {
                    delete this.incomingResponseResultSelected.getAttributes().matSpinner;
                });
            });
        });
    }

    sendInvitation2(){
        const node: any = document.getElementById(this.contentId);
        this.incomingResponseResultSelected.setUnknown('matSpinner','null');
        setTimeout(() => {
            html2canvas(node).then(canvas => {
                this.incomingResponseSelected.getUnknown('datas').map((responseResult: any) => {
                    this.incomingResponseResult.setAttributes(responseResult);
                    if(this.incomingResponseResult.getUnknown('formControl').cntrl_num.includes(this.incomingFormSettings.getInvitationContents())){
                        const outgoingRequest: any = new Parameters();
                        const incomingResult: any = new Parameters();
                        incomingResult.setAttributes(this.incomingResponseResult.getAttributes().hasOwnProperty('result') &&
                        !this.service.isNull(this.incomingResponseResult.getResult()) ? this.incomingResponseResult.getResult() : {});
                        outgoingRequest.setAttributes({});
                        outgoingRequest.setAttributes({});
                        outgoingRequest.setSrc(null);
                        outgoingRequest.setFlag(0);
                        outgoingRequest.setCaption('');
                        outgoingRequest.setOrderBy('');
                        outgoingRequest.setStatus(1);
                        outgoingRequest.setUrl('');
                        outgoingRequest.setPath('');
                        outgoingRequest.setAvatar(this.service.dataURItoBlob(canvas.toDataURL("image/png")));
                        this.service.httpService('post', this.service.app.routes.texttospeech.writeFile,
                            outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true'},(result: any) => {
                                incomingResult.setSrc(result.path);
                                this.incomingResponseSelected.setUnknown('imgSrc',result.path);
                                this.sendInvitationThroughtWhatsapp(this.incomingResponseSelected.getAttributes());
                                delete this.incomingResponseResultSelected.getAttributes().matSpinner;
                            }, (error: any) => {
                                delete this.incomingResponseResultSelected.getAttributes().matSpinner;
                            });
                    }
                });
            });
        });
    }

    emitter(){
        this.subscription = this.broadcastChannel.getNavChangeEmitter().pipe()
            .subscribe((item: any) => {
                if (item instanceof Object) {
                    let incomingItem: any = new Parameters();
                    incomingItem.setAttributes(item);
                    if (incomingItem.getAttributes().hasOwnProperty('searchText')){
                        this.searchCntrl = incomingItem.getUnknown('searchText');
                        this.responseService.searchText = incomingItem.getUnknown('searchText');
                        if(!(item.hasOwnProperty(this.strings.getStatus()) && item[this.strings.getStatus()] === 0))
                            this.handleSearch();
                    }else if (incomingItem.getAttributes().hasOwnProperty('action')){
                        let incomingAction: any = new Parameters();
                        incomingAction.setAttributes(incomingItem.getAction());
                        if(incomingItem.getUnknown('action').toLowerCase().includes('6547cc106038f')){
                            if (this.responseService.isDialogOpened === false) {
                                this.responseService.isDialogOpened = true;
                                this.incomingResponseSelected.setAttributes({});
                                this.chatbot();
                            }
                        }else if(incomingItem.getUnknown('action').toLowerCase().includes('send card')){
                            if (this.responseService.isDialogOpened === false) {
                                this.responseService.isDialogOpened = true;
                                this.posts = [];
                                this.openInvitationCardSettingDialog({});
                            }
                        }else if(incomingItem.getUnknown('action').toLowerCase().includes('650e4e574f651')){
                            if (this.responseService.isDialogOpened === false) {
                                this.responseService.isDialogOpened = true;
                                setTimeout(() => {
                                    this.outgoingRequest.setAttributes({});
                                    this.outgoingRequest.setFormId(this.incomingForm.getId());
                                    this.outgoingRequest.setFlag(this.strings.getPhone());
                                    this.service.httpService('post', this.service.app.routes.forms.invitations.sendrequest,
                                        this.outgoingRequest.attributes, {}, (response: any) => {
                                            this.zone.run(() => { this.responseService.isDialogOpen = false;});
                                        }, (error: any) => {
                                            this.zone.run(() => { this.responseService.isDialogOpen = false; });
                                        });
                                });
                            }
                        }else if(incomingItem.getUnknown('action').toLowerCase().includes('6579d5d59577d')){
                            if (this.responseService.isDialogOpened === false) {
                                this.responseService.isDialogOpened = true;
                                setTimeout(() => {
                                    this.outgoingRequest.setAttributes({});
                                    this.outgoingRequest.setFormId(this.incomingForm.getId());
                                    this.outgoingRequest.setFlag(this.strings.getPhone());
                                    this.service.httpService('post', this.service.app.routes.forms.invitations.validatewhatsapprequest,
                                        this.outgoingRequest.attributes, {}, (response: any) => {
                                            this.zone.run(() => { this.responseService.isDialogOpen = false;});
                                        }, (error: any) => { this.zone.run(() => { this.responseService.isDialogOpen = false; });
                                        });
                                });
                            }
                        } else if (incomingItem.getUnknown('action').includes('afterCloseDialog')) {
                            if (this.responseService.isDialogOpened === false) {
                                this.responseService.isDialogOpened = true;
                                const incomingResponse: any = new Parameters();
                                incomingResponse.setAttributes(incomingItem.getResponse());
                                if(incomingResponse.getAttributes().hasOwnProperty('id')){
                                    this.posts.splice(this.service.findIntegerIndex(this.posts, 'id',
                                        parseFloat(incomingResponse.getId())), 1);
                                    setTimeout(() => {
                                        this.openInvitationCardSettingDialog(
                                            incomingAction.getAttributes().
                                            hasOwnProperty(this.strings.getStatus()) ? {status: 0} : {});
                                    });
                                }
                            }
                        } else if (incomingItem.getUnknown('action').toLowerCase().includes('import')) {
                            if (this.responseService.isDialogOpen === false) {
                                this.responseService.isDialogOpen = true;
                                this.file.nativeElement.click();
                            }
                        }
                    }
                } else if (item.toString().toLowerCase() === 'add') {
                    this.addResponse();
                } else if (item.includes('extract')) {
                    this.responseService.reportValues.setDisplay('excel');
                    if (this.responseService.isDialogOpen === false) {
                        this.responseService.isDialogOpen = true;
                        this.dialog.open(FilterDashboardComponent, {
                            data: {report: this.responseService.reportValues.getAttributes()},
                            width: '55%',
                            height: '100%'
                        }).beforeClosed().subscribe(() => {
                            this.responseService.isDialogOpen = false;
                        });
                    }
                }else if (item.includes('qrcodeCloseDialog')) {
                    if (this.responseService.isDialogOpen === false) {
                        this.posts.splice(this.service.findIndex(this.posts, 'id',
                            parseFloat(this.incomingResponseSelected.getId())), 1);
                        setTimeout(() => {
                            this.openDialogGenerateQrcodeComponent();
                        });
                    }
                }else if (item.includes('search')) {
                    this.handleSearch();
                }else if (item.includes('qrcode')) {
                    if (this.responseService.isDialogOpen === false) {
                        this.responseService.isDialogOpen = true;
                        this.generateQrcodeUrl();
                    }
                }else if (item.includes('sendInvitation')) {
                    if (this.responseService.isDialogOpen === false) {
                        this.responseService.isDialogOpen = true;
                        this.sendInvitationCards();
                    }
                }else if (item === 'openDateMenu') {
                    this.openMenuDate();
                } else if (item === 'report') {
                    this.responseService.isDialogOpen = false;
                    this.report();
                    // this.formService.setDate(this.responseService, this.dashboardService);
                }else if (item.includes('more')) {
                    if (this.responses.data.length !== 0){
                        this.responseService.reportValues.setDescendinglastAt(this.responses.data[this.responses.data.length - 1].id);
                        this.report();
                    }

                }
            });
    }

    setCrypt(ciphertext: any, callback: any){
        try{const result: any = this.service.encrypt(ciphertext);
            if (this.service.isNull(this.service.decrypt(result))) { this.setCrypt(ciphertext, callback); } else { callback(result); }
        }catch (e){this.setCrypt(ciphertext, callback); }
    }

    getInvitationCardResponseResultByResponse(response){
        const incomingResponse: any = new Parameters();
        const incomingResponseResult: any = new Parameters();
        const outgoingResponseResult: any = new Parameters();
        incomingResponse.setAttributes(response);
        outgoingResponseResult.setAttributes({});
        incomingResponse.getUnknown('datas').map((responseResult: any) => {
            incomingResponseResult.setAttributes(responseResult);
            if(incomingResponseResult.getUnknown('formControl').cntrl_num.toString().includes(this.incomingInvitationCard.getCntrlNum().toString())){
                outgoingResponseResult.setAttributes(incomingResponseResult.getAttributes());
            }
        });return outgoingResponseResult.getAttributes();
    }


    getQrcodeUrlByResponseResult(response: any , responseResult: any){
        const incomingResponseResult: any = new Parameters();
        const outgoingRequest: any = new Parameters();
        const incomingResponse: any = new Parameters();
        const incomingResult: any = new Parameters();
        const incomingQuestion: any = new Parameters();
        const outgoingResult: any = new Parameters();
        const outgoingResponse: any = new Parameters();
        const outgoingResults: any = new Parameters();
        let config: any = null;
        incomingResponseResult.setAttributes(responseResult);
        incomingResponse.setAttributes(response);
        incomingResult.setAttributes(
            incomingResponseResult.getAttributes().hasOwnProperty('result') &&
            !this.service.isNull(incomingResponseResult.getResult()) ? incomingResponseResult.getResult() : {});
        incomingResponseResult.setResult(incomingResult.getAttributes());
        incomingResult.setUnknown('data', incomingResult.getAttributes().hasOwnProperty('data') ? incomingResult.getUnknown('data') : '');
        if (!incomingResponseResult.getAttributes().hasOwnProperty('dataUrl')){
            incomingResponseResult.setUnknown('dataUrl', 'null');
            this.setCrypt(incomingResponse.getCntrlNum(), (result: any) => {
                config = window.location.host +
                    this.service.app.strings.params.appInvitations + result +
                    this.service.app.strings.params.confirmationQ;
                config = config.toString().includes(this.service.app.strings.params.https) ? config : this.service.app.strings.params.https + config;
                if (!this.service.isNull(this.incomingFormSettings.getBackgroundColor())) config = config + this.service.app.strings.params.extension + this.incomingFormSettings.getBackgroundColor();
                if (!this.service.isNull(this.incomingClient.getUnknown('logo'))) config = config + this.service.app.strings.params.access + this.incomingClient.getUnknown('logo').split('clients/')[1];
                incomingResult.setData(config);
                incomingResponseResult.setData(config);
                incomingQuestion.setAttributes(incomingResponseResult.getUnknown('formControl'));
                this.getInvitationCardElementHtml(incomingResponse.getAttributes(), () => {
                    setTimeout(() => {
                      const element: any = document.getElementById('qrcode' +incomingResponse.getId());
                      if(element.innerHTML.includes('<canvas')){
                        html2canvas(element).then(canvas => {
                            this.zone.run(() => {
                                incomingResponseResult.setUnknown('dataUrl', canvas.toDataURL('image/png'));
                                // incomingResult.setPath(canvas.toDataURL('image/png'));
                                // outgoingRequest.setAttributes({});
                                // outgoingRequest.setAttributes({});
                                // outgoingRequest.setSrc(null);
                                // outgoingRequest.setFlag(0);
                                // outgoingRequest.setCaption('');
                                // outgoingRequest.setOrderBy('');
                                // outgoingRequest.setStatus(1);
                                // outgoingRequest.setUrl('');
                                // outgoingRequest.setPath('');
                                // outgoingRequest.setAvatar(this.service.dataURItoBlob(incomingResult.getPath()));
                                // this.service.httpService('post', this.service.app.routes.texttospeech.writeFile,
                                //     outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true'},(response: any) => {
                                //         outgoingResponse.setAttributes(response);
                                //         incomingResult.setSrc(outgoingResponse.getPath());
                                //         outgoingRequest.setAttributes({});
                                //         if(parseFloat(incomingResponseResult.getId()) !== 0)
                                //             outgoingRequest.setId(incomingResponseResult.getId());
                                //         outgoingRequest.setData(incomingResponseResult.getData());
                                //         outgoingRequest.setFormControlId(incomingQuestion.getId());
                                //         outgoingRequest.setPostId(incomingResponse.getId());
                                //         outgoingRequest.setControlId(incomingQuestion.getControlId());
                                //         outgoingRequest.setResult(JSON.stringify(Object.assign({}, incomingResult.getAttributes())));
                                //         this.service.httpService('post',
                                //             this.service.app.routes.forms.responses.responseResults.update.toString(),
                                //             outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (result: any) => {
                                //             }, (error: any) =>  {
                                //             });
                                //     }, (error: any) => {
                                //     });
                            });
                        });
                      }
                    },this.service.timeout()*3);
                });
            });
        }
        return incomingResult.getUnknown('data');
    }

    getInvitationCardElementHtml(response, callback: any){
        const incomingResponse: any = new Parameters();
        incomingResponse.setAttributes(response);
        const element: any = document.getElementById('qrcode' +incomingResponse.getId());
        if(!element.innerHTML.toString().includes('*qrcode')){callback(null);
        }else this.getInvitationCardElementHtml(incomingResponse.getAttributes(),callback);
    }

    getCanvasOptions(){
        const opt: any = {
            margin: 1,
            image: { type: 'jpeg', quality: 0.20 },
            html2canvas: {scale: 7, logging: true},
        };
    }

    readFile(event: any) {
        const files: FileList = event.target.files;
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            this.outgoingRequest.setAttributes({});
            this.outgoingRequest.setFormId(this.incomingForm.getId());
            this.outgoingRequest.setFile(this.service.dataURItoBlob(reader.result));
            this.service.httpService('post', this.service.app.routes.responses.excel.import,
                this.outgoingRequest.attributes, {}, (response: any) => {
                    event.target.files = null;
                    this.responseService.isDialogOpen = false;
                    this.responseService.reportValues.setStartingAt(moment(new Date()).format('MM/DD/YYYY'));
                    this.responseService.reportValues.setEndingAt(moment(new Date()).format('MM/DD/YYYY'));
                    if (this.responseService.reportValues.attributes.hasOwnProperty('descending_last_at')) {
                        delete this.responseService.reportValues.getAttributes().descending_last_at;
                    }
                    this.notifier.notify('success', this.service.app.strings.responses.import.successfull);
                    this.report();
                }, (error: any) => {
                    event.target.files = null;
                    this.responseService.isDialogOpen = false;
                });

        };
    }

    sendInvitation(){
        let dialogRef: any =  this.dialog.open(MessageDisplayComponent, {data : {title: 'Invitation', action: 'send invitation to'}, width : 'auto', });
        dialogRef.afterClosed().subscribe((item: any) => {
            if (item instanceof Object) {
                if (item.hasOwnProperty(this.strings.getResponse())) {
                    if (item.response.toString().includes(this.strings.getSuccessfull())) {
                        let outgoingRequest: any = new Parameters();
                        outgoingRequest.setAttributes({});
                        outgoingRequest.setResponseId(this.incomingResponseSelected.getId());
                        outgoingRequest.setFormId(this.incomingResponseSelected.getFormId());
                        this.service.httpService('post', this.service.app.routes.events.sendInvitation,
                            outgoingRequest.getAttributes(), {}, (result: any) => {}, (error: any) => { });
                    }
                }
            }
        });

    }

    fetchAllByDescendingAndSendInvitation(){
        if (this.incomingResponseSelected.getAttributes().hasOwnProperty('id')){
            this.outgoingRequest.setAttributes(Object.assign({}, this.responseService.reportValues.attributes));
            this.outgoingRequest.setFormId(this.incomingForm.getId());
            this.outgoingRequest.setCategoryId(6);
            this.outgoingRequest.setDescendinglastAt(this.incomingResponseSelected.getId());
            if (this.outgoingRequest.getAttributes().hasOwnProperty('data')) {
                delete this.outgoingRequest.data;
            }
            this.service.httpService('post', this.service.app.routes.reports.fetch, this.outgoingRequest.getAttributes(), {},
                (responses: any) => {
                    if (responses.length !== 0){
                        this.posts = responses;
                        this.responseService.isDialogOpen = false;
                        this.sendInvitationCardRequest();
                    }
                }, (error: any) => {
                });
        }
    }

    onContentEditableTextChange(responseResult, text){
        let incomingResponseResult: any = new Parameters();
        let incomingQuestion: any = new Parameters();
        let outgoingRequest: any = new Parameters();
        incomingResponseResult.setAttributes(responseResult);
        incomingQuestion.setAttributes(incomingResponseResult.getUnknown(this.strings.getFormControll()));
        outgoingRequest.setAttributes({});
        if(incomingResponseResult.hasAttribute(this.strings.getId()) &&
            this.service.parseFloat(incomingResponseResult.getId()) !== 0)
            outgoingRequest.setId(incomingResponseResult.getId());
        outgoingRequest.setData(text);
        outgoingRequest.setFormControlId(incomingQuestion.getId());
        outgoingRequest.setPostId(incomingResponseResult.getPostId());
        outgoingRequest.setControlId(incomingQuestion.getControlId());
        outgoingRequest.setResult('');
        outgoingRequest.setIgnoreLoadingBar('true');
        this.responseService.updateResponseResult(outgoingRequest, (updatedResponseResult: any) => {
            if(updatedResponseResult !== null){
            }
        });
    }

    sendInvitationCard(response: any){
        let outgoingRequest: any = new Parameters();
        outgoingRequest.setAttributes({});
        outgoingRequest.setResponseId(this.incomingResponseSelected.getId());
        let dialogRef: any =  this.dialog.open(MessageDisplayComponent, {data : {title: 'Invitation', action: 'send card to'}, width : 'auto', });
        dialogRef.afterClosed().subscribe((item: any) => {
            if (item instanceof Object) {
                if (item.hasOwnProperty(this.strings.getResponse())) {
                    if (item.response.toString().includes(this.strings.getSuccessfull())) {
                        this.responseService.sendInvitationCard(outgoingRequest, (output: any) => {
                            if(output !== null){
                                this.notifier.notify(this.strings.getSuccess(), this.service.app.strings.invitations.card.sent.successful);
                            }
                        });
                    }
                }
            }
        });
        // this.service.httpService('post', this.service.app.routes.reports.fetch, outgoingRequest.getAttributes(), {},
        //     (responses: any) => {
        //         this.posts = responses;
        //         this.responseService.isDialogOpen = false;
        //         this.sendInvitationCardRequest();
        //     }, (error: any) => {
        //     });
    }

    sendInvitationCards(){
        setTimeout(() => {
            let outgoingRequest: any = new Parameters();
            outgoingRequest.setAttributes(Object.assign({}, this.responseService.reportValues.attributes));
            outgoingRequest.setFormId(this.incomingForm.getId());
            outgoingRequest.setCategoryId(6);
            if (outgoingRequest.getAttributes().hasOwnProperty('data'))
                delete outgoingRequest.attributes.data;
            this.service.httpService('post', this.service.app.routes.reports.fetch, outgoingRequest.getAttributes(), {},
                (responses: any) => {
                    this.posts = responses;
                    this.responseService.isDialogOpen = false;
                    this.sendInvitationCardRequest();
                }, (error: any) => {
                });
        });

    }

    fetchAllByDescending(){
        if (this.incomingResponseSelected.getAttributes().hasOwnProperty('id')){
            this.outgoingRequest.setAttributes(Object.assign({}, this.responseService.reportValues.attributes));
            this.outgoingRequest.setFormId(this.incomingForm.getId());
            this.outgoingRequest.setCategoryId(6);
            this.outgoingRequest.setUnknown('qrcode', null);
            this.outgoingRequest.setDescendinglastAt(this.incomingResponseSelected.getId());
            if (this.outgoingRequest.getAttributes().hasOwnProperty('data')) {
                delete this.outgoingRequest.data;
            }
            this.service.httpService('post', this.service.app.routes.reports.fetch, this.outgoingRequest.getAttributes(), {},
                (responses: any) => {
                    if (responses.length !== 0){
                        this.posts = responses;
                        this.responseService.isDialogOpen = false;
                        this.openDialogGenerateQrcodeComponent();
                    }
                }, (error: any) => {
                });
        }
    }



    getResponsesByUnEmailedInvitationCard(responseSelected: any){

    }

    openDialogGenerateQrcodeComponent(){
        if (this.posts.length !== 0){
            this.posts.map((post: any, incremental: any) => {
                if (this.responseService.isDialogOpened === false){
                    this.responseService.isDialogOpened = true;
                    this.incomingResponseSelected.setAttributes(post);
                    this.responseService.setSelected(this.incomingResponseSelected.getAttributes());
                    this.dialogRef = this.dialog.open(DialogGenerateQrcodeComponent, {
                        data: {},
                        width: '55%',
                        height: '100%'
                    }).beforeClosed().subscribe(() => {
                        this.responseService.isDialogOpened = false;
                    });
                }
            });
        }else { this.fetchAllByDescending(); }
    }


    getAttendeesByIsInvited(responseSelected: any){
        const incomingResponseSelected: any = new Parameters();
        const outgoingRequest: any = new Parameters();
        incomingResponseSelected.setAttributes(responseSelected);
        outgoingRequest.setAttributes(Object.assign({}, this.responseService.reportValues.attributes));
        outgoingRequest.setFormId(this.incomingForm.getId());
        outgoingRequest.setFlag(this.strings.getIsInvited());
        if(incomingResponseSelected.getAttributes().hasOwnProperty('id'))
            outgoingRequest.setDescendinglastAt(incomingResponseSelected.getId());
        if (outgoingRequest.getAttributes().hasOwnProperty('data'))
            delete outgoingRequest.attributes.data;
        this.service.httpService('post', this.service.app.routes.reports.fetch, outgoingRequest.getAttributes(), {},
            (responses: any) => {
                this.responseService.isDialogOpened = false;
                if (responses.length !== 0){
                    this.posts = responses;
                    this.openInvitationCardSettingDialog({status: 0});
                }
            }, (error: any) => {
                this.getAttendeesByIsInvited(incomingResponseSelected.getAttributes());
            });
    }


    openInvitationCardSettingDialog(params: any){
        let incomingParams: any = new Parameters();
        incomingParams.setAttributes(params);
        const incomingResponseSelected: any = new Parameters();
        if (this.posts.length !== 0){
            this.posts.map((post: any, incremental: any) => {
                if (this.responseService.isDialogOpened === false){
                    this.responseService.isDialogOpened = true;
                    incomingResponseSelected.setAttributes(post);
                    let dialogRef: any = this.dialog.open(DialogInvitationCardComponent, {
                        data: {response: incomingResponseSelected.getAttributes(), params: params},
                        width: '55%',
                        height: '100%'
                    }).afterClosed().subscribe(() => {
                        this.zone.run(() => {
                            this.responseService.isDialogOpened = false;
                        });
                    });
                }
            });
        }else this.openDialogByB({}, params);
    }


    generateQrcodeUrl(){
        setTimeout(() => {
            this.outgoingRequest.setAttributes(Object.assign({}, this.responseService.reportValues.attributes));
            this.outgoingRequest.setFormId(this.incomingForm.getId());
            this.outgoingRequest.setCategoryId(6);
            this.outgoingRequest.setUnknown('qrcode', null);
            if (this.outgoingRequest.getAttributes().hasOwnProperty('data'))
                delete this.outgoingRequest.data;
            this.service.httpService('post', this.service.app.routes.reports.fetch, this.outgoingRequest.getAttributes(), {},
                (responses: any) => {
                    this.posts = responses;
                    this.responseService.isDialogOpen = false;
                    this.openDialogGenerateQrcodeComponent();
                }, (error: any) => {
                });
        });

    }

    chatbot(){
        this.outgoingRequest.setAttributes({});
        let phone: any = null;
        if(this.incomingResponseSelected.getAttributes().hasOwnProperty(this.strings.getId())) {
            this.outgoingRequest.setResponseId(this.incomingResponseSelected.getId());
            this.incomingResponseSelected.getUnknown('datas').map((responseResult: any) => {
                this.incomingResponseResult.setAttributes(responseResult);
                this.incomingQuestion.setAttributes(this.incomingResponseResult.getUnknown('formControl'));
                if(this.incomingQuestion.getCntrlNum().includes(this.incomingFormSettings.getAttendeePhone())){
                    if(!this.service.isNull(this.incomingResponseResult.getData())){
                        phone = this.incomingResponseResult.getData();
                    }
                }
            });
        }
        phone =  prompt('', phone === null ? '' : phone);
        if (phone !== null) {
            this.outgoingRequest.setFormId(this.incomingForm.getId());
            this.outgoingRequest.setPhone(phone);
            this.service.httpService('post',
                this.service.app.routes.forms.chatbot,
                this.outgoingRequest.getAttributes(), {}, (responses: any) => {
                    this.responseService.isDialogOpened = false; }, (error: any) => {
                  this.responseService.isDialogOpened = false;
                });
        }else this.responseService.isDialogOpened = false;
    }

    openDialogByB(responseSelected: any, params: any){
        let incomingParams: any = new Parameters();
        incomingParams.setAttributes(params);
        const incomingResponseSelected: any = new Parameters();
        const outgoingRequest: any = new Parameters();
        incomingResponseSelected.setAttributes(responseSelected);
        outgoingRequest.setAttributes(Object.assign({}, this.responseService.reportValues.attributes));
        outgoingRequest.setFormId(this.incomingForm.getId());
        if(!incomingParams.getAttributes().hasOwnProperty(this.strings.getStatus())) {
            outgoingRequest.setFlag(this.service.app.strings.params.invitationCardEmailed);
        }else outgoingRequest.setFlag(this.strings.getIsInvited());
        if(incomingResponseSelected.getAttributes().hasOwnProperty('id'))
            outgoingRequest.setDescendinglastAt(incomingResponseSelected.getId());
        if (outgoingRequest.getAttributes().hasOwnProperty('data'))
            delete outgoingRequest.attributes.data;
        this.service.httpService('post', this.service.app.routes.reports.fetch, outgoingRequest.getAttributes(), {},
            (responses: any) => {
                this.responseService.isDialogOpened = false;
                if (responses.length !== 0){
                    this.posts = responses;
                    this.openInvitationCardSettingDialog(
                        incomingParams.getAttributes());
                }

                // if (responses.length !== 0){
                //     this.posts = responses;
                //     this.openInvitationCardDialog();
                // }
            }, (error: any) => {
                this.openDialogByB(
                    incomingResponseSelected.getAttributes(),
                    incomingParams.getAttributes());
            });
    }

    copy(){
        document.execCommand('copy');
    }

    addResponse(){
        this.responseService.setSelected({});
        this.responseService.openDialog();
        // if (!this.responseService.isDialogOpen){
        //     this.responseService.isDialogOpen = true;
        //     this.outgoingForm.setAttributes(Object.assign({}, this.form));
        //     // this.responseService.setPost(this.outgoingForm.getAttributes(), (response: any) => {
        //     //     let responses: any = [];
        //     //     const controls: any = [];
        //     //     this.outgoingForm.setControls(this.outgoingForm.getControls().map((question: any) => {
        //     //         this.incomingQuestion.setAttributes(question);
        //     //         this.incomingQuestion.setData('');
        //     //         this.incomingQuestion.setResult({});
        //     //         return this.incomingQuestion.getAttributes();
        //     //     }));
        //     //     this.formService.formSelectedId = this.outgoingForm.getId();
        //     //     this.incomingPost.setAttributes(response);
        //     //     this.incomingPost.setForm(this.outgoingForm.getAttributes());
        //     //     this.responseService.setSelected(this.incomingPost.getAttributes());
        //     //     this.formService.setSelected(this.outgoingForm.getAttributes());
        //     //     responses.push(this.incomingPost.getAttributes());
        //     //     this.responseService.isDialogOpen = false;
        //     //     this.storage.set('responses', responses).subscribe(() => {
        //     //         this.storage.set('others', []).subscribe(() => {
        //     //             this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.outgoingForm.getId()) + '/respond');
        //     //         });
        //     //     });
        //     // });
        //
        // }

    }


    report(){
        if (this.responseService.isDialogOpen === false) {
            this.responseService.isDialogOpen = true;
            setTimeout((outgoingRequest: any = {}) => {
                outgoingRequest = Object.assign(outgoingRequest, this.responseService.reportValues.attributes);
                delete outgoingRequest.data;
                if (window.location.href.toString().includes('questions')) {
                    outgoingRequest.question_id = this.service.decrypt(this.activatedRoute.snapshot.params.questionId);
                }
                if (window.location.href.toString().includes('labels')) {
                    outgoingRequest.optionCntrlNum = this.service.decrypt(this.activatedRoute.snapshot.params.label);
                }
                this.service.httpService('post', '/reports', outgoingRequest, {}, (responses: any) => {
                    if (this.responseService.reportValues.attributes.hasOwnProperty('descending_last_at')){
                        responses.map((response: any) => {
                            this.incomingResponse.setAttributes(response);
                            this.responses.data.push(this.incomingResponse.getAttributes());
                            delete this.responseService.reportValues.attributes.descending_last_at;
                            delete outgoingRequest.descending_last_at;
                        });
                    }else { this.responses.data = responses; }
                    this.responseService.reportValues.attributes.data = 'values';
                    this.responseService.isDialogOpen = false;
                }, (error: any) => {
                    this.responseService.isDialogOpen = false;
                    if (this.responseService.reportValues.attributes.hasOwnProperty('descending_last_at')) {
                        delete this.responseService.reportValues.attributes.descending_last_at;
                    }
                });
            });
        }
    }

    print(form: any){
        this.formService.setSelected(this.form);
        this.formService.questions = Object.assign(this.formService.questions, this.form.controls);
        setTimeout((position: any) => {
            this.responseSelected.datas.map((question: any) => {
                this.questionalValues = new Parameters();
                this.questionalValues.setAttributes(question);
                position = this.service.findIndex(this.formService.questions, 'cntrl_num', this.questionalValues.attributes.formControl.cntrl_num);
                if (this.formService.questions[position]){
                    this.formService.questions[position] = Object.assign(this.formService.questions[position] , {data: this.questionalValues.attributes.data});
                }
            });
            this.pdfFormService.downloadPdf();
        });
    }


    openMenuDate(){
        if (this.responseService.isDialogOpen === false) {
            this.responseService.isDialogOpen = true;
            this.responseService.reportValues.setDisplay('dashboard');
            this.dialog.open(FilterDashboardComponent, {data: {report: this.responseService.reportValues.getAttributes()}, width: '55%', height: '100%'}).beforeClosed().subscribe(() => {
                this.responseService.isDialogOpen = false;
            });
        }
    }


    handleSearch(){
        setTimeout((outgoingRequest: any = {}) => {
            if (!this.service.isNull(this.searchCntrl)){
                this.responseService.reportValues.setDisplay('dashboard');
                outgoingRequest = Object.assign(outgoingRequest, this.responseService.reportValues.attributes);
                outgoingRequest.searchText = this.searchCntrl;
                delete outgoingRequest.data;
                this.service.httpService('post', '/forms/xx/responses/searchrequest', outgoingRequest, {}, (result: any) => {
                    this.responses = Object.assign(this.responses, {data: result});
                }, (error: any) => {
                });
            }
        });
        // if(this.responseService.isDialogOpen === false) {
        //   this.responseService.isDialogOpen = true;
        //   setTimeout((outgoingRequest: any = {}) => {
        //     if(!this.service.empty(this.searchCntrl)){
        //       this.responseService.reportValues.setDisplay('dashboard');
        //       outgoingRequest = Object.assign(outgoingRequest, this.responseService.reportValues.attributes);
        //       outgoingRequest.searchText = this.searchCntrl;
        //       delete outgoingRequest.data;
        //       this.service.httpService('post', '/forms/xx/responses/searchrequest', outgoingRequest, {}, (response: any) => {
        //         this.responses.data = response;
        //         this.responseService.isDialogOpen = false;}, (error: any) => {
        //         this.responseService.isDialogOpen = false;
        //       });
        //     }
        //   });
        // }
    }




    onContextMenu(event: MouseEvent, inspector: any) {
        event.preventDefault();
        this.contextMenuPosition.x = event.clientX + 'px';
        this.contextMenuPosition.y = event.clientY + 'px';
        this.contextMenu.menuData = { item: inspector };
        this.contextMenu.menu.focusFirstItem('mouse');
        this.contextMenu.openMenu();
    }

    handleDestroy(response: any){
        this.incomingResponseSelected.setAttributes(this.responseSelected);
        this.dialog.open(DeleteDialogComponent, {
            data   : {
                data : this.responses,
                model: this.incomingResponseSelected.getAttributes(),
                title: this.incomingResponseSelected.getTitle(),
                url  : this.service.app.routes.responses.delete.toString().replaceAll('xx', this.incomingResponseSelected.getId()),
                key  : 'id',
                id   : this.incomingResponseSelected.getId(),
            },
            width : 'auto',
        });
    }

    getCampaignByForm(form: any){
        let campaign: any = this.service.only(this.app.data.campaigns.data.filter((campaign: any) => campaign.id === form.campaign_id)[0]);
        const client: any = this.service.only(this.getClientByCampaign(campaign));
        campaign = Object.assign(campaign, {client});
        form = Object.assign(form, {campaign});
        return campaign;
    }


    getClientByCampaign(campaign: any){
        return this.app.data.clients.data.filter((client: any) => client.id === campaign.client_id)[0];
    }

    handleUpdate(post: any){
        this.incomingForm = new Parameters();
        this.incomingForm.setAttributes(this.form);
        this.incomingForm.setCampaign(this.incomingCampaign.getAttributes());
        this.formService.setSelected(this.incomingForm.getAttributes());
        this.incomingForm.getControls().map((question: any) => {
            this.incomingQuestion.setAttributes( question);
            this.responseSelected.datas.map((resp: any) => {
                if (resp.formControl.cntrl_num.toString() === this.incomingQuestion.getCntrlNum().toString()){
                    this.incomingQuestion.setData(resp.data);
                    this.incomingQuestion.setResult(resp.result);
                }
            });
        });
        this.incomingPost.setAttributes(this.responseSelected);
        this.incomingPost.setForm(this.incomingForm.getAttributes());
        this.responseService.setSelected(this.incomingPost.getAttributes());
        this.responseService.responseSelectedCntrlNum = this.incomingPost.getPostId();
        this.formService.formSelectedId = this.incomingForm.getId();
        this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.incomingForm.attributes.id) + '/respond');
    }

    handleRestore(inspector: any){
        this.incomingForm.setAttributes(this.form);
        this.service.restore('/forms/' + parseFloat(this.incomingForm.getId()) + '/restore',
            this.responses, 'id', parseFloat(this.incomingForm.getId()), this.form);
    }



    handleResponses(form: any){
        this.subscription.unsubscribe();
        this.incomingForm.setAttributes(this.form);
        this.incomingForm.getControls().map((question: any) => {
            this.incomingQuestion.setAttributes(question);
            this.tableService.setQuestion(this.incomingQuestion.getAttributes());
        }); this.responseService.setSelected(this.form);
        this.router.navigateByUrl('/app/forms/' + this.form.id + '/responses');
    }

    openDialog(response: any){
        this.responseService.setSelected(this.incomingForm.getAttributes());
        // this.inspectorsComponent.openDialog();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.responseService.yScrollingPart = window.pageYOffset;
        this.responseService.xScrollingPart = window.pageXOffset;
        if (this.responseService.reportValues.attributes.hasOwnProperty('descending_last_at')) {
            delete this.responseService.reportValues.attributes.descending_last_at;
        }
    }


}
