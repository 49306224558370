import {AfterContentInit, AfterViewInit, Component, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DashboardService} from '../../../dashboard/dashboard.service';
import {MatDialog} from '@angular/material/dialog';
import {FormService} from '../../form.service';
import {CampaignService} from '../../../campaigns/campaign.service';
import {RegionsService} from '../../../regionals/regions.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ResponseService} from '../../responses/data/response.service';
import {AppService} from '../../../app.service';
import {BroadcastChannelService} from '../../../broadcast-channel.service';
import {Parameters} from '../../../parameters';
import {DialogOverallComponent} from '../../../dashboard/dialog-overall.component';
import {FilterDashboardComponent} from '../../../dashboard/filter-dashboard.component';
import * as $ from 'jquery';
import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import {MatMenuTrigger} from "@angular/material/menu";
import {FormDashboardService} from "../form-dashboard.service";
import {ClientService} from "../../../clients/client.service";
import {orderBy } from 'lodash';
import {SettingsCriteriasDialogComponent} from "../../../criterias/settings/settings-criterias-dialog.component";
import {SettingsFormComponent} from "../../../settings/settings-form.component";
import {interval} from "rxjs";
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import {MatDrawer} from "@angular/material/sidenav";
import {CriteriasService} from "../../../criterias/criterias.service";

@Component({
  selector: 'app-dashboard2-form',
  templateUrl: './dashboard2-form.component.html',
  styleUrls: ['./dashboard2-form.component.scss']
})
export class Dashboard2FormComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentInit {
  @ViewChild('drawer', {static: false})  drawer: any;
  @Input() formId: any;
  @ViewChild(MatMenuTrigger) contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  subscription: any;
  report: any;
  incomingForm: any;
  incomingReport: any;
  ambassadors: any;
  startingAt: any;
  kpi: any = 0;
  progress: any;
  responses: any = 0;
  teams: any = [];
  position: any;
  team: any;
  markers: any = [];
  app: any;
  lat: any = -6.8643365;
  lng: any = 39.2712821;
  zoom: any = 0;
  updateFlag = false;
  Highcharts: typeof Highcharts = Highcharts;
  questions: any;
  target: any;
  incomingCampaign: any;
  strings: any;
  incomingOption: any;
  incomingItem: any;
  incomingQuestion: any;
  searchCntrl: any;
  navigation: any;
  icon: any;
  incomingTeam: any;
  navOverall: any = 0;
  teamSelectedMatTab: any;
  teamNavSelectedMatTab: any = 0;
  navUser: any = 0;
  incomingClient: any;
  navCurrencies: any = null;
  navOutlets: any = 0;
  products: any = [];
  navProducts: any = null;
  incomingSelectedQuestion: any;
  navTime: any = 4;
  charts: any = [];
  outletSectors: any = [];
  navTop5: any = 4;
  navRecent: any = 1;
  incomingProducts: any;
  navAddress: any = 1;
  navRegion: any = 0;
  navRegionSide: any = 2;
  durations: any = [];
  appsettingsUrl: any = '../assets/json/';
  navFunc: any = 1;
  func: any = [];
  incomingSelectedRegion: any;
  incomingSelectedUser: any;
  outgoingCriteria: any;
  navTeam: any = 0;
  incomingSelectedTeam: any;
  customOptions: any = {};
  incomingSingleFormReport: any;
  incomingDurations: any;
  incomingDuration: any;
  incomingSections: any;
  incomingSection: any;
  allSections: any;
  incomingResponse: any;
  incomingSelectedItem: any;
  incomingOutletSector: any;
  incomingItm: any;
  hiddenFlag: any = 0;
  navNewCustomers: any =null;
  incomingMarker: any;
  outgoingRequest: any;
  incomingFeedback: any;
  spinner: any = true;
  incomingFormDurations: any;
  incomingSectionDurations: any;
  incomingCustomReport: any;
  incomingSelectedCustomReport: any;
  legends: any = [];
  values: any = [];
  matMenuStatus: any = 0;
  incomingItemExtract: any;
  incomingItemExtractFiltered: any;
  items: any = [];
  result: any;
  incomingFormSettings: any;
  exportExcel: any = false;
  navSell: any = null;
  opened: any = false;
  flag: any;
  attributes: any;
  incomingRegion: any;
  showFlag: any = true;
  teamIncremental: any = 0;
  mFlag: any = 1;
  incomingUser: any;
  incomingDistrict: any;
  users: any = [];
  navInventory: any =null;
  userNavFunc: any =1;
  extend: any = false;
  userNav: any = 0;
  incomingUserSelectedQuestion: any;
  scrollingPart: any = 0;
  scrollingStarting: any = 0;
  scrollingEnding: any = 0;
  mainNav: any = 5;
  incomingTeamSelectedQuestion: any;
  userNavRecent: any = 1;
  userNavTop5: any = 1;
  incomingDistrictSelected: any;
  incomingCriteriaSelected: any;
  incomingCriteria: any;
  outgoingCriteriaSelected: any;
  isCriteriaSearching: any = true;
  constructor(public dashboardService: DashboardService,
              private dialog: MatDialog,
              private formService: FormService,
              private campaignService: CampaignService,
              public zone: NgZone,
              public formDashboardService: FormDashboardService,
              private clientService: ClientService,
              private criteriaService: CriteriasService,
              private regionService: RegionsService,
              public router: Router,
              public activateRouter: ActivatedRoute,
              public responseService: ResponseService,
              public service: AppService,
              public broadcastChannel: BroadcastChannelService) {
    this.contextMenu = MatMenuTrigger;
    this.incomingCampaign = new Parameters();
    this.strings = new Parameters();
    this.attributes = new Parameters();
    this.incomingCriteria = new Parameters();
    this.incomingReport = new Parameters();
    this.outgoingCriteria = new Parameters();
    this.outgoingCriteriaSelected = new Parameters();
    this.incomingOption = new Parameters();
    this.incomingItem   = new Parameters();
    this.incomingClient = new Parameters();
    this.incomingCriteriaSelected  = new Parameters();
    this.incomingDistrict = new Parameters();
    this.incomingDistrictSelected = new Parameters();
    this.incomingQuestion = new Parameters();
    this.incomingUser = new Parameters();
    this.incomingProducts = new Parameters();
    this.incomingOutletSector = new Parameters();
    this.incomingRegion = new Parameters();
    this.incomingTeam = new Parameters();
    this.incomingCustomReport = new Parameters();
    this.incomingUserSelectedQuestion = new Parameters();
    this.incomingSelectedQuestion = new Parameters();
    this.incomingSelectedCustomReport = new Parameters();
    this.incomingSectionDurations = new Parameters();
    this.incomingFormDurations = new Parameters();
    this.incomingSelectedRegion = new Parameters();
    this.incomingSelectedUser = new Parameters();
    this.incomingSelectedTeam = new Parameters();
    this.incomingSingleFormReport = new Parameters();
    this.incomingTeamSelectedQuestion = new Parameters();
    this.incomingItemExtract = new Parameters();
    this.incomingItemExtractFiltered = new Parameters();
    this.incomingSections = new Parameters();
    this.incomingSection = new Parameters();
    this.incomingDurations = new Parameters();
    this.incomingDuration = new Parameters();
    this.incomingResponse = new Parameters();
    this.allSections = new Parameters();
    this.incomingSelectedItem = new Parameters();
    this.incomingItm = new Parameters();
    this.incomingMarker = new Parameters();
    this.outgoingRequest = new Parameters();
    this.incomingFeedback = new Parameters();
    this.incomingFormSettings = new Parameters();
    this.drawer = MatDrawer;
    this.incomingReport.setAttributes({});
    this.service.module = 'form.view';
    this.incomingCriteriaSelected.setAttributes({});
    this.incomingDistrictSelected.setAttributes({});
    this.incomingSelectedQuestion.setAttributes({});
    this.incomingTeamSelectedQuestion.setAttributes({});
    this.incomingSectionDurations.setAttributes({});
    this.navigation = this.router.getCurrentNavigation();
    this.formId = this.activateRouter.snapshot.params.id;
    this.formId = this.service.decrypt(this.formId);
    this.formService.formSelectedId = this.formId;
    this.incomingSelectedRegion.setAttributes({});
    this.incomingSelectedUser.setAttributes({});
    this.incomingSelectedTeam.setAttributes({});
    this.incomingDurations.setAttributes({});
    this.incomingDuration.setAttributes({});
    this.incomingSections.setAttributes({});
    this.incomingSection.setAttributes({});
    this.incomingRegion.setAttributes({});
    this.incomingUser.setAttributes({});
    this.incomingUserSelectedQuestion.setAttributes({});
    this.strings.setAttributes(this.service.app.strings.params);
  }

  ngOnInit(): void {
    this.startingAt = new Date();
    this.attributes.setAttributes(this.service.app.data);
    this.allSections.setAttributes({});
    this.allSections.setAll(false);
    this.allSections.setImages(false);
    this.regionService.requestRegionsByCountryId(1);
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          if (item instanceof Object){
            if (item.hasOwnProperty(this.strings.getSearchText()) ) {
              this.searchCntrl = item.searchText;
            }else if (item.hasOwnProperty(this.strings.getForm())){
              this.dashboardService.incomingSelectedForm.setAttributes(item.form);
              this.formService.setSelected(item.form);
              this.dashboardService.incomingReport.setFormId(item.form.id);
              this.handleDashboard('starter');
            }else if(item.hasOwnProperty('_criteria')) {
              this.incomingCriteria.setAttributes(item._criteria);
              this.incomingFormSettings.getCriterias().map((criteria: any) => {
                this.outgoingCriteria.setAttributes(criteria);
                if(this.incomingCriteria.getCntrlNum().includes(this.outgoingCriteria.getCntrlNum())){
                  this.outgoingCriteria.attributes = Object.assign(this.outgoingCriteria.attributes,
                      this.incomingCriteria.getAttributes());
                  this.outgoingRequest.setAttributes(Object.assign({}, this.incomingForm.getAttributes()));
                  this.outgoingRequest.setSettings(JSON.stringify(this.incomingFormSettings.getAttributes()));
                  this.formService.updateSettings(this.outgoingRequest, (result: any) => {});
                }
              });
            }
          } else if (item === 'openDateMenu') {
            this.openMenuDate();
          } else if (item === 'report'){
            if(this.flag === 3){
              this.requestOutletEngament();
            }if(this.flag === 4){
              this.requestDateRangeReport();
            }if(this.flag === parseFloat('5')){
              this.requestAmbassadorsFinalReport();
            } else{
              if(!this.exportExcel){
                this.incomingForm.setAttributes(this.formService.getFormById(this.formId));
                this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
                this.incomingReport.setDuration('custom');
                this.incomingReport.setSection('all');
                this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), {});
                this.handleDashboard('starter');
              }
            }


          }
        });
    this.icon = {url: 'https://patten.co.tz/lat.png', scaledSize: {height: 20, width: 20}};
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#f5f5f5';
    this.incomingForm = new Parameters();
    this.incomingReport = new Parameters();
    this.incomingForm.setAttributes(this.formService.getFormById(this.formId));
    this.getInvitees(()=> {});
    this.incomingForm.setResponseDisplayTitle(this.service.isNull(this.incomingForm.getResponseDisplayTitle()) ? 'Responses' : this.incomingForm.getResponseDisplayTitle());
    this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
    this.incomingFormSettings.setResponsesCounter(this.incomingFormSettings.attributes.hasOwnProperty('responses_counter') ? this.incomingFormSettings.getResponsesCounter() : 1);
    this.incomingFormSettings.setTarget(this.incomingFormSettings.attributes.hasOwnProperty('target') ? this.incomingFormSettings.getTarget() : '');
    this.incomingFormSettings.setOutlet(this.incomingFormSettings.attributes.hasOwnProperty('outlet') ? this.incomingFormSettings.getOutlet() : '');
    this.incomingFormSettings.setIsOutlet(this.incomingFormSettings.attributes.hasOwnProperty('is_outlet') ? this.incomingFormSettings.getIsOutlet() : 0);
    this.incomingFormSettings.setStartingAt(this.incomingFormSettings.attributes.hasOwnProperty(this.strings.getStartingAt()) ? this.incomingFormSettings.getStartingAt() : '');
    this.incomingCampaign.setAttributes(this.campaignService.getCampaignById(this.incomingForm.getCampaignId()));
    this.incomingClient.setAttributes(this.clientService.getClientById(this.incomingCampaign.getClientId()));
    this.incomingReport.setAttributes(this.dashboardService.incomingReport.getAttributes());
    this.startingAt = new Date(this.incomingReport.getStartingAt());
    this.incomingProducts.setAttributes(this.service.getJSObjectByArrayAndLabel(this.incomingForm.getProducts(), this.strings.getCntrlNum()));
    this.incomingSingleFormReport.setAttributes(this.formDashboardService.incomingForms.attributes.hasOwnProperty(this.incomingForm.getId()) ?
        this.formDashboardService.incomingForms.getUnknown(this.incomingForm.getId()) : {});
    this.report = this.incomingReport.attributes.data;
    this.ambassadors = [];
    this.responses = 0;
    this.progress = 0;
    this.app = this.service.app;
    if (Object.keys(this.incomingSingleFormReport.getAttributes()).length === 0) {
      this.incomingReport.setStartingAt(this.service.isNull(this.incomingFormSettings.getStartingAt()) ? this.incomingReport.getStartingAt() : this.incomingFormSettings.getStartingAt());
      this.handleDashboard('starter');
    } else this.dashbaord();
    setTimeout(() => {
      document.title = this.incomingCampaign.attributes.name;
      this.service.header = this.incomingCampaign.getName().toUpperCase() + ' ' + this.service.setDisplayedDate(this.dashboardService.incomingReport) + ' ,dashboard';
      this.zoom = 10;
      if(this.formDashboardService.dialogStatus){
        this.formDashboardService.dialogStatus = false;
          const echo: any = new Echo({
            broadcaster : this.strings.getBroadcasterDriver(),
            key         : this.strings.getWebsocketKey(),
            wsHost      : this.strings.getWsHost(),
            wsPort      : parseFloat(this.strings.getWsPort()),
            disableStats: false,
          });
          echo.channel(
              this.strings.getWebsocketChannelResponse()).listen('.'+
              this.strings.getWebsocketEventResponse(), (e: any ) => {
                this.zone.run(() => {
                  let incomingResult: any = new Parameters();
                  incomingResult.setAttributes(JSON.parse(e[0]));
                  if(parseFloat(this.incomingForm.getId())===parseFloat(incomingResult.getFormId())) {
                    if (this.incomingDuration.getAttributes().hasOwnProperty(this.strings.getCriterias())) {
                      this.incomingDuration.getUnknown(this.strings.getCriterias()).map((criteria: any) => {
                        let incomingCriteria: any = new Parameters();
                        incomingCriteria.setAttributes(criteria);
                        incomingCriteria.setStatus(0);
                      });
                      this.handleReportByCriterias_();
                    }
                  }
                });
          });
          window.addEventListener('click',(e: any) => {
            this.zone.run((e: any) => {
              // this.drawer.toggle();
            });
          });
      }
    }, 1000);
  }

  onDblClickCriteria(criteria: any){
    let incomingCriteria: any = new Parameters();
    incomingCriteria.setAttributes(criteria);
    this.openCriterialabelPrompt(incomingCriteria.getAttributes());
  }


  add(){
    this.responseService.setSelected({});
    this.responseService.openDialog();
  }

  onSelectForm(form: any){
    let incomingForm: any = new Parameters();
    incomingForm.setAttributes(form);
    var promptText: any = prompt('', incomingForm.getName());
    if (!this.service.isNull(promptText)) {
      incomingForm.setName(promptText);
      this.formService.update(incomingForm, (output: any) => {
        if(output !== null){
        }
      });
    }
  }

  getResponsesByCriterialsAndSearchText(criterials: any, searchText: any){
    let args: any = searchText.toLowerCase();
    return criterials.filter((criteria: any) => {return JSON.stringify(criteria).toLowerCase().includes(args);});
  }
  getResponses(){
    this.router.navigateByUrl('/us/app/' +
        this.strings.getForms() +
        this.strings.getSlash() +
        this.service.encrypt(this.incomingForm.getId()) +
        this.strings.getSlash() + this.strings.getResponses());
  }

  // openDrawer(){setTimeout(() => {this.drawer.toggle();},this.service.timeout());}

  onSelectCriteria(criteria: any){
    this.criteriaService.isDialogOpen = false;
    this.criteriaService.setSelected(criteria);
    setTimeout(() => {
      this.criteriaService.openDialoglist();
    });
 // // setTimeout(() => {this.drawer.toggle();},this.service.timeout());
 //    this.isCriteriaSearching = true;
 //    let incomingCriteriaSelected: any = new Parameters();
    // incomingCriteriaSelected.setAttributes(criteria);
 //    let outgoingRequest: any = new Parameters();
 //    outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
 //    outgoingRequest.setCntrlNum(incomingCriteriaSelected.getCntrlNum());
 //    outgoingRequest.setSection(this.strings.getCriterias());
 //    outgoingRequest.setFlag(this.strings.getResponses());
 //    delete outgoingRequest.attributes.data;
 //    if(incomingCriteriaSelected.getUnknown(outgoingRequest.getDuration())[this.strings.getData()].length !== 0)
 //      outgoingRequest.setAscendinglastAt(incomingCriteriaSelected.getUnknown(
 //          outgoingRequest.getDuration())[this.strings.getData()]
 //          [incomingCriteriaSelected.getUnknown(outgoingRequest.getDuration())
 //          [this.strings.getData()].length - 1][this.strings.getId()]);
 //    this.service.httpService('POST', this.service.app.routes.reports.fetch,
 //        outgoingRequest.getAttributes(),
 //        {ignoreLoadingBar: 'true', notify: false},
 //        (output: any) => {
 //      let incomingOutputResult: any = new Parameters();
 //      incomingOutputResult.setAttributes(output);
 //      if(parseFloat(incomingOutputResult.getFormId()) !== parseFloat(this.incomingForm.getId())) return;
 //      if(incomingOutputResult.getSection()  !== this.strings.getCriterias()) return;
 //      if(incomingOutputResult.getDuration() !== outgoingRequest.getDuration()) return;
 //      if(incomingOutputResult.hasAttribute(this.strings.getCriterias())){
 //        let incomingCriteria: any = new Parameters();
 //        incomingCriteria.setAttributes(Object.assign({}, incomingOutputResult.getCriterias().filter((item: any) => {
 //          return parseFloat(item[this.strings.getCntrlNum()]) === parseFloat(incomingOutputResult.getCntrlNum());})[0]));
 //        incomingCriteria.getUnknown(outgoingRequest.getDuration())[this.strings.getData()].map((response: any) => {
 //          let incomingResponse: any = new Parameters();
 //          incomingResponse.setAttributes(response);
 //          incomingCriteriaSelected.getUnknown(outgoingRequest.getDuration())[this.strings.getData()].push(incomingResponse.getAttributes());
 //        });if(incomingCriteria.getUnknown(outgoingRequest.getDuration())[this.strings.getData()].length !== 0)
 //          this.onSelectCriteria(incomingCriteriaSelected.getAttributes());
 //        else this.isCriteriaSearching = false;
 //      }
 //    },(error: any) => {
 //      setTimeout(() => {this.onSelectCriteria(incomingCriteriaSelected.getAttributes());});
 //    });
  }


  getQuestionsByProductsAndQuestionId(products: any, questionId: any){
   return this.result = products.map((product: any) => {
    this.incomingItem.setAttributes(Object.assign({}, product));
    this.incomingQuestion.setAttributes(Object.assign({}, this.incomingItem.getUnknown(this.incomingReport.getDuration()).questions.filter((question: any) => {return parseFloat(question.id) === parseFloat(questionId);})[0]));
    return this.incomingQuestion.attributes.sum;
   });
  }

  scrollUp(){
    window.scrollTo(0, pageYOffset < this.scrollingPart ? 0 : this.scrollingPart);
  }

  openDateMenu(){ this.broadcastChannel.emitNavChangeEvent('openDateMenu');}
  setSelectedTeam(team: any){this.incomingSelectedTeam.setAttributes(Object.assign({}, team));}
  getNav(){ return ["navOverall","navSell","navCurrency"];}
  getSumByRegionsAndQuestionIdAndPeriod(regions: any, questionId: any,time: any){return regions.map((region: any)=>{return parseFloat(region.questions.filter((question: any) =>{return parseFloat(question.id) === parseFloat(questionId);})[0][time].currency);});}
  getSumByUsersAndQuestionIdAndPeriodAndColumn(users: any, questionId: any,time: any,column: any){return users.map((user: any)=>{return {name: user.name, result: parseFloat(user.questions.filter((question: any) =>{return parseFloat(question.id) === parseFloat(questionId);})[0][time][column])};});}
  getSumByItemsAndColumnItemsAndItemIdAndPeriodAndColumn(items: any, columnItems: any,itemId: any,time: any,column: any){return items.map((item: any)=>{return {name: item.name, result: parseFloat(item[columnItems].filter((item: any) =>{return parseFloat(item.id) === parseFloat(itemId);})[0][time][column])};});}


  getQuestions(questions: any, time: any){
    return questions.filter((question: any) => {
      this.incomingQuestion.setAttributes(question);
      return (
          parseFloat(this.incomingQuestion.getControlId()) === 26 ||
          parseFloat(this.incomingQuestion.getControlId()) === 30 ||
          parseFloat(this.incomingQuestion.getControlId()) === 31);
    });
  }

  requestAmbassadorsFinalReport(){
    this.outgoingRequest.setType('ambassadors.final.report');
    this.service.httpService('post', this.service.app.routes.reports.fetch, this.outgoingRequest.getAttributes(), {}, (response: any) => {
      this.service.responses = response;
      this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.incomingForm.getId()) + '/ambassadors/final/report');
    }, (error: any) => {
    });
  }

  getSum(items: any){
   this.result = 0;
   items.map((item: any)=>{this.result += parseFloat(item);});
   return this.result;
  }

  onSelectStartingAt(event: any){
      if(!this.startingAt.toString().includes(moment(event).format('MM/DD/YYYY'))){
        this.startingAt = event;
        this.incomingReport.setStartingAt(moment(event).format('MM/DD/YYYY'));
        this.incomingReport.setEndingAt(moment(event).format('MM/DD/YYYY'));
        this.incomingReport.setDuration('custom');
        this.incomingReport.setSection('all');
        this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), {});
        this.handleDashboard('starter');
      }
  }

  getInvitees(callback: any){
   if(this.incomingForm.getInvitees().data.length ===  0){
    this.outgoingRequest.setAttributes({});
    this.outgoingRequest.setFormId(this.incomingForm.getId());
    this.service.httpService('post', this.service.app.routes.invitees.get, this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (result: any) => {
       this.incomingForm.getInvitees().data = Object.assign(this.incomingForm.getInvitees().data,result);
       this.service.app.data.invitees.data = Object.assign(this.service.app.data.invitees.data,result);
       callback(null);
       },(error: any) => {
       callback(null);
       });
   }else callback(null);
  }

  handleReportByUsers(){
    this.mFlag = true;
    this.getInvitees((result: any)=>{
         this.users = this.incomingForm.getInvitees().data;
         this.users.map((user: any) => {
           if(this.mFlag === true){
             this.incomingUser.setAttributes(Object.assign({}, user));
             this.position = this.service.findIndex(
                 this.incomingDuration.getUnknown(this.incomingReport.getSection()), 'id',
                 this.incomingUser.getId());
             if(this.position === -1){
               this.outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
               this.outgoingRequest.setId(this.incomingUser.getId());
               delete this.outgoingRequest.attributes.data;
               this.service.httpService('post', this.service.app.routes.reports.fetch, this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
                 this.incomingResponse.setAttributes(response);
                 if(parseFloat(this.incomingResponse.getFormId()) !== parseFloat(this.incomingForm.getId())) return;
                 if(this.incomingResponse.getSection()  !== this.incomingReport.getSection()) return;
                 if(this.incomingResponse.getDuration() !== this.incomingReport.getDuration()) return;
                 if(this.incomingResponse.hasAttribute('users')){
                   this.incomingItem.setAttributes(Object.assign({}, this.incomingResponse.getUsers().filter((item: any) => {return parseFloat(item.id) === parseFloat(this.incomingResponse.getId());})[0]));
                   this.incomingDuration.getUnknown(this.incomingReport.getSection()).push(this.incomingItem.getAttributes());
                   this.incomingSelectedUser.setAttributes(
                       Object.keys(this.incomingSelectedUser.getAttributes()).length === 0 ?
                       this.incomingItem.getAttributes() :
                       this.incomingSelectedUser.getAttributes());
                   this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
                   this.incomingSingleFormReport.setDurations(
                       this.incomingDurations.getAttributes());
                   this.formDashboardService.incomingForms.setUnknown(
                       this.incomingForm.getId(),
                       this.incomingSingleFormReport.getAttributes());
                   this.incomingReport.setData(this.incomingDuration.getAttributes());
                   this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
                   this.handleReportByUsers();
                 }
               },(error: any) => {
                this.handleReportByUsers();
               }); this.mFlag = false;
             }
           }
         });
         if(this.incomingReport.attributes.data.users.length === this.users.length) {
           this.requestSection();
         }
    });
  }


  extract(items: any, t: any, column: any){
    this.items = [];
    this.items = items.map((item: any) => {
      this.incomingItemExtract.setAttributes(Object.assign({}, item));
      this.incomingItemExtractFiltered.setAttributes({});
      this.incomingItemExtractFiltered.setAttributes(Object.assign(this.incomingItemExtractFiltered.getAttributes(), this.incomingItemExtract.getAttributes()));
      this.incomingItemExtractFiltered.setUnknown(column, parseFloat(this.incomingItemExtract.getUnknown(t)[column]));
      return this.incomingItemExtractFiltered.getAttributes();
    });return this.items;
  }

  extractAndDesc(items: any, t: any, column: any){
      this.items = [];
      this.items = items.map((item: any,position: any) => {
        this.incomingItemExtract.setAttributes(Object.assign({}, item));
        this.incomingItemExtractFiltered.setAttributes({});
        this.incomingItemExtractFiltered.setAttributes(Object.assign(this.incomingItemExtractFiltered.getAttributes(), this.incomingItemExtract.getAttributes()));
        this.incomingItemExtractFiltered.setUnknown(column, parseFloat(this.incomingItemExtract.getUnknown(t)[column]));
        this.incomingItemExtractFiltered.setPosition(position);
        return this.incomingItemExtractFiltered.getAttributes();
      });return orderBy(this.items,column, 'desc');
  }

  handleReportByMarkers(){
    if(parseFloat(this.incomingResponse.getFormId()) !== parseFloat(this.incomingForm.getId())) return;
    if(this.incomingResponse.getSection()  !== this.incomingReport.getSection()) return;
    if(this.incomingResponse.getDuration() !== this.incomingReport.getDuration()) return;
  }



  handleReportByCriterias(){
       this.mFlag = true;
      this.service.orderBy(this.incomingFormSettings.getCriterias(),'asc','index').map((criteria: any) => {
        if(this.mFlag === true){
          this.incomingCriteria.setAttributes(Object.assign({}, criteria));
          this.position = this.service.findIndex(
              this.incomingDuration.getUnknown(this.incomingReport.getSection()), 'cntrl_num',
              this.incomingCriteria.getCntrlNum());
          if(this.position === -1){
            this.outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
            this.outgoingRequest.setCntrlNum(this.incomingCriteria.getCntrlNum());
            delete this.outgoingRequest.attributes.data;
            this.service.httpService('post', this.service.app.routes.reports.fetch, this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
              this.incomingResponse.setAttributes(response);
              if(parseFloat(this.incomingResponse.getFormId()) !== parseFloat(this.incomingForm.getId())) return;
              if(this.incomingResponse.getSection()  !== this.incomingReport.getSection()) return;
              if(this.incomingResponse.getDuration() !== this.incomingReport.getDuration()) return;
              if(this.incomingResponse.hasAttribute(this.strings.getCriterias())){
                let incomingCriteria: any = new Parameters();
                incomingCriteria.setAttributes(Object.assign({}, this.incomingResponse.getCriterias().filter((item: any) => {
                 return parseFloat(item.cntrl_num) === parseFloat(this.incomingResponse.getCntrlNum());})[0]));
                incomingCriteria.setDefaultContainerWidth(
                    incomingCriteria.getAttributes().hasOwnProperty(this.strings.getDefaultContainerWidth()) ?
                        incomingCriteria.getDefaultContainerWidth() : 3);
                incomingCriteria.setStatus(1);
                incomingCriteria.attributes[this.incomingReport.getDuration()] =
                    Object.assign(incomingCriteria.attributes[this.incomingReport.getDuration()], {data : []});
                this.incomingDuration.getUnknown(this.incomingReport.getSection()).push(incomingCriteria.getAttributes());
                this.incomingCriteriaSelected.setAttributes(Object.keys(this.incomingCriteriaSelected.getAttributes()).length === 0 ?
                    incomingCriteria.getAttributes() : this.incomingCriteriaSelected.getAttributes());
                this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
                this.incomingSingleFormReport.setDurations(
                    this.incomingDurations.getAttributes());
                this.formDashboardService.incomingForms.setUnknown(
                    this.incomingForm.getId(),
                    this.incomingSingleFormReport.getAttributes());
                this.incomingReport.setData(this.incomingDuration.getAttributes());
                this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
                this.handleReportByCriterias();
              }
            },(error: any) => {
             this.handleReportByCriterias();
            }); this.mFlag = false;
          }
        }
      });
      if(this.incomingFormSettings.hasAttribute(this.strings.getCriterias()) &&
          this.incomingReport.attributes.data.criterias.length === this.incomingFormSettings.getCriterias().length) {
        this.requestSection();
      }
  }

  handleReportByOutetSectors(){
      this.mFlag = true;
      this.outletSectors = this.service.app.data.outlets.types;
      this.outletSectors.map((outletSector: any) => {
        if(this.mFlag === true){
          this.incomingOutletSector.setAttributes(Object.assign({}, outletSector));
          this.position = this.service.findIndex(
              this.incomingDuration.getUnknown(this.incomingReport.getSection()), 'id',
              this.incomingOutletSector.getId());
          if(this.position === -1){
            this.outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
            this.outgoingRequest.setId(this.incomingOutletSector.getId());
            delete this.outgoingRequest.attributes.data;
            this.service.httpService('post', this.service.app.routes.reports.fetch, this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
              this.incomingResponse.setAttributes(response);
              if(parseFloat(this.incomingResponse.getFormId()) !== parseFloat(this.incomingForm.getId())) return;
              if(this.incomingResponse.getSection()  !== this.incomingReport.getSection()) return;
              if(this.incomingResponse.getDuration() !== this.incomingReport.getDuration()) return;
              if(this.incomingResponse.hasAttribute('outletSectors')){
                this.incomingItem.setAttributes(Object.assign({}, this.incomingResponse.getOutletSectors().filter((item: any) => {return parseFloat(item.id) === parseFloat(this.incomingResponse.getId());})[0]));
                this.incomingDuration.getUnknown(this.incomingReport.getSection()).push(this.incomingItem.getAttributes());
                this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
                this.incomingSingleFormReport.setDurations(
                    this.incomingDurations.getAttributes());
                this.formDashboardService.incomingForms.setUnknown(
                    this.incomingForm.getId(),
                    this.incomingSingleFormReport.getAttributes());
                this.incomingReport.setData(this.incomingDuration.getAttributes());
                this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
                this.handleReportByOutetSectors();
              }
            },(error: any) => { this.handleReportByOutetSectors(); }); this.mFlag = false;
          }
        }
      });
      if(this.incomingReport.attributes.data.outletSectors.length === this.outletSectors.length) {
        this.requestSection();
      }
  }


  handleReportByCriterias_(){
    let incremental: any = true;
    this.service.orderBy(this.incomingFormSettings.getCriterias(),
        this.service.app.strings.params.asc,
        this.strings.getIndex()).map((criteria: any) => {
      if(incremental){
        this.incomingCriteria.setAttributes(Object.assign({}, criteria));
        let position: any = this.service.findIndex(
            this.incomingDuration.getUnknown(this.strings.getCriterias()),
            this.service.app.strings.params.cntrlNum,
            this.incomingCriteria.getCntrlNum());
        if(position !== -1){
          let incomingItem: any = new Parameters();
          incomingItem.setAttributes(this.incomingDuration.getUnknown(this.strings.getCriterias())[position]);
          if(incomingItem.getAttributes().hasOwnProperty(this.strings.getStatus())){
            if(parseFloat(incomingItem.getStatus()) === 0){
              let outgoingRequest: any = new Parameters();
              outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
              outgoingRequest.setCntrlNum(this.incomingCriteria.getCntrlNum());
              outgoingRequest.setSection(this.strings.getCriterias());
              delete outgoingRequest.attributes.data;
              incremental = false;
              this.service.httpService('post', this.service.app.routes.app.reports.get,
                  outgoingRequest.getAttributes(), {
                ignoreLoadingBar: 'true', notify: false}, (response: any) => {
                let incomingResponse: any = new Parameters();
                incomingResponse.setAttributes(response);
                if(parseFloat(incomingResponse.getFormId()) !== parseFloat(this.incomingForm.getId())) return;
                if(incomingResponse.getSection()  !== this.strings.getCriterias()) return;
                if(incomingResponse.getDuration() !== this.incomingReport.getDuration()) return;
                if(incomingResponse.hasAttribute('criterias')){
                  incomingItem.setAttributes(Object.assign({}, incomingResponse.getCriterias().filter((item: any) => {
                    return parseFloat(item.cntrl_num) === parseFloat(incomingResponse.getCntrlNum());})[0]));
                  this.incomingDuration.getUnknown(this.strings.getCriterias()).map((section: any) => {
                    let incomingSection: any = new Parameters();
                    incomingSection.setAttributes(section);
                    if(incomingSection.getCntrlNum().includes(incomingItem.getCntrlNum())){
                      this.pState();
                      incomingItem.setStatus(1);
                      incomingSection.setAttributes(Object.assign(incomingSection.getAttributes(), incomingItem.getAttributes()));
                    }
                  });
                  this.incomingDurations.setUnknown(this.incomingReport.getDuration(),
                      this.incomingDuration.getAttributes());
                  this.incomingSingleFormReport.setDurations(
                      this.incomingDurations.getAttributes());
                  this.formDashboardService.incomingForms.setUnknown(
                      this.incomingForm.getId(),
                      this.incomingSingleFormReport.getAttributes());
                  this.incomingReport.setData(this.incomingDuration.getAttributes());
                  this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(),
                      this.incomingSingleFormReport.getAttributes());
                  this.handleReportByCriterias_();
                }
              },(error: any) => {
                this.handleReportByCriterias_();
              });
            }
          }
        }
      }
    });
  }

  extractAndAsc(items: any, t: any, column: any){
    this.items = [];
    this.items = items.map((item: any,position: any) => {
      this.incomingItemExtract.setAttributes(Object.assign({}, item));
      this.incomingItemExtractFiltered.setAttributes({});
      this.incomingItemExtractFiltered.setAttributes(Object.assign(this.incomingItemExtractFiltered.getAttributes(), this.incomingItemExtract.getAttributes()));
      this.incomingItemExtractFiltered.setUnknown(column, parseFloat(this.incomingItemExtract.getUnknown(t)[column]));
      this.incomingItemExtractFiltered.setPosition(position);
      return this.incomingItemExtractFiltered.getAttributes();
    });return orderBy(this.items,column, 'asc');
  }


  level(users: any, carr: any, time: any, label: any, column: any){
    let items: any = [];
    items = users.map((user: any) => {
      this.incomingItm.setAttributes({});
      let sum: any = 0; user[carr].map((product: any) => {
        this.incomingItem.setAttributes(product);
        this.incomingItem.setCurrency(this.incomingItem.attributes.hasOwnProperty('currency') ? this.incomingItem.getCurrency() : 0);
        if (parseFloat(this.incomingItem.attributes.id) === parseFloat(this.incomingQuestion.attributes.id)){
          sum += parseFloat((this.incomingItem.attributes.hasOwnProperty([time][column]) ? this.incomingItem.attributes[time][column] : 0).toString().replaceAll(',', ''));
        }
      });
      this.incomingItm.attributes[column] = sum;
      this.incomingItm.attributes[label] = user[label];
      return this.incomingItm.getAttributes();
    }); return items;
  }

  selectUserByTeamId(teamId: any){
    this.users = this.getUsersByTeamId(teamId);
    this.incomingSelectedUser.setAttributes(this.users.length === 0 ? {} : this.users[this.users.length - 1]);
  }

  sortDescending(items: any, column: any){
   return items.map((item: any, position: any) => {
    this.incomingItem.setAttributes(item);
    this.incomingItem.setPosition(position);
    return this.incomingItem.getAttributes();
   });
  }

  requestOutletEngament(){
    this.outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
    this.incomingReport.setStartingAt(this.incomingItem.getStartingAt());
    this.incomingReport.setEndingAt(this.incomingItem.getEndingAt());
    if(this.outgoingRequest.attributes.hasOwnProperty('data')) delete this.outgoingRequest.attributes.data;
    this.outgoingRequest.setType('outlet.engagement.report');
    this.service.httpService('post', this.service.app.routes.reports.fetch, this.outgoingRequest.getAttributes(), {}, (response: any) => {
      this.service.responses = response;
      this.router.navigateByUrl('/app/reports/outlets/engagement');
    }, (error: any) => {
    });
    this.flag = 0;
  }

  getTeamsByRegionId($regionId: any ){
    let teams: any = [];
    if (this.incomingSelectedRegion.hasAttribute('name')){
      teams = this.incomingReport.attributes.data.teams.filter((team: any) => {
        this.incomingItem.setAttributes(team);
        return !this.service.isNull(this.incomingItem.getAddress()) &&
            this.incomingItem.getAddress().toString().toLowerCase()
                .includes(this.incomingSelectedRegion.getName()
                    .toString()
                    .toLowerCase());
      });
    }return teams;
  }

  onContextMenu(event: MouseEvent, inspector: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': inspector };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  getUsersByTeamId(teamId: any){
    return this.incomingReport.attributes.data.users.filter((user: any) => {return user.hasOwnProperty('team_id') && parseFloat(user.team_id) === parseFloat(teamId);});
  }

  afterSelectRegion(){
    if (this.incomingSelectedRegion.hasAttribute('name')){
      setTimeout(() => {
        const regionTeams: any = this.incomingReport.attributes.data.teams.filter((team: any) => {
          this.incomingItem.setAttributes(team);
          return this.incomingItem.getAddress().toString().toLowerCase().includes(this.incomingSelectedRegion.getName().toString().toLowerCase());
        }); this.incomingSelectedTeam.setAttributes(regionTeams.length === 0 ? {} : regionTeams[0]);
        this.navTeam = 0;
        if (Object.keys(this.incomingSelectedTeam.getAttributes()).length === 0) {}{
          const teamUsers: any = this.incomingReport.attributes.data.users.filter((user: any) => {
            this.incomingItem.setAttributes(user);
            return parseFloat(this.incomingItem.getTeamId()) === parseFloat(this.incomingSelectedTeam.getId());
          }); this.incomingSelectedUser.setAttributes(teamUsers.length === 0 ? {} : teamUsers[teamUsers.length - 1]);
          this.navUser = teamUsers.length === 0 ? 0 : teamUsers.length - 1;
        }
      });
    }
  }

  requestSection(){
    setTimeout(() => {
      this.service.httpService( 'get', this.appsettingsUrl + '/sections.json?version=' + this.service.random(), {}, {}, (result: any) => {
        this.allSections.setAttributes(result);
        let output: any = true;
        this.incomingDurations.setAttributes(this.incomingSingleFormReport.getDurations());
        this.incomingDuration.setAttributes(this.incomingDurations.getUnknown(this.incomingReport.getDuration()));
        this.incomingSections.setAttributes(
            this.incomingDuration.attributes.hasOwnProperty('sections') ?
                this.incomingDuration.getSections() : {});
        for (const key in this.allSections.getAttributes()){
          this.incomingSection.setAttributes(
              this.incomingSections.attributes.hasOwnProperty(key) ?
                  this.incomingSections.getUnknown(key) : {});
          if ((Object.keys(this.incomingSection.getAttributes()).length === 0) &&
              output === true){
            this.incomingReport.setSection(key);
            if(this.incomingReport.getSection().toString().includes('questions')) {
              this.incomingDuration.setUnknown(
                  this.incomingReport.getSection(),
                  []);
              this.incomingSection.setStatus(1);
              this.incomingSections.setUnknown(
                  this.incomingReport.getSection(),
                  this.incomingSection.getAttributes());
              this.incomingDuration.setSections(this.incomingSections.getAttributes());
              this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
              this.incomingSingleFormReport.setDurations(
                  this.incomingDurations.getAttributes());
              this.formDashboardService.incomingForms.setUnknown(
                  this.incomingForm.getId(),
                  this.incomingSingleFormReport.getAttributes());
              this.incomingReport.setData(this.incomingDuration.getAttributes());
              this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
              this.handleReportByQuestions();
              output = false;
            }else if(this.incomingReport.getSection().toString().includes('products')) {
                this.incomingDuration.setUnknown(
                    this.incomingReport.getSection(),
                    []);
                this.incomingSection.setStatus(1);
                this.incomingSections.setUnknown(
                    this.incomingReport.getSection(),
                    this.incomingSection.getAttributes());
                this.incomingDuration.setSections(this.incomingSections.getAttributes());
                this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
                this.incomingSingleFormReport.setDurations(
                    this.incomingDurations.getAttributes());
                this.formDashboardService.incomingForms.setUnknown(
                    this.incomingForm.getId(),
                    this.incomingSingleFormReport.getAttributes());
                this.incomingReport.setData(this.incomingDuration.getAttributes());
                this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
                this.handleReportByProducts();
                output = false;
            }else if(this.incomingReport.getSection().toString().includes('outletSectors')) {
                 this.incomingDuration.setUnknown(
                     this.incomingReport.getSection(),[]);
                 this.incomingSection.setStatus(1);
                 this.incomingSections.setUnknown(
                     this.incomingReport.getSection(),
                     this.incomingSection.getAttributes());
                 this.incomingDuration.setSections(this.incomingSections.getAttributes());
                 this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
                 this.incomingSingleFormReport.setDurations(
                     this.incomingDurations.getAttributes());
                 this.formDashboardService.incomingForms.setUnknown(
                     this.incomingForm.getId(),
                     this.incomingSingleFormReport.getAttributes());
                 this.incomingReport.setData(this.incomingDuration.getAttributes());
                 this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
                 this.handleReportByOutetSectors();
                 output = false;
            }else if(this.incomingReport.getSection().toString().includes('regions')) {
              if(this.attributes.getRegions().data.length !== 0){
                this.incomingDuration.setUnknown(
                    this.incomingReport.getSection(), []);
                this.incomingSection.setStatus(1);
                this.incomingSections.setUnknown(
                    this.incomingReport.getSection(),
                    this.incomingSection.getAttributes());
                this.incomingDuration.setSections(this.incomingSections.getAttributes());
                this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
                this.incomingSingleFormReport.setDurations(
                    this.incomingDurations.getAttributes());
                this.formDashboardService.incomingForms.setUnknown(
                    this.incomingForm.getId(),
                    this.incomingSingleFormReport.getAttributes());
                this.incomingReport.setData(this.incomingDuration.getAttributes());
                this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
                this.handleReportByRegions();
                output = false;
              }
            }else if(this.incomingReport.getSection().toString().includes('users')) {
              this.incomingDuration.setUnknown(
                  this.incomingReport.getSection(), []);
              this.incomingSection.setStatus(1);
              this.incomingSections.setUnknown(
                  this.incomingReport.getSection(),
                  this.incomingSection.getAttributes());
              this.incomingDuration.setSections(this.incomingSections.getAttributes());
              this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
              this.incomingSingleFormReport.setDurations(
                  this.incomingDurations.getAttributes());
              this.formDashboardService.incomingForms.setUnknown(
                  this.incomingForm.getId(),
                  this.incomingSingleFormReport.getAttributes());
              this.incomingReport.setData(this.incomingDuration.getAttributes());
              this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
              this.handleReportByUsers();
              output = false;
            }else if(this.incomingReport.getSection().toString().includes('criterias')) {
               this.incomingDuration.setUnknown(
                   this.incomingReport.getSection(), []);
               this.incomingSection.setStatus(1);
               this.incomingSections.setUnknown(
                   this.incomingReport.getSection(),
                   this.incomingSection.getAttributes());
               this.incomingDuration.setSections(this.incomingSections.getAttributes());
               this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
               this.incomingSingleFormReport.setDurations(
                   this.incomingDurations.getAttributes());
               this.formDashboardService.incomingForms.setUnknown(
                   this.incomingForm.getId(),
                   this.incomingSingleFormReport.getAttributes());
               this.incomingReport.setData(this.incomingDuration.getAttributes());
               this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
               this.handleReportByCriterias();
               output = false;
            }
            // else if(this.incomingReport.getSection().toString().includes(this.strings.getMarkers())) {
            //   this.incomingDuration.setUnknown(
            //       this.incomingReport.getSection(), []);
            //   this.incomingSection.setStatus(1);
            //   this.incomingSections.setUnknown(
            //       this.incomingReport.getSection(),
            //       this.incomingSection.getAttributes());
            //   this.incomingDuration.setSections(this.incomingSections.getAttributes());
            //   this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
            //   this.incomingSingleFormReport.setDurations(
            //       this.incomingDurations.getAttributes());
            //   this.formDashboardService.incomingForms.setUnknown(
            //       this.incomingForm.getId(),
            //       this.incomingSingleFormReport.getAttributes());
            //   this.incomingReport.setData(this.incomingDuration.getAttributes());
            //   this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
            //   this.handleReportByMarkers();
            //   output = false;
            // }
            else if(this.incomingReport.getSection().toString().includes('teams')) {
              this.incomingDuration.setUnknown(
                  this.incomingReport.getSection(), []);
              this.incomingSection.setStatus(1);
              this.incomingSections.setUnknown(
                  this.incomingReport.getSection(),
                  this.incomingSection.getAttributes());
              this.incomingDuration.setSections(this.incomingSections.getAttributes());
              this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
              this.incomingSingleFormReport.setDurations(
                  this.incomingDurations.getAttributes());
              this.formDashboardService.incomingForms.setUnknown(
                  this.incomingForm.getId(),
                  this.incomingSingleFormReport.getAttributes());
              this.incomingReport.setData(this.incomingDuration.getAttributes());
              this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
              this.handleReportByTeams();
              output = false;
            } else {
              this.incomingReport.attributes = Object.assign(this.incomingReport.attributes, { form_id: this.incomingForm.attributes.id});
              setTimeout((params: any) => {
                params.outgoingRequest = Object.assign(params.outgoingRequest, this.incomingReport.attributes);
                if (params.outgoingRequest.hasOwnProperty('data')) {
                  delete params.outgoingRequest.data;
                }this.service.httpService('POST', this.service.app.routes.reports.fetch, params.outgoingRequest, {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
                  this.incomingResponse.setAttributes(response);
                  if(parseFloat(this.incomingResponse.getFormId())   !== parseFloat(this.incomingForm.getId())) return;
                  if(this.incomingResponse.getSection()  !== this.incomingReport.getSection()) return;
                  if(this.incomingResponse.getDuration() !== this.incomingReport.getDuration()) return;
                  this.incomingDuration.setUnknown(
                      this.incomingReport.getSection(),
                      this.incomingResponse.getUnknown(this.incomingReport.getSection()));
                  this.incomingSection.setStatus(1);
                  this.incomingSections.setUnknown(
                      params.section.toString(),
                      this.incomingSection.getAttributes());
                  this.incomingDuration.setSections(this.incomingSections.getAttributes());
                  this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
                  this.incomingSingleFormReport.setDurations(
                      this.incomingDurations.getAttributes());
                  this.formDashboardService.incomingForms.setUnknown(
                      this.incomingForm.getId(),
                      this.incomingSingleFormReport.getAttributes());
                  this.incomingReport.setData(this.incomingDuration.getAttributes());
                  if(this.incomingReport.getSection() === this.strings.getMarkers()){
                   this.getMarkersByAscending(this.incomingReport.getAttributes(), params.outgoingRequest);
                  }
                  if (this.incomingReport.getSection().includes('users')){
                    // this.incomingDuration.setUnknown('regions', this.incomingResponse.getUnknown('regions'));
                    // this.incomingDuration.setUnknown('teams', this.incomingResponse.getUnknown('teams'));
                    // this.incomingDuration.setUnknown('branches', this.incomingResponse.getUnknown('branches'));
                  }if (Object.keys(this.incomingSections.getAttributes()).length >= 8){
                    // this.incomingFormDurations.setAttributes(this.incomingSingleFormReport.attributes.hasOwnProperty('periods') ?
                    // this.incomingSingleFormReport.getPeriods() : {});
                    // this.incomingFormDurations.setUnknown(this.incomingReport.getDuration(), true);
                    // this.incomingSingleFormReport.setPeriods(this.incomingFormDurations.getAttributes());
                    // let incremental: any = false;
                    // this.durations.map((keyDuration: any) => {
                    //     if(!this.incomingSingleFormReport.attributes.periods.hasOwnProperty(keyDuration.tm && !incremental)){
                    //       this.incomingReport.setDuration(keyDuration.tm);
                    //       this.incomingReport.setSection('all');
                    //       incremental = true;
                    //       this.handleDashboard((callback: any) => {
                    //
                    //       });
                    //     }
                    // });
                  }else this.requestSection();
                  this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
                }, (error: any) => {});
              }, this.timeout(), {section: key.toString(), outgoingRequest: {}});
              output = false;
            }
            // else{
            //   this.incomingDuration.setUnknown(
            //       this.incomingReport.getSection(),
            //       []);
            //   this.incomingSection.setStatus(1);
            //   this.incomingSections.setUnknown(
            //       this.incomingReport.getSection(),
            //       this.incomingSection.getAttributes());
            //   this.incomingDuration.setSections(this.incomingSections.getAttributes());
            //   this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
            //   this.incomingSingleFormReport.setDurations(
            //       this.incomingDurations.getAttributes());
            //   this.formDashboardService.incomingForms.setUnknown(
            //       this.incomingForm.getId(),
            //       this.incomingSingleFormReport.getAttributes());
            //   this.incomingReport.setData(this.incomingDuration.getAttributes());
            //   this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
            //   this.handleCustomReports();
            //   output = false;
            // }

          }
        }
      },  (error: any) => {});
    });
  }

  getMarkersByAscending(report: any, request: any){
    let incomingReport: any = new Parameters();
    incomingReport.setAttributes(Object.assign({}, report));
    let outgoingRequest: any = new Parameters();
    outgoingRequest.setAttributes(Object.assign({}, request));
    outgoingRequest.setFormId(this.incomingForm.getId());
    if(this.incomingReport.attributes.data.markers[incomingReport.getDuration()].markers.length !== 0){
      outgoingRequest.setAscendinglastAt(this.incomingReport.attributes.data.markers[incomingReport.getDuration()]
          .markers[this.incomingReport.attributes.data.markers[incomingReport.getDuration()].markers.length - 1].id);
      this.service.httpService('POST', this.service.app.routes.reports.fetch, outgoingRequest.getAttributes(), {
        ignoreLoadingBar: 'true', notify: false}, (response: any) => {
        let incomingResponse: any = new Parameters();
        incomingResponse.setAttributes(response);
        if(parseFloat(incomingResponse.getFormId())   !== parseFloat(this.incomingForm.getId())) return;
        if(incomingResponse.getSection()  !== incomingReport.getSection()) return;
        if(incomingResponse.getDuration() !== incomingReport.getDuration()) return;
        incomingResponse.getMarkers()[incomingReport.getDuration()].markers.map((marker: any) => {
          let incomingMarker: any = new Parameters();
          incomingMarker.setAttributes(marker);
          this.incomingReport.attributes.data.markers[incomingReport.getDuration()].markers.push(incomingMarker.getAttributes());
        });if(incomingResponse.getMarkers()[incomingReport.getDuration()].markers.length !== 0)
          this.getMarkersByAscending(
              incomingReport.getAttributes(),
              outgoingRequest.getAttributes());
      },  (error: any) => {
      });
    }
  }



  exportCustomReport(customReport: any){
    setTimeout((time: any, items: any = []) => {
      time = this.durations.filter((duration: any) => {return parseFloat(duration.id) === parseFloat(this.navTime)})[0];
      if(parseFloat(this.incomingSelectedCustomReport.getResult().status) === 0){
        this.legends = this.only((this.extract(this.incomingSelectedCustomReport.getResult().data[0].options, time.tm, 'responses')), 'label');
        this.values = this.only((this.extract(this.incomingSelectedCustomReport.getResult().data[0].options, time.tm, 'responses')), 'responses');
        this.legends.map((item: any, incremental: any) => {
          this.incomingItem.setAttributes({});
          this.incomingItem.setUnknown('sector', item);
          this.incomingItem.setUnknown(this.incomingForm.getResponseDisplayTitle(), this.values[incremental]);
          items.push(this.incomingItem.getAttributes());
        });this.service.exportCustomReport(items, this.incomingSelectedCustomReport.getTitle() + '-' + this.service.setDisplayedDate(this.dashboardService.incomingReport), true);
      }
    });
  }

  setChart(item: any){
    this.incomingItem.setAttributes(item);
    this.incomingItem.setChart(this.incomingItem.attributes.hasOwnProperty('chart') ?
        this.incomingItem.getChart() : 2);
  }

  handleCustomReports(){
    let output: any = true;
    this.incomingForm.getCustomReports().data.map((customReport: any) => {
      if(output === true){
        this.incomingCustomReport.setAttributes(Object.assign({}, customReport));
        this.position = this.service.findIndex(
            this.incomingDuration.getUnknown(this.incomingReport.getSection()), 'id',
            this.incomingCustomReport.getId());
        if(this.position === -1){
          this.outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
          this.outgoingRequest.setId(this.incomingCustomReport.getId());
          delete this.outgoingRequest.attributes.data;
          this.service.httpService('post', this.service.app.routes.reports.fetch, this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
            this.incomingResponse.setAttributes(response);
            if(parseFloat(this.incomingResponse.getFormId())   !== parseFloat(this.incomingForm.getId())) return;
            if(this.incomingResponse.getSection()  !== this.incomingReport.getSection()) return;
            if(this.incomingResponse.getDuration() !== this.incomingReport.getDuration()) return;
            this.incomingItem.setAttributes(Object.assign({}, this.incomingForm.getCustomReports().data.filter((report: any) => {return parseFloat(report.id) === parseFloat(response.id);})[0]));
            this.incomingItem.setResult(response);
            this.incomingDuration.getUnknown(this.incomingReport.getSection()).push(this.incomingItem.getAttributes());
            this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
            this.incomingSingleFormReport.setDurations(
                this.incomingDurations.getAttributes());
            this.formDashboardService.incomingForms.setUnknown(
                this.incomingForm.getId(),
                this.incomingSingleFormReport.getAttributes());
            this.incomingReport.setData(this.incomingDuration.getAttributes());
            this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
            this.handleCustomReports();
          },  (error: any) => {
            this.handleCustomReports();
          }); output = false;
        }
      }
    });
    if(this.incomingReport.attributes.data.custom_reports.length === this.incomingForm.getCustomReports().data.length) this.requestSection();
  }



  gallery(){ this.router.navigateByUrl('/us/app/forms/' + this.service.encrypt(this.incomingForm.getId()) + '/images' );}


  onlyProduct(responses: any){
    const results: any = responses.filter((response: any) => {
      this.incomingQuestion.setAttributes(response.formControl);
      return this.incomingProducts.getAttributes().hasOwnProperty(this.incomingQuestion.getCntrlNum()) || parseFloat(this.incomingQuestion.getControlId()) === 26;
    }); return results;
  }

  onFormSelected(form: any){
    if(parseFloat(form.id) !== parseFloat(this.formService.formSelectedId)){
      this.subscription.unsubscribe();
      this.formService.formSelectedId = form.id;
      this.formService.setSelected(this.formService.getFormById(form.id));
      this.router.navigateByUrl('/wait..');
      this.incomingReport.setDuration('custom');
      this.incomingReport.setSection('all');
      this.hiddenFlag = 1;
      setTimeout(() => {
        if(parseFloat(this.service.app.data.user.role.id) === 2){
          this.router.navigateByUrl('/us/app/forms/' + this.service.encrypt(form.id) + '/' + 'dashboard3');
        }else this.router.navigateByUrl('/app/forms/' + this.service.encrypt(form.id) + '/' + 'dashboard');
      });
    }
  }

  onMapClicked(){ this.opened = false; }
  oncreateFormButtonClicked(){
    this.formService.setSelected({});
    this.formService.openDialog({});
  }

  handleReportByQuestions(){
    let output: any = true;
    let questions: any = this.incomingForm.getControls().filter((question: any) => {
      this.incomingQuestion.setAttributes(question);
      return parseFloat(this.incomingQuestion.getControlId()) === 26 ||
          parseFloat(this.incomingQuestion.getControlId()) === 30 ||
          parseFloat(this.incomingQuestion.getControlId()) === 31;
    });
    questions.map((question: any) => {
      if(output === true){
        this.incomingQuestion.setAttributes(Object.assign({}, question));
        this.position = this.service.findIndex(
            this.incomingDuration.getUnknown(this.incomingReport.getSection()), 'id',
            this.incomingQuestion.getId());
        if(this.position === -1){
          this.outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
          this.outgoingRequest.setId(this.incomingQuestion.getId());
          delete this.outgoingRequest.attributes.data;
          this.service.httpService('post', this.service.app.routes.reports.fetch, this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
            this.incomingResponse.setAttributes(response);
            if(parseFloat(this.incomingResponse.getFormId())   !== parseFloat(this.incomingForm.getId())) return;
            if(this.incomingResponse.getSection()  !== this.incomingReport.getSection()) return;
            if(this.incomingResponse.getDuration() !== this.incomingReport.getDuration()) return;
            if(this.incomingResponse.hasAttribute('questions')){
              this.incomingItem.setAttributes(Object.assign({}, this.incomingResponse.getQuestions().filter((control: any) => {return parseFloat(control.id) === parseFloat(this.incomingResponse.getId());})[0]));
              this.incomingDuration.getUnknown(this.incomingReport.getSection()).push(this.incomingItem.getAttributes());
              this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
              this.incomingSingleFormReport.setDurations(
                  this.incomingDurations.getAttributes());
              this.formDashboardService.incomingForms.setUnknown(
                  this.incomingForm.getId(),
                  this.incomingSingleFormReport.getAttributes());
              this.incomingReport.setData(this.incomingDuration.getAttributes());
              this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
              this.handleReportByQuestions();
            }

          },  (error: any) => {
            this.handleReportByQuestions();
          }); output = false;
        }
      }
    });
    if(this.incomingReport.attributes.data.questions.length === questions.length) {
      this.requestSection();
    }
  }

  getQuestionAliasByQuestionId(questionId: any){
   return this.incomingForm.getControls().filter((question: any) => parseFloat(question.id) === parseFloat(questionId))[0];
  }

  onClickMarker(event: any, marker: any){
    this.incomingMarker.setAttributes(marker);
    this.outgoingRequest.setAttributes({});
    this.outgoingRequest.setUrl('/responses/' + this.incomingMarker.getId());
    this.spinner = true;
    this.service.httpService('post', this.outgoingRequest.getUrl(), {}, {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
      this.incomingFeedback.setAttributes(response);
      this.incomingMarker.setResponse([this.incomingFeedback.getAttributes()]);
      this.spinner = false;
    }, (error: any) => {});
  }


  handleReportByProducts(){
      let output: any = true;
      let products: any = this.incomingForm.getProducts().map((question:any)=>{return question.options;});
      products = products.flat(1);
      products.map((question: any) => {
        if(output === true){
          this.incomingQuestion.setAttributes(Object.assign({}, question));
          this.position = this.service.findIndex(
              this.incomingDuration.getUnknown(this.incomingReport.getSection()), 'id',
              this.incomingQuestion.getId());
          if(this.position === -1){
            this.outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
            this.outgoingRequest.setId(this.incomingQuestion.getId());
            delete this.outgoingRequest.attributes.data;
            this.service.httpService('post', this.service.app.routes.reports.fetch, this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
              this.incomingResponse.setAttributes(response);
              if(parseFloat(this.incomingResponse.getFormId())   !== parseFloat(this.incomingForm.getId())) return;
              if(this.incomingResponse.getSection()  !== this.incomingReport.getSection()) return;
              if(this.incomingResponse.getDuration() !== this.incomingReport.getDuration()) return;
              if(this.incomingResponse.hasAttribute('products')){
                this.incomingItem.setAttributes(Object.assign({}, this.incomingResponse.getProducts().filter((control: any) => {return parseFloat(control.id) === parseFloat(this.incomingResponse.getId());})[0]));
                this.incomingDuration.getUnknown(this.incomingReport.getSection()).push(this.incomingItem.getAttributes());
                this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
                this.incomingSingleFormReport.setDurations(
                    this.incomingDurations.getAttributes());
                this.formDashboardService.incomingForms.setUnknown(
                    this.incomingForm.getId(),
                    this.incomingSingleFormReport.getAttributes());
                this.incomingReport.setData(this.incomingDuration.getAttributes());
                this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
                this.handleReportByProducts();
              }
            },  (error: any) => {
              this.handleReportByProducts();
            }); output = false;
          }
        }
      });
      if(this.incomingReport.attributes.data.products.length === products.length) {
        this.requestSection();
      }
 }

  pState(){
    this.hiddenFlag = parseFloat('0');
    let yScrollablePart: any = window.pageYOffset;
    setTimeout(() => {
      this.hiddenFlag = parseFloat('1');
      setTimeout(() =>{
        window.scrollTo(0, yScrollablePart);
      });
    });
  }
  onextractFormButtonClicked(){}

  only(data, label){return data.map((l: any) => l[label]); }


  onInfoWindowClose(gm: any, infoWIndow: any){}

  getHeight(){ return {height: window.innerHeight}; }
  seeAllRsp(){ this.router.navigateByUrl('/app/forms/' + this.incomingForm.getId() + '/responses'); }
  getNames(data: any){ return data.map((l: any) => l.name); }
  getlabels(data: any, label: any){return data.map((l: any) => (l[label]).toString()); }

  handleReportByRegions(){
    let isEnding: any = true;
    this.attributes.getRegions().data.map((region: any) => {
      if(isEnding === true){
        this.incomingRegion.setAttributes(Object.assign({}, region));
        this.position = this.service.findIndex(
            this.incomingDuration.getUnknown(this.incomingReport.getSection()), 'id',
            this.incomingRegion.getId());
        if(this.position === -1){
          this.outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
          this.outgoingRequest.setId(this.incomingRegion.getId());
          delete this.outgoingRequest.attributes.data;
          this.service.httpService('post', this.service.app.routes.reports.fetch, this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
            this.incomingResponse.setAttributes(response);
            if(parseFloat(this.incomingResponse.getFormId()) !== parseFloat(this.incomingForm.getId())) return;
            if(this.incomingResponse.getSection()  !== this.incomingReport.getSection()) return;
            if(this.incomingResponse.getDuration() !== this.incomingReport.getDuration()) return;
            if(this.incomingResponse.hasAttribute('regions')){
              this.incomingItem.setAttributes(Object.assign({}, this.incomingResponse.getRegions().filter((item: any) => {return parseFloat(item.id) === parseFloat(this.incomingResponse.getId());})[0]));
              this.incomingDuration.getUnknown(this.incomingReport.getSection()).push(this.incomingItem.getAttributes());
              this.incomingSelectedRegion.setAttributes(this.incomingItem.getAttributes());
              this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
              this.incomingSingleFormReport.setDurations(
                  this.incomingDurations.getAttributes());
              this.formDashboardService.incomingForms.setUnknown(
                  this.incomingForm.getId(),
                  this.incomingSingleFormReport.getAttributes());
              this.incomingReport.setData(this.incomingDuration.getAttributes());
              this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
              this.handleReportByRegions();
            }
          },  (error: any) => { this.handleReportByRegions(); }); isEnding = false;
        }
      }
    });
    if(this.incomingReport.attributes.data.regions.length === this.attributes.getRegions().data.length) {
      this.requestSection();
      this.handleReportByDistricts();
    }
  }



  getValues(data: any, value: string){return data.map((l: any) => ({name: l.name, value: l[value]})); }
  getValuesByLableAndValue2(data: any, label: any, value: any){return data.map((l: any) => ({name: l[label], value: parseFloat(l[value])})); }
  getValuesByLableAndValue(data: any, label: any, value: string){return data.map((l: any) => {
    const spliter: any = value.split(',');
    return {name: l[label], value: spliter.length === 0 ? l[value] : l[spliter[0]][spliter[1]]}; });
  }

  dashbaord(){
    this.incomingDurations.setAttributes(this.incomingSingleFormReport.getDurations());
    this.incomingDuration.setAttributes(this.incomingDurations.getUnknown(this.incomingReport.getDuration()));
    this.incomingReport.setData(this.incomingDuration.getAttributes());
    if(this.incomingReport.getData().hasOwnProperty('criterias')){
      if(this.incomingReport.getData().criterias.length !== 0){
        if(Object.keys(this.incomingCriteriaSelected.getAttributes()).length===0){
          this.incomingCriteriaSelected.setAttributes(this.incomingReport.getData().criterias[0]);
        }
      }
    }
    this.requestSection();
  }

  filter(questions: any){
    return questions.filter((questional: any) => questional.control.id === 3 || questional.control.id === 5);
  }

  infoWindowDetails(){
    let all: any = null;
    this.markers.map((point: any) => {
      all = (all === null) ? '<h4>' + point.postedBy.name + '</h4>' : all + '<br><hr>' + '<h4>' + point.postedBy.name + '</h4>';
    });
    return all;
  }




  handleHomeNavigator(){ this.router.navigateByUrl('/app/forms'); }
  handleNavigator(specie: any){
    this.dialog.open(DialogOverallComponent, {data : {report: this.dashboardService.incomingReport.attributes}, width : '120%', height: '99%', });
  }

  outletEngagementAndSales(){
    this.flag = 3;
    this.incomingItem.setAttributes({});
    this.incomingItem.setStartingAt(this.incomingReport.getStartingAt());
    this.incomingItem.setEndingAt(this.incomingReport.getEndingAt());
    this.openDateMenu();
  }


  requestDateRangeReport(){
    this.outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
    this.incomingReport.setStartingAt(this.incomingItem.getStartingAt());
    this.incomingReport.setEndingAt(this.incomingItem.getEndingAt());
    if(this.outgoingRequest.attributes.hasOwnProperty('data')) delete this.outgoingRequest.attributes.data;
    this.outgoingRequest.setType('responses.date.range');
    this.service.httpService('post', this.service.app.routes.reports.fetch, this.outgoingRequest.getAttributes(), {}, (result: any) => {
      this.service.responses = result;
      this.router.navigateByUrl('/app/supervisors/xxx/responses');
    }, (error: any) => {
    });
    this.flag = 0;
  }

  handleDashboard(item: any){
    this.incomingReport.attributes = Object.assign(this.incomingReport.attributes, { form_id: this.incomingForm.attributes.id});
    setTimeout((outgoingRequest: any = {}) => {
      outgoingRequest = Object.assign(outgoingRequest, this.incomingReport.attributes);
      delete outgoingRequest.data;
      delete outgoingRequest.business_id;
      if (item === 'starter') this.router.navigateByUrl('/wait..');
      this.service.httpService('post', this.service.app.routes.reports.fetch, outgoingRequest, {}, (response: any) => {
        this.incomingResponse.setAttributes(Object.assign({}, response));
        if(parseFloat(this.incomingResponse.getFormId()) !== parseFloat(this.incomingForm.getId())) return;
        if(this.incomingResponse.getSection()  !== this.incomingReport.getSection()) return;
        if(this.incomingResponse.getDuration() !== this.incomingReport.getDuration()) return;
        this.incomingSectionDurations.setAttributes(Object.keys(this.incomingSectionDurations.attributes).length === 0 ? {} : this.incomingSectionDurations.getAttributes());
        this.incomingSectionDurations.setUnknown(this.incomingReport.getDuration(), true);
        this.incomingSingleFormReport.setAttributes(
            this.formDashboardService.incomingForms.attributes.hasOwnProperty(this.incomingForm.getId()) ?
                this.formDashboardService.incomingForms.getUnknown(this.incomingForm.getId()) : {});
        this.incomingSingleFormReport.setId(this.incomingForm.getId());
        this.incomingDurations.setAttributes(
            this.incomingSingleFormReport.attributes.hasOwnProperty('durations') ?
                this.incomingSingleFormReport.getDurations() : {});
        this.incomingDuration.setAttributes(
            this.incomingDurations.attributes.hasOwnProperty(this.incomingReport.getDuration()) ?
                this.incomingDurations.getUnknown(this.incomingReport.getDuration()) : {});
        this.incomingDuration.setAttributes(response);
        this.incomingSections.setAttributes(
            this.incomingDuration.attributes.hasOwnProperty('sections') ?
                this.incomingDuration.getSections() : {});
        this.incomingSection.setAttributes(
            this.incomingSections.attributes.hasOwnProperty(this.incomingReport.getSection()) ?
                this.incomingSections.getUnknown(this.incomingReport.getSection()) : {});
        this.incomingSection.setStatus(1);
        this.incomingSection.setDurations(this.incomingSectionDurations.getAttributes());
        this.incomingSections.setUnknown(this.incomingReport.getSection(), this.incomingSection.getAttributes());
        this.incomingDuration.setSections(this.incomingSections.getAttributes());
        this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
        this.incomingSingleFormReport.setDurations(this.incomingDurations.getAttributes());
        this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
        if (item === 'starter') {
          window.history.back();
        } else item(null);
      }, (error: any) => {
      });
    }, this.timeout());
  }

  dateRangeReport(){
    this.flag = 4;
    this.incomingItem.setAttributes({});
    this.incomingItem.setStartingAt(this.incomingReport.getStartingAt());
    this.incomingItem.setEndingAt(this.incomingReport.getEndingAt());
    this.openDateMenu();
  }

  viewResponsesByQuestionId(){
     this.responseService.reportValues.setStartingAt(this.incomingReport.getStartingAt());
     this.responseService.reportValues.setEndingAt(this.incomingReport.getEndingAt());
     this.responseService.reportValues.setData(null);
     this.subscription.unsubscribe();
     this.router.navigateByUrl('/us/app/forms/' + this.service.encrypt(this.incomingForm.getId()) + '/questions/' + this.service.encrypt(this.incomingSelectedQuestion.getId()) + '/responses' );
  }

  onChangeDuration(duration: any) {
    setTimeout(() => {
    //this.incomingDurations.setAttributes(Object.assign({}, this.incomingSingleFormReport.getDurations()));
      this.incomingDurations.setAttributes({});
      this.incomingDuration.setAttributes(Object.assign({}, this.incomingDurations.attributes.hasOwnProperty(duration) ? this.incomingDurations.getUnknown(duration) : {}));
      this.incomingReport.setDuration(duration);
      this.incomingReport.setSection('all');
      this.handleDashboard((call: any) => { this.dashbaord();});
      // if (Object.keys(this.incomingDuration.getAttributes()).length === 0) {
      //   this.handleDashboard((call: any) => { this.dashbaord();});
      // } else this.dashbaord();

    });
  }

  viewInventoriesByQuestionId(){
     this.responseService.reportValues.setStartingAt(this.incomingReport.getStartingAt());
     this.responseService.reportValues.setEndingAt(this.incomingReport.getEndingAt());
     this.responseService.reportValues.setData(null);
     this.subscription.unsubscribe();
     this.router.navigateByUrl('/us/app/forms/' + this.service.encrypt(this.incomingForm.getId()) + '/questions/' + this.service.encrypt(this.incomingSelectedQuestion.getId()) + '/inventories' );

  }



  viewResponsesByQuestionIdAndLabel(){
       this.responseService.reportValues.setStartingAt(this.incomingReport.getStartingAt());
       this.responseService.reportValues.setEndingAt(this.incomingReport.getEndingAt());
       this.responseService.reportValues.setData(null);
       this.subscription.unsubscribe();
       this.router.navigateByUrl('/us/app/forms/' + this.service.encrypt(this.incomingForm.getId()) + '/questions/' + this.service.encrypt(this.incomingSelectedQuestion.getQuestionId()) + '/labels/' + this.service.encrypt(this.incomingSelectedQuestion.getLabel()) + '/responses' );
  }

  getAmbassadorsFinalReport(){
    this.flag = 5;
    this.outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
    this.outgoingRequest.setFormId(this.incomingForm.getId());
    if(this.outgoingRequest.hasAttribute('data')) delete this.outgoingRequest.attributes.data;
    this.openDateMenu();
  }


  handleReportByTeams(){
    this.result = true;
    this.teams = this.incomingCampaign.getTeams().data;
    this.teams.map((team: any) => {
      if(this.result === true){
        this.incomingTeam.setAttributes(Object.assign({}, team));
        this.position = this.service.findIndex(
            this.incomingDuration.getUnknown(this.incomingReport.getSection()), 'id',
            this.incomingTeam.getId());
        if(this.position === -1){
          this.outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
          this.outgoingRequest.setId(this.incomingTeam.getId());
          delete this.outgoingRequest.attributes.data;
          this.service.httpService('post', this.service.app.routes.reports.fetch, this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
            this.incomingResponse.setAttributes(response);
            if(parseFloat(this.incomingResponse.getFormId()) !== parseFloat(this.incomingForm.getId())) return;
            if(this.incomingResponse.getSection()  !== this.incomingReport.getSection()) return;
            if(this.incomingResponse.getDuration() !== this.incomingReport.getDuration()) return;
            if(this.incomingResponse.hasAttribute('teams')){
              this.incomingItem.setAttributes(Object.assign({}, this.incomingResponse.getTeams().filter((item: any) => {return parseFloat(item.id) === parseFloat(this.incomingResponse.getId());})[0]));
              this.incomingDuration.getUnknown(this.incomingReport.getSection()).push(this.incomingItem.getAttributes());
              this.incomingSelectedTeam.setAttributes(
                  Object.assign({},
                   Object.keys(this.incomingSelectedTeam.getAttributes()).length === 0 ?
                   this.incomingItem.getAttributes() :
                   this.incomingSelectedTeam.getAttributes()
                  )
              );
              this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
              this.incomingSingleFormReport.setDurations(
                  this.incomingDurations.getAttributes());
              this.formDashboardService.incomingForms.setUnknown(
                  this.incomingForm.getId(),
                  this.incomingSingleFormReport.getAttributes());
              this.incomingReport.setData(this.incomingDuration.getAttributes());
              this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
              this.handleReportByTeams();
            }
          },  (error: any) => { this.handleReportByTeams(); }); this.result = false;
        }
      }
    });
    if(this.incomingReport.attributes.data.teams.length === this.teams.length) {
      this.requestSection();
    }
  }


  redirectAmbassadorsPage(){
    this.formService.formSelectedId = parseFloat(this.incomingForm.getId());
    this.router.navigateByUrl('/us/app/forms/' + this.service.encrypt(this.incomingForm.getId()) + '/ambassadors' );
 // this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.incomingForm.getId()) + '/ambassadors/list' );
  }

  getGroup(options: any, timely: any, num: any, optName: any){
    const result: any = [];
    options.map((option: any) => {
      this.incomingOption.setAttributes(option);
      const responses: any = this.incomingOption.getAttributes()[timely].map((tm: any, key: any) => {
        this.incomingItem.setAttributes(tm);
        return this.incomingItem.getAttributes()[num];
      }); result.push({label: option[optName], data: responses});
    }); return result;
  }

  timeout(){return 94; }
  float(l: any){ return parseFloat(l); }


  openMenuDate(){
    this.outgoingRequest.setAttributes(this.exportExcel ? Object.assign({}, this.dashboardService.incomingReport.getAttributes()) : this.dashboardService.incomingReport.getAttributes());
    this.outgoingRequest.setDisplay(this.exportExcel ? 'excel'     : 'form-dashboard');
    this.outgoingRequest.setType(this.exportExcel ? 'responses' : 'form-dashboard');
    if(this.outgoingRequest.attributes.hasOwnProperty('data')){
      if(this.exportExcel) delete this.outgoingRequest.attributes.data;
    }this.dialog.open(FilterDashboardComponent, { data: {report: this.outgoingRequest.getAttributes()} , width  : '55%', height : '100%'});
    this.exportExcel = false;
  }

  handleReportByDistricts(){
      let result: any = true;
      this.incomingRegion.setAttributes(Object.assign({},this.attributes.getRegions().data.filter((region: any) => {return parseFloat(region.id) === parseFloat(this.incomingSelectedRegion.getId());})[0]));
      this.incomingRegion.getDistricts().map((district: any) => {
        if(result === true){
          this.incomingSelectedRegion.setDistricts(this.incomingSelectedRegion.hasAttribute('districts') ? this.incomingSelectedRegion.getDistricts() : []);
          this.incomingDistrict.setAttributes(Object.assign({}, district));
          this.position = this.service.findIndex(
                                  this.incomingSelectedRegion.getDistricts(), 'id',
                                  this.incomingDistrict.getId());
          if(this.position === -1){
            this.outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
            this.outgoingRequest.setId(this.incomingDistrict.getId());
            this.outgoingRequest.setSection('districts');
            delete this.outgoingRequest.attributes.data;
            this.service.httpService('post', this.service.app.routes.reports.fetch, this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
              this.incomingResponse.setAttributes(response);
              if(parseFloat(this.incomingResponse.getFormId()) !== parseFloat(this.incomingForm.getId())) return;
              if(this.incomingResponse.getSection()  !== 'districts') return;
              if(this.incomingResponse.getDuration() !== this.incomingReport.getDuration()) return;
              if(this.incomingResponse.hasAttribute('districts')){
              this.incomingItem.setAttributes(Object.assign({}, this.incomingResponse.getDistricts().filter((item: any) => {return parseFloat(item.id) === parseFloat(this.incomingResponse.getId());})[0]));
              this.incomingSelectedRegion.getDistricts().push(this.incomingItem.getAttributes());
              this.incomingDistrictSelected.setAttributes(this.incomingItem.getAttributes());
              this.handleReportByDistricts();
              }
            },  (error: any) => { this.handleReportByDistricts(); }); result = false;
          }
        }
      });
      if(this.incomingRegion.getDistricts().length === this.incomingSelectedRegion.getDistricts().length) {}
  }

  updateCriteria(_criteria: any){
    const incomingCriteria = new Parameters();
    incomingCriteria.setAttributes(_criteria);
    this.incomingFormSettings.getCriterias().map((criteria: any) => {
      const outgoingCriteria: any = new Parameters();
      outgoingCriteria.setAttributes(criteria);
      if(incomingCriteria.getCntrlNum().includes(outgoingCriteria.getCntrlNum())){
        outgoingCriteria.attributes = Object.assign(
            outgoingCriteria.attributes,
            incomingCriteria.getAttributes());
        const outgoingRequest: any = new Parameters();
        outgoingRequest.setAttributes(Object.assign({}, this.incomingForm.getAttributes()));
        outgoingRequest.setSettings(JSON.stringify(this.incomingFormSettings.getAttributes()));
        this.formService.updateSettings(outgoingRequest, (result: any) => {});
      }
    });
  }

  openCriterialabelPrompt(criteria: any){
    const incomingCriteria = new Parameters();
    incomingCriteria.setAttributes(criteria);
    let text: any = prompt('', incomingCriteria.getTitle());
    if(!this.service.isNull(text)) {
      incomingCriteria.setTitle(text);
      this.updateCriteria(incomingCriteria.getAttributes());
    }
  }

  openChartDialog(criteria: any){
    this.dialog.open(SettingsCriteriasDialogComponent, {data  : {criteria: criteria}, width : '55%', height: '100%',});
  }
  openSettingsDialog(criteria: any){
    const dialogRef = this.dialog.open(SettingsFormComponent, {
      disableClose: false,
      data: {},
      width: '70%',
      height: '100%',
      autoFocus: true,
    });
    dialogRef.afterOpened().subscribe(() => {
      setTimeout(()=>{ this.broadcastChannel.emitNavChangeEvent({criteriaSelected: criteria});},this.service.timeout());
    });
    dialogRef.afterClosed().subscribe(() => {});
  }

  upperShiftCriteria(criteria: any, position: any){
    const criterias: any = this.service.orderBy(this.incomingReport.attributes.data.criterias,'asc','index');
    criterias.splice(position, 1);
    criterias.splice(position - 1, 0, criteria);
    criterias.map((reportCriteria: any, key: any) => { criterias[key].index = key;});
    criterias.map((reportCriteria: any) => {
      const incomingCriteria = new Parameters();
      incomingCriteria.setAttributes(reportCriteria);
      this.incomingFormSettings.getCriterias().map((settingCriteria: any) => {
        const outgoingCriteria: any = new Parameters();
        outgoingCriteria.setAttributes(settingCriteria);
        if(incomingCriteria.getCntrlNum().includes(outgoingCriteria.getCntrlNum())){
          outgoingCriteria.attributes = Object.assign(
              outgoingCriteria.attributes,
              incomingCriteria.getAttributes());
        }
      });
    });
    const outgoingRequest: any = new Parameters();
    outgoingRequest.setAttributes(Object.assign({}, this.incomingForm.getAttributes()));
    outgoingRequest.setSettings(JSON.stringify(this.incomingFormSettings.getAttributes()));
    this.formService.updateSettings(outgoingRequest, (result: any) => {});
  }

  downShiftCriteria(criteria: any, position: any){
    const criterias: any = this.service.orderBy(this.incomingReport.attributes.data.criterias,'asc','index');
    criterias.splice(position, 1);
    criterias.splice(position + 1, 0, criteria);
    criterias.map((reportCriteria: any, key: any) => { criterias[key].index = key;});
    criterias.map((reportCriteria: any) => {
      const incomingCriteria = new Parameters();
      incomingCriteria.setAttributes(reportCriteria);
      this.incomingFormSettings.getCriterias().map((settingCriteria: any) => {
        const outgoingCriteria: any = new Parameters();
        outgoingCriteria.setAttributes(settingCriteria);
        if(incomingCriteria.getCntrlNum().includes(outgoingCriteria.getCntrlNum())){
          outgoingCriteria.attributes = Object.assign(
              outgoingCriteria.attributes,
              incomingCriteria.getAttributes());
        }
      });
    });
    const outgoingRequest: any = new Parameters();
    outgoingRequest.setAttributes(Object.assign({}, this.incomingForm.getAttributes()));
    outgoingRequest.setSettings(JSON.stringify(this.incomingFormSettings.getAttributes()));
    this.formService.updateSettings(outgoingRequest, (result: any) => {});
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formDashboardService.dialogStatus = true;
  }

  viewResponsesByAll(){
   this.responseService.reportValues.setStartingAt(this.incomingReport.getStartingAt());
   this.responseService.reportValues.setEndingAt(this.incomingReport.getEndingAt());
   this.responseService.reportValues.setData(null);
   this.subscription.unsubscribe();
   this.router.navigateByUrl('/us/app/forms/' + this.service.encrypt(this.incomingForm.getId()) + '/responses' );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.showFlag = false;
      this.service.httpService( 'get', '../assets/json/reports.json?version=' + this.service.random(), {}, {},
          (response: any) => {
            this.charts = response;
          },  (error: any) => {
          });
      $.getScript( '../assets/js/easy-chart.js?version=' + this.service.random(), ( data, textStatus, jqxhr ) => {});
      $.getScript( '../assets/js/plot.js', ( data, textStatus, jqxhr ) => {});
      this.hiddenFlag = 1;
      // window.localStorage.setItem('monthly', JSON.stringify(this.incomingReport.attributes.data.monthly));
      // window.localStorage.setItem('weekly' , JSON.stringify(this.incomingReport.attributes.data.weekly));
      // $.getScript( '../assets/js/flot.js', ( data, textStatus, jqxhr ) => {});
    },this.service.timeout() *2);
  }

  ngAfterContentInit(): void {
    $.getScript( '../assets/js/easy-chart.js?version=' + this.service.random(), ( data, textStatus, jqxhr ) => {});
    this.service.httpService( 'get', this.appsettingsUrl + 'time.json?version=' + this.service.random() , {}, {},
        (response: any) => {
          this.durations = response;
        },  (error: any) => {});
    this.service.httpService( 'get', this.appsettingsUrl + 'func.json?version=' + this.service.random() , {}, {},
        (response: any) => {
          this.func = response;
        },  (error: any) => {
        });
  }

}
