<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <center>
        <div><img alt="" ngClass="" [src]="this.service.logo" [ngStyle]="{'width':'auto','height':'100px'}" /></div><br><br>
        <h2 class="mdl-color-text--grey-700">Dear {{name}},</h2>
        <br>
<!--        <h2 class="mdl-color-text&#45;&#45;grey-700">Thank you for registering! To proceed with ticket payment, please select your desired payment method below to receive an invitation card and ensure your spot at the event</h2><br><br>-->
    </center>
</div>
<div class="vertical-centerr">
    <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <center>
            <h2 class="mdl-color-text--grey-700" *ngFor="let packagee of packages">Package: <span [innerHTML]="packagee"></span></h2>
            <h2 class="mdl-color-text--grey-700">Tickets: <span class="mitra mdl-typography--display-1">{{tickets}}</span></h2>
            <h2 class="mdl-color-text--grey-700">Bill: <span class="mitra mdl-typography--display-1">{{this.service.numberWithCommans(total)}}</span></h2>
            <br><br>
            <div [innerHTML]="details | safeUrl"></div>
        </center>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
</div>
