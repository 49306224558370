import {AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppService} from '../../app.service';
import {FormService} from '../../forms/form.service';
import {Parameters} from '../../parameters';
import {ActivatedRoute, Router} from '@angular/router';
import Echo from 'laravel-echo';
import {ResponseService} from '../../forms/responses/data/response.service';
import {MatDialog} from '@angular/material/dialog';
import {MessageDisplayComponent} from '../../main/message/message-display.component';
import {NgxWheelComponent} from 'ngx-wheel';
@Component({
  selector: 'app-wheel-raffles',
  templateUrl: './wheel-raffles.component.html',
  styleUrls: ['./wheel-raffles.component.scss']
})
export class WheelRafflesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(NgxWheelComponent, { static: false }) wheel;
  spinningWheelSoundEffect: any = 'assets/audio/spinning-wheel.mp3';
  textAlignment: any = 'outer';
  textOrientation: any = 'horizontal';
  textDirection: any = 'reversed';
  idToLandOn: any = null;
  width: any = 600;
  height: any = 600;
  eventId: any;
  strings: any;
  items: any =  [];
  incomingForm: any;
  incomingCampaign: any;
  incomingClient: any;
  incomingFormSettings: any;
  outgoingRequest: any;
  incomingQuestionSelected: any;
  isDialogOpen: any = false;
  disabledButton: any = false;
  sound: any = null;
  flag: any = 0;
  responses: any = [];
  isSpinningInterval: any;
  dialogRef: any;
  constructor(public service: AppService,
              private router: Router,
              public zone: NgZone,
              private dialog: MatDialog,
              private responseService: ResponseService,
              private activatedRoute: ActivatedRoute,
              private formService: FormService) {
    this.incomingForm = new Parameters();
    this.incomingCampaign = new Parameters();
    this.incomingClient = new Parameters();
    this.incomingFormSettings = new Parameters();
    this.outgoingRequest = new Parameters();
    this.strings = new Parameters();
    this.incomingQuestionSelected = new Parameters();
    this.incomingForm.setAttributes(this.formService.getSelected());
    this.strings.setAttributes(this.service.app.strings.params);
    this.incomingCampaign.setAttributes({});
    this.incomingClient.setAttributes({});
    this.incomingFormSettings.setAttributes({});
    this.incomingQuestionSelected.setAttributes();
  }

  ngAfterViewInit(): void {
    document.getElementsByTagName( 'body')[0].style.overflow = 'hidden';
  }

  ngOnDestroy(): void {
    this.set();
  }

  ngOnInit(): void {
    this.eventId = this.activatedRoute.snapshot.params.eventId;
    this.eventId = this.service.decrypt(this.eventId);
    this.width = this.service.getWidth();
    this.height = this.service.getHeight();
    if (this.incomingForm.hasAttribute(this.strings.getId())){
      this.incomingCampaign.setAttributes(this.incomingForm.getCampaign());
      this.incomingClient.setAttributes(this.incomingCampaign.getClient());
      this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
      this.incomingFormSettings.setRedirectUrl(this.incomingFormSettings.hasAttribute(this.strings.getRedirectUrl()) ?
          this.incomingFormSettings.getRedirectUrl() : '');
      this.incomingFormSettings.setAfterRegisterSuccessfulMessage(
          this.incomingFormSettings.hasAttribute(this.strings.getAfterRegisterSuccessfulMessage()) ?
              this.incomingFormSettings.getAfterRegisterSuccessfulMessage() : '');
      this.service.backgroundColor = this.incomingFormSettings.getBackgroundColor();
      this.incomingQuestionSelected.setAttributes(this.incomingForm.getControls()[0]);
      document.getElementsByTagName( 'body')[0].style.backgroundColor = this.incomingFormSettings.getBackgroundColor();
  // this.onSelect(this.incomingQuestionSelected.getAttributes());
      const echo: any = new Echo({
        broadcaster : this.strings.getBroadcasterDriver(),
        key         : this.strings.getWebsocketKey(),
        wsHost      : this.strings.getWsHost(),
        wsPort      : parseFloat(this.strings.getWsPort()),
        disableStats: false,
      });
      echo.channel(
          this.strings.getWebsocketChannelIsAttending()).listen('.' +
          this.strings.getWebsocketEventIsAttending(), (e: any ) => {
        this.zone.run(() => {
          const incomingResult: any = new Parameters();
          incomingResult.setAttributes(JSON.parse(e[0]));
          this.afterEchoChannel(incomingResult);
        });
      });
      this.service.matSpinner = true;
      this.getResponses();
      // this.addItems((e: any) => { this.spin(null); });
      window.addEventListener('resize', (e: any) => {
        this.items = this.items.map((item: any) => {
          let incomingItem: any = new Parameters();
          incomingItem.setAttributes(item);
          incomingItem.setText(this.service.limitTo(incomingItem.getLabel(), ((((this.getHeight() / 2)) * 4.5) / 100)));
          return incomingItem.getAttributes();
        });
      });
      window.addEventListener('click', (e: any) => {
        if(this.dialogRef)
          this.dialogRef.close();
      });
    }else { this.requestForm(() => {}); }
  }

  afterEchoChannel(incomingResult: Parameters){
    const outgoingRequest: any = new Parameters();
    outgoingRequest.setAttributes({});
    outgoingRequest.setResponseId(incomingResult.getResponseId());
    this.responseService.search(outgoingRequest, (responses: any) => {
      responses.map((response: any) => {
        const incomingResponse: any = new Parameters();
        incomingResponse.setAttributes(response);
        const position: any = this.service.findIntegerIndex(this.responses, this.strings.getId(), incomingResponse.getId());
        if (position !== -1) {
          this.responses[position][this.strings.getIsAttending()] = 'yes';
          this.addItems(() => {
            this.isSpinning();
          });
        }else{
          const incomingInvitee: any = new Parameters();
          incomingInvitee.setAttributes({});
          incomingResponse.getDatas().map((responseResult: any) => {
            const incomingResponseResult: any = new Parameters();
            incomingResponseResult.setAttributes(responseResult);
            const incomingQuestion: any = new Parameters();
            incomingQuestion.setAttributes(incomingResponseResult.getUnknown(this.strings.getFormControll()));
            if (incomingQuestion.getCntrlNum() === this.incomingFormSettings.getIsAttending() &&
                !this.service.isNull(incomingResponseResult.getData())) {
              incomingInvitee.setIsAttending(incomingResponseResult.getData());
            }
            if (incomingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeeName()){
              if (!this.service.isNull(incomingResponseResult.getData())) {
                incomingInvitee.setName(incomingResponseResult.getData());
                incomingInvitee.setId(incomingResponse.getId());
              }
            }
            if (incomingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeeCompany()){
              if (!this.service.isNull(incomingResponseResult.getData())) {
                incomingInvitee.setCompany(incomingResponseResult.getData());
              }
            }
          });
          if (incomingInvitee.hasAttribute(this.strings.getName())) {
            this.responses.push(incomingInvitee.getAttributes());
            this.addItems(() => {
              this.isSpinning();
            });
          }
          this.responses = this.service.orderBy(
              this.responses,
              this.strings.getDesc(),
              this.strings.getId());
        }
      });
    });
  }

  isSpinning(){
    if (!this.disabledButton) {
      this.flag = parseFloat('1');
      setTimeout(() => {
        this.flag = parseFloat('0');
      }, this.service.timeout());
    }
  }

  set(){
    let output: any = this.service.getNavigatedUrl().toString().split('?');
    if (output.length !== 1){
      output = output[1].toString().split('&');
      output.map((split: any) => {
        if (split.toString().toLowerCase().includes(this.strings.getExtension())){
          document.getElementsByTagName( this.strings.getAccess())[0].style.backgroundColor =
              split.toString().split('=')[1];
        }else if (split.toString().toLowerCase().includes(this.strings.getAccess())){
          this.service.logo = null;
          if (!this.service.app.hasOwnProperty(this.strings.getSettings())){
            this.service.logo = this.service.clientImgUrl() + split.toString().split('=')[1];
            this.service.logo = this.service.getClientImgPart()  + split.toString().split('=')[1];
          }else { this.service.logo = this.service.getClientImgPart()  + split.toString().split('=')[1]; }
        }
      });
    }
  }

  after(){
    this.zone.run(() => {
      this.disabledButton = false;
      this.sound.pause();
      let incomingItem: any = new Parameters();
      incomingItem.setAttributes(this.items[this.idToLandOn]);
      this.dialogRef =  this.dialog.open(MessageDisplayComponent, {
        disableClose: true,
        backdropClass: null,
        hasBackdrop: false,
        data : {
          title: '', action: null,
          company: incomingItem.getAttributes().hasOwnProperty(this.strings.getCompany()) ? incomingItem.getCompany() : '',
          text: incomingItem.getLabel()
        },
        height : 'auto',
        width : 'auto'});
      this.dialogRef.afterClosed().subscribe((item: any) => {
        if (item instanceof Object){
          if (item.hasOwnProperty('response')){
            if (item.response.toString().includes('successfull')){

            }
          }
        }
      });
    });

  }

  getIdToLandOn(call: any){
    this.idToLandOn = Math.floor(Math.random() * this.items.length);
    if (this.idToLandOn === 0) {
      this.getIdToLandOn(call);
    } else call(this.idToLandOn);
  }

  onOptionSelected(option: any){
    const incomingOption: any = new Parameters();
    incomingOption.setAttributes(option);
    const inputPromptStringText: any = prompt('', incomingOption.getLabel());
    if (!this.service.isNull(inputPromptStringText) && incomingOption.getLabel() !== inputPromptStringText) {
     incomingOption.setLabel(inputPromptStringText);
     incomingOption.setQuestions(incomingOption.hasAttribute(this.strings.getQuestions()) ? JSON.stringify(incomingOption.getQuestions()) : '');
     incomingOption.setSettings(incomingOption.hasAttribute(this.strings.getSettings()) ? JSON.stringify(incomingOption.getSettings()) : '');
     incomingOption.setResult(incomingOption.hasAttribute(this.strings.getResult()) ? JSON.stringify(incomingOption.getResult()) : '');
     this.service.httpService('POST',
          this.service.app.routes.questions.options.update,
          incomingOption.getAttributes(),
          {ignoreLoadingBar: 'true', notify: false},
          (option: any) => {
            incomingOption.setAttributes(Object.assign(incomingOption.attributes, option));
          }, (error: any) => {
          });
    }
  }

  onSelect(question: any){
    // this.items = [];
    // setTimeout(() => {
    //   this.incomingQuestionSelected.setAttributes(question);
    //   this.incomingQuestionSelected.getOptions().map((option: any, l: any) => {
    //     const incomingOption: any = new Parameters();
    //     incomingOption.setAttributes(option);
    //     this.items.push({
    //       textFontFamily : 'Arial',
    //       strokeStyle: '#fff',
    //       textDirection: this.textDirection,
    //       textFillStyle: '#fff',
    //       textFontSize : 25,
    //       fillStyle: this.incomingFormSettings.getBackgroundColor(),
    //       text : this.service.limitTo(incomingOption.getLabel(), 25),
    //       id   : l,
    //     });
    //   });
    // }, this.service.timeout());
  }

  addItems(call: any){
    this.items = [];
    // for (let l = 0; l < 80; l++) {
    //   let title: any = this.service.random() + ' ' + this.service.random() + ' ' + this.service.random();
    //   this.items.push({
    //     textFontFamily: 'Mitrax',
    //     strokeStyle: '#fff',
    //     textDirection: this.textDirection,
    //     textAlignment: this.textAlignment,
    //     textMargin: 2,
    //     label: title,
    //     textFillStyle: '#fff',
    //     textFontSize: 25,
    //     fillStyle: this.incomingFormSettings.getBackgroundColor(),
    //     text: this.service.limitTo(title, (((this.getHeight() / 2) * 4.5) / 100)),
    //     id: l,
    //   });
    // }
    setTimeout(() => {
      let l: any = 0;
      this.responses.map((item: any) => {
        const incomingItem: any = new Parameters();
        incomingItem.setAttributes(item);
        if(incomingItem.getAttributes().hasOwnProperty(this.strings.getIsAttending())) {
          this.items.push({
            textFontFamily: 'Mitrax',
            strokeStyle: '#fff',
            textDirection: this.textDirection,
            textAlignment: this.textAlignment,
            textMargin: 2,
            label: incomingItem.getName(),
            company: incomingItem.hasAttribute(this.strings.getCompany()) ? incomingItem.getCompany() : '',
            textFillStyle: '#fff',
            textFontSize: 25,
            fillStyle: this.incomingFormSettings.getBackgroundColor(),
            text: this.service.limitTo(incomingItem.getName(), ((((this.getHeight() / 2)) * 4.9) / 100)),
            id: l,
          });
          l+= parseFloat('1');
        }
      });
      call(null);
    });
  }

  addOption(question: any){
    const incomingQuestion: any = new Parameters();
    incomingQuestion.setAttributes(question);
    const inputPromptStringText: any = prompt('', '');
    if (!this.service.isNull(inputPromptStringText)) {
      this.formService.addOptional(incomingQuestion.getOptions(), inputPromptStringText);
      const outgoingRequest: any = new Parameters();
      outgoingRequest.setAttributes(this.formService.newOptional);
      outgoingRequest.setFormControlId(incomingQuestion.getId());
      outgoingRequest.setSettings('');
      outgoingRequest.setQuestions('');
      outgoingRequest.setResult('');
      this.service.httpService('POST',
          this.service.app.routes.questions.options.add,
          outgoingRequest.getAttributes(),
          {ignoreLoadingBar: 'true', notify: false},
          (response: any) => {
          }, (error: any) => {
          });
    }
  }


  requestForm(call: any){
    if (!this.formService.getSelected().hasOwnProperty(this.strings.getId())){
      this.service.logo = null;
      this.router.navigateByUrl(this.strings.getSlash() + 'wait..');
      this.outgoingRequest.setAttributes({});
      this.outgoingRequest.setId(this.eventId);
      this.service.httpService('POST',
          this.service.app.routes.forms.get.toString(),
          this.outgoingRequest.getAttributes(), { ignoreLoadingBar : 'true', notify: false}, (result: any) => {
            this.formService.setSelected(result);
            this.incomingForm.setAttributes(result);

            window.history.back();
          }, (error: any) =>  {
            this.requestForm(call);
          });
    }
  }


  getResponses(){
    const outgoingRequest: any = new Parameters();
    outgoingRequest.setAttributes({});
    outgoingRequest.setFormId(this.incomingForm.getId());
    outgoingRequest.setOrderBy(this.strings.getDesc());
    if (this.responses.length !== 0) {
      outgoingRequest.setAscendinglastAt(this.responses[this.responses.length - 1].id);
    }
    this.service.httpService( 'POST',
        this.service.app.routes.responses.search,
        outgoingRequest.getAttributes(),
        {ignoreLoadingBar : 'true', notify: 'false'},
        (responses: any) => {
          const items: any = [];
          responses.map((response: any) => {
            const incomingResponse: any = new Parameters();
            incomingResponse.setAttributes(response);
            const incomingInvitee: any = new Parameters();
            incomingInvitee.setAttributes({});
            incomingResponse.getDatas().map((responseResult: any) => {
              const incomingResponseResult: any = new Parameters();
              incomingResponseResult.setAttributes(responseResult);
              const incomingQuestion: any = new Parameters();
              incomingQuestion.setAttributes(incomingResponseResult.getUnknown(this.strings.getFormControll()));
              if (incomingQuestion.getCntrlNum() === this.incomingFormSettings.getIsAttending() &&
                  !this.service.isNull(incomingResponseResult.getData()) &&
                  incomingResponseResult.getData().toLowerCase().includes(this.strings.getYes()))
                incomingInvitee.setIsAttending(incomingResponseResult.getData());
              if (incomingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeeName()){
                if (!this.service.isNull(incomingResponseResult.getData())) {
                  incomingInvitee.setName(incomingResponseResult.getData());
                  incomingInvitee.setId(incomingResponse.getId());
                }
              }
              if (incomingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeeCompany()){
                if (!this.service.isNull(incomingResponseResult.getData())) {
                  incomingInvitee.setCompany(incomingResponseResult.getData());
                }
              }
            });
            if (incomingInvitee.hasAttribute(this.strings.getName())){
              this.responses.push(incomingInvitee.getAttributes());
              items.push(incomingInvitee.getAttributes());
            }
            this.responses = this.service.orderBy(
                this.responses,
                this.strings.getDesc(),
                this.strings.getId());
          }); if (items.length !== 0) {
            this.getResponses();
          }else {
            this.service.matSpinner = false;
            this.addItems(() => {
              this.spin(null);
            });
          }
        },  (error: any) => {
        });
  }


  getWidth(){
    return this.service.getWidth();
  }

  getHeight(){
    return this.service.getHeight();
  }

  before(){
    this.zone.run(() => {
      this.disabledButton = true;
      this.sound = new Audio(this.spinningWheelSoundEffect);
      // sound.volume = 0.1;
      this.sound.play();
      this.sound.onended = () => {};
    });
  }


  reset(){
    try{
      this.wheel.reset();
      this.sound.pause();
      this.flag = 1; setTimeout(() => {this.flag = 0;});
    }catch (e) {
    }
  }

  start(){
    if(this.disabledButton) {this.reset(); this.disabledButton = false;} else this.spin(null);
  }

  async spin(prize) {
    this.reset();
    this.idToLandOn = Math.floor(Math.random() * this.items.length);
    await new Promise(resolve => setTimeout(resolve, 0)).then(() => {
      setTimeout(() => {
        this.wheel.spin();
      });
    }).catch((error: any) => {
    });
  }



  random(){
    return Math.random();
  }


}
